<template>
  <div class="flex">
    <div
        class="p-4 bg-[#E3F2FF] rounded-e-2xl max-w-[75%] self-end ml-auto mb-4 relative"
        style="border-radius: 16px 0 16px 16px"
    >
      <span class="text-[#000D21] text-sm text-left whitespace-pre-wrap">{{ messageContent }}</span>
      <div v-if="messageImages.length > 0" class="flex flex-wrap mt-2 gap-2">
        <div v-for="(image, index) in messageImages" :key="image.image_url?.url || image" class="flex-shrink-0">
          <img
            :src="image.image_url?.url || image"
            alt="message image"
            class="h-20 w-20 object-cover rounded-lg cursor-pointer hover:opacity-90"
            @click="handleImageClick(index)"
          >
        </div>
      </div>
      <div class="flex justify-between mt-3 items-center">
      <span class="text-xs text-[#595C5F]"
      >{{ calculateText?.wordCount }} words /
        {{ calculateText?.charCount }} characters</span
      >
        <div class="flex justify-end items-center">
          <img
              v-tooltip="{ content: 'Copy Text' }"
              src="@src/assets/img/chat_bot/copy.svg"
              width="18"
              height="18"
              alt="copy text"
              class="ml-3 cursor-pointer active:opacity-50"
              @click="copyPrompt(messageContent)"
          />
          <img
              v-tooltip="{ content: 'Save Prompt' }"
              src="@src/assets/img/chat_bot/save.svg"
              class="ml-3 cursor-pointer active:opacity-50"
              width="18"
              height="18"
              alt="save"
              @click="props.savePrompt(messageContent,'save')"
          />
        </div>
      </div>
    </div>

    <VueEasyLightbox
      :visible="visible"
      :imgs="previewImages"
      :index="imageIndex"
      @hide="visible = false"
    />
  </div>
</template>

<script setup>
// using recommended syntax  and eslint disabled until we update linting rules
/* eslint-disable */
import { computed, defineProps, ref } from 'vue'
import VueEasyLightbox from 'vue-easy-lightbox'

const props = defineProps({
  message: {
    type: Object,
    default: () => {},
  },
  type: {
    type: String,
    default: 'modal',
  },
  savePrompt: {
    type: Function,
    default: () => {},
  },
})

// Lightbox state
const visible = ref(false)
const imageIndex = ref(0)

const calculateText = computed(() => {
  if (typeof props.message?.content === 'string') {
    const words = props.message.content.split(' ')
    const wordCount = words.length
    const charCount = props.message.content.length
    return { wordCount, charCount }
  } else if (Array.isArray(props.message?.content)) {
    const textContent = props.message.content
      .filter(item => item.type === 'text')
      .map(item => item.text)
      .join(' ')
    const words = textContent.split(' ')
    const wordCount = words.length
    const charCount = textContent.length
    return { wordCount, charCount }
  }
  return { wordCount: 0, charCount: 0 }
})

const messageContent = computed(() => {
  if (typeof props.message?.content === 'string') {
    return props.message.content
  } else if (Array.isArray(props.message?.content)) {
    return props.message.content
      .filter(item => item.type === 'text')
      .map(item => item.text)
      .join(' ')
  }
  return ''
})

const messageImages = computed(() => {
  if (Array.isArray(props.message?.content)) {
    return props.message.content.filter(item => item.type === 'image_url')
  }
  return []
})

const previewImages = computed(() => {
  return messageImages.value.map(image => image.image_url?.url || image)
})

const copyPrompt = (prompt) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(prompt)
  }
}

const handleImageClick = (index) => {
  imageIndex.value = index
  visible.value = true
}
</script>

<style scoped></style>
