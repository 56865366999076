<script setup>
import { watch, computed } from 'vue'
import useEcharts from '@src/modules/analytics_v3/composables/useEcharts'
import debounce from 'lodash.debounce'

const props = defineProps({
  isModal: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'line',
  },
  chartOptions: {
    type: Object,
    required: true,
  },
  customHeight:{
    type: String,
    default: '350px',
  },
  customModalHeight:{
    type: String,
    default: '40vh',
  },
  showGraph:{
    type: Boolean,
    default: true
  }
})

const { setup, chartRef } = useEcharts(props.chartOptions, props.type)

// Reactive height for transitions
const height = computed(() => {
  return props.isModal ? props.customModalHeight : props.customHeight;
});

watch(
  () => [chartRef.value, props.chartOptions, props.showGraph],
  debounce(() => {
    if (!props.showGraph) return
    setup(props.chartOptions)
  }, 100)
)
</script>

<template>
  <div
    v-if="props.showGraph"
    ref="chartRef"
    :style="{ height: height, transition: 'height 0.5s ease-in-out' }"
  />
</template>
