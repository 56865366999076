/**
 * Fetches Social/Blog account Details, Replaces getFreshAccount from channel-mixin.js
 * @param type
 * @param account
 * @param storeData
 * @returns {*}
 */
export const getAccount = (type, account, storeData = null) => {
  return channelItem(null, type, account, storeData)
    ? channelItem(null, type, account, storeData)
    : account
}

/**
 * Fetches social channel items from vuex store.
 * @param accountId
 * @param type
 * @param account
 * @param storeData
 * @returns {null|*}
 */
export const channelItem = (
  accountId,
  type,
  account = null,
  storeData = null
) => {
  switch (type) {
    case 'facebook':
      return storeData.getters.getFacebookAccounts.items.find(
        (item) =>
          item.facebook_id === accountId ||
          item.facebook_id === parseInt(accountId) ||
          (account && item.facebook_id === account.facebook_id)
      )
    case 'twitter':
      return storeData.getters.getTwitterAccounts.items.find(
        (item) => item.twitter_id === accountId
      )
    case 'pinterest':
      return storeData.getters.getPinterestBoards.find(
        (item) =>
          item.board_id === accountId ||
          (account && item.board_id === account.board_id)
      )
    case 'linkedin':
      return storeData.getters.getLinkedinAccounts.items.find(
        (item) =>
          item.linkedin_id === accountId ||
          item.linkedin_id === parseInt(accountId) ||
          (account && item.linkedin_id === account.linkedin_id)
      )
    case 'tumblr_profile':
      return storeData.getters.getTumblrAccounts.find(
        (item) =>
          item.name === accountId || (account && item.name === account.name)
      )
    case 'gmb':
      return storeData.getters.getGmbAccounts.items.find(
        (item) => item.name === accountId
      )
    case 'instagram':
      return storeData.getters.getInstagramAccounts.items.find(
        (item) =>
          item.instagram_id === accountId ||
          (account && item.instagram_id === account.instagram_id)
      )
    case 'youtube':
      return storeData.getters.getYoutubeAccounts.items.find(
        (item) =>
          item.platform_identifier === accountId ||
          (account && item.platform_identifier === account.platform_identifier)
      )
  }
  return account
}

/**
 * Returns a list of all the social/blog accounts connected in alphabetical order.
 * @param storeData
 * @returns {(function(*): [])|*}
 */
export const platformAccountsList = (storeData) => {
  return storeData.getters.getPlatformsList
}

/**
 * Retrieves the account name based on the account type.
 *
 * @param {Object} account - The account object containing account details.
 * @param {string} account.account_type - The type of the account (e.g., 'facebook', 'twitter').
 * @param {string} account.name - The name of the account.
 * @param {string} account.platform_name - The platform name for certain account types.
 * @returns {string | undefined} The name of the account or platform name, depending on the account type.
 */
export const getAccountName = (account) => {
  switch (account?.account_type) {
    case 'facebook':
    case 'twitter':
    case 'instagram':
    case 'pinterest':
      return account?.name
    case 'linkedin':
      return account?.name?.toString()
    case 'tiktok':
    case 'youtube':
      return account?.platform_name
    default:
      return ''
  }
}
