<template>
  <div>
    <div class="media-tab__header"> Upload to Media Library </div>
    <div
      class="media-tab__body"
      :class="modalBodyClasses"
      @dragover.prevent="handleDragOver"
      @dragenter="handleDragEnter"
      @dragleave="handleDragLeave"
      @drop="handleDrop"
    >
      <div v-if="images.length > 0" class="media-tab__info">
        <div class="media-tab__count">
          {{ images.length }}
          Item{{ images.length > 1 ? 's' : '' }} of size
          {{ bytesToSize(totalSize) }}
          {{ images.length > 1 ? 'are' : 'is' }} ready to upload.</div
        >
        <div class="media-tab__actions">
          <button
            :disabled="isUploading"
            href="javascript:;"
            @click="clearFiles"
            >Remove All</button
          >
        </div>
      </div>



      <div ref="dropzone" class="media-tab__items relative">
        <div class="grid-flex">
          <div class="cell-md-3">
            <label
              :for="`upload-modal-btn__${type}`"
              class="media-tab__upload-btn"
            >
              <span class="block">
                <i class="far fa-cloud-upload"></i>
                Add{{ images.length > 0 ? ' More' : '' }} Files
              </span>
            </label>

            <input
              :id="`upload-modal-btn__${type}`"
              class="w-full h-full hidden"
              type="file"
              multiple
              accept=".jpg, .jpeg, .png, .gif, .avi, .mov, .m4v, .mp4 , .heic, application/pdf"
              :disabled="isUploading"
              value=""
              @change="onFileChange"
            />
          </div>
          <div v-for="(image, k) in images" :key="k" class="cell-md-3 mb-3">
            <Asset
              :id="image._id"
              :hide-delete="isUploading"
              type="secondary"
              :src="image.url"
              :alt="image.name"
              :info="image"
              @delete="deleteItem"
            />
          </div>
        </div>
      </div>
    </div>
    <!--            :append-to-body="true"-->
    <div class="media-tab__footer">
      <div class="d-flex align_center">
        <div class="mr-3 font-0-95rem">Folder:</div>
        <div class="media-tab__footer-folders">
          <i class="far fa-folder mr-3"></i>
          <TreeSelect
            v-model="selectedFolderValue"
            :disabled="isUploading"
            placeholder="Select Folder"
            :clearable="false"
            :flatten-search-results="true"
            :options="treeFolders"
            :normalizer="normalizer"
          >
          </TreeSelect>
        </div>
      </div>

      <div
        class="
          media-tab__footer-actions
          d-flex
          flex-grow-1
          justify-content-end
          flex-shrink-0
          ml-2
          items-center
          gap-x-4
        "
      >
        <div v-if="isUploading">
          <b-progress
              v-if="progress"
              class="w-60 2xl:w-96"
              :value="progress"
              :max="100"
              show-progress
              animated
          >
            <b-progress-bar :value="progress" >
              {{ progress }}%
            </b-progress-bar>
          </b-progress>
        </div>
        <button
          class="btn btn-studio-theme-space"
          :disabled="isUploading"
          @click="uploadFiles"
        >
          Upload
          <clip-loader
            v-if="isUploading"
            class="spinner ml-2"
            :color="'#e0dfdf'"
            :size="'16px'"
          ></clip-loader>
        </button>
      </div>
    </div>
  </div>
</template>

<script>


import isEmpty from 'is-empty'

import { MediaHelperMixin } from '@src/modules/publish/components/media-library/utils/MediaHelpers'
import { EventBus } from '@common/lib/event-bus'
import { commonMethods } from '@common/store/common-methods'
import Asset from '../Asset'


export default {
  name: 'UploadFilesTabVue',
  components: {
    Asset,
  },
  mixins: [MediaHelperMixin],
  props: {
    type: {
      type: String,
      default: '',
    },
    folders: {
      type: Array,
      default: () => [],
    },
    modalId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      // define the default value
      selectedFolderValue: 'uncategorized',
      images: [],
      progress: null,
      isUploading: false,
      isDraggingOver: false,
      embedSrc: '',
    }
  },

  computed: {
    treeFolders() {
      let list = [{ id: 'uncategorized', label: 'Uncategorized' }]

      list = [
        ...list,
        ...this.folders.map((folder) => {
          const temp = folder
          const folder_name = folder.folder_name + ((folder.is_root && folder.is_global) ? ' ♛' : '')

          temp.children = folder.sub_folders.map((subfolder) => {
            return {
              id: subfolder._id,
              label: subfolder.folder_name,
            }
          })

          if (temp.children.length === 0) {
            return {
              id: temp._id + '-root',
              label: folder_name
            }
          }

          return {
            id: temp._id + '-root',
            label: folder_name,
            children: temp.children,
          }
        }),
      ]

      return list
    },
    totalSize() {
      let size = 0

      this.images.forEach((image) => {
        size = size + image.size
      })

      return size
    },
    modalBodyClasses() {
      return {
        'bg-gray-100': this.isDraggingOver,
        // add more classes here as needed
      }
    },
  },

  watch: {
    isUploading(val) {
      this.$emit('uploading', val)
    },
  },

  mounted() {
    if (!isEmpty(this.$route.query.type)) {
      if (this.$route.query.type === 'folder' && this.$route.query.folderId) {
        let folderValue = this.$route.query.folderId

        if (this.$route.query.root && this.$route.query.root === 'true') {
          folderValue = folderValue + '-root'
        }

        this.selectedFolderValue = folderValue
      }
    }
  },
  methods: {
    handleDragEnter(event) {
      event.preventDefault()
      this.isDraggingOver = true
    },
    handleDragOver(event) {
      event.preventDefault()
      this.isDraggingOver = true
    },
    handleDragLeave(event) {
      event.preventDefault()
      this.isDraggingOver = false
    },

    /**
     * Handle files drop
     */
    handleDrop(event) {
      event.preventDefault()
      event.stopPropagation()
      this.isDraggingOver = false
      const mainElement = this.$refs.dropzone

      this.onFileChange(event, 'drop')
      mainElement.setAttribute('data-dragover', 'false')
    },

    /**
     * Method to manage the folder selection
     */
    normalizer(node) {
      let name = node.label

      if (node.id === 'uncategorized') {
        name = name + ' (Main)'
      }

      return {
        id: node.id,
        label: name,
        children: node.children,
      }
    },

    /**
     * Method to handle file change
     */
    onFileChange(e, source = 'native') {
      const selectedFiles =
          source === 'drop' ? e.dataTransfer.files : e.target.files
      for (let i = 0; i < selectedFiles.length; i++) {
        let url = ''
        if (selectedFiles[i].type.includes('video/')) {
          url =
              'https://storage.googleapis.com/lumotive-web-storage/placeholder.png'
        } else if (selectedFiles[i].type.includes('image/') || this.isHEICFile(selectedFiles[i])) {
          url = URL.createObjectURL(selectedFiles[i])
        } else if (selectedFiles[i].type.includes('application/pdf')) {
          // Handle PDF files here
          url = 'https://storage.googleapis.com/lumotive-web-storage/placeholder.png'
        } else {
          // Handle other file types or skip unsupported files
          continue
        }

        this.images.push({
          _id: this.images.length + 1,
          name: selectedFiles[i].name,
          size: selectedFiles[i].size,
          type:
              selectedFiles[i].type || this.isHEICFile(selectedFiles[i])
                  ? 'image/heif'
                  : '',
          url,
          file: selectedFiles[i],
        })
      }
      document.getElementById(`upload-modal-btn__${this.type}`).value = ''
    },

    isHEICFile(file) {
      return (
        file.name.toLowerCase().endsWith('.heic') ||
        file.name.toLowerCase().endsWith('.heif')
      )
    },

    clearFiles() {
      this.images = []
    },

    deleteItem(id) {
      this.images = this.images.filter((img) => img._id !== id)
    },

    async uploadFiles() {
      this.isUploading = true

      let folderId = null
      let isRoot = false

      const filters = {
        media: this.images,
        folder_id: null,
      }

      // If any folder selected
      if (!isEmpty(this.selectedFolderValue)) {
        ;[folderId, isRoot] = this.selectedFolderValue.split('-')

        folderId = folderId === 'uncategorized' ? null : folderId
        isRoot = !isEmpty(isRoot)
        filters.folder_id = folderId

        if (folderId) {
          filters.is_root = isRoot
        }
      }

      await this.uploadFilesHelper(
        filters,
        (status, message) => {
          if (status) {
            this.clearFiles()
            this.isUploading = false
            if (this.type === 'library') {
              this.$bvModal.hide(this.modalId)
              this.$bvModal.hide('sweet-alert')
              EventBus.$emit('refetch-folders')
              EventBus.$emit('refetch-media', { folderId, isRoot })
              EventBus.$emit('refetch-media-limits')
              commonMethods.toggleWidgets(false)
            } else {
              this.$emit('changeTab', 1, true)
            }
          }
        },
        (percentCompleted) => {
          this.progress = percentCompleted
        }
      )
      this.isUploading = false
    },
  },
}
</script>
