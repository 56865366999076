import EngagementIcon from '@src/assets/img/icons/analytic/colored/outlined/engagement-green-color-outlined-icon.svg'
import ImpressionIcon from '@src/assets/img/icons/analytic/colored/outlined/impressions-pink-color-outlined-icon.svg'
import LikeIcon from '@src/assets/img/icons/analytic/colored/outlined/like-color-outlined-icon.svg'
import CommentsIcon from '@src/assets/img/icons/analytic/colored/outlined/comment-purple-color-outlined-icon.svg'
import PostClickIcon from '@src/assets/img/icons/analytic/colored/outlined/clicks-color-outlined-icon.svg'
import ReactionIcon from '@src/assets/img/icons/analytic/colored/outlined/like-green-color-outlined-icon.svg'
import ShareIcon from '@src/assets/img/icons/analytic/colored/outlined/shares-blue-color-outlined-icon.svg'
import PostEngagementIcon from '@src/assets/img/icons/analytic/colored/outlined/engagement-purple-color-outlined-icon.svg'
import ReachIcon from '@src/assets/img/icons/analytic/colored/outlined/reach-orange-color-outlined-icon.svg'
import LoveIcon from '@src/assets/img/icons/analytic/colored/outlined/heart-color-outlined-icons.svg'
import WowIcon from '@src/assets/img/icons/analytic/colored/outlined/wow-color-outlined-icon.svg'
import AngryIcon from '@src/assets/img/icons/analytic/colored/outlined/angry-color-outlined-icon.svg'
import MediaTypeIcon from '@src/assets/img/icons/analytic/colored/outlined/media-type-color-outlined-icon.svg'
import HahaIcon from '@src/assets/img/icons/analytic/colored/outlined/haha-color-outlined-icon.svg'
import SadIcon from '@src/assets/img/icons/analytic/colored/outlined/sad-color-outlined-icon.svg'
import ViewIcon from '@src/assets/img/icons/analytic/colored/outlined/eye-blue-color-outlined-icon.svg'
import SaveIcon from '@src/assets/img/icons/analytic/colored/outlined/save-color-outlined-icon.svg'
import EngagementRateIcon from '@src/assets/img/icons/analytic/colored/outlined/engagement-color-outlined-icon.svg'
import MinutesWatchedIcon from '@src/assets/img/icons/analytic/colored/outlined/videos-color-outlined-icons.svg'
import RedMinutesWatchedIcon from '@src/assets/img/icons/analytic/colored/outlined/red-minutes-watched-outlined-icon.svg'
import DislikeIcon from '@src/assets/img/icons/analytic/colored/outlined/dislike-color-outlined-icon.svg'
import RedViewsIcon from '@src/assets/img/icons/analytic/colored/outlined/video-views-color-outlined-icons.svg'
import OutboundClickIcon from '@src/assets/img/icons/analytic/colored/outlined/outbound-click-outline-icon.svg'
import AverageViewIcon from '@src/assets/img/icons/analytic/colored/outlined/time-blue-color-outlined-icons.svg'
import FollowerIcon from '@src/assets/img/icons/analytic/colored/followers-growth-icon.svg'
import { useRouter } from 'vue-router'
import { EventBus } from '@common/lib/event-bus'
import { useBilling } from '@modules/billing/composables/useBilling'
import loaderImage from '@assets/img/logo/logo-animation-white-bg.gif'
import { ref, computed } from 'vue'
import usePlannerHelper from '@src/modules/planner_v2/composables/usePlannerHelper'
import useAnalyticsUtils from '@src/modules/analytics/components/common/composables/useAnalyticsUtils.js'
import { useFeatures } from '@modules/billing/composables/useFeatures'
import { getAccount } from '@common/composables/useSocialChannels'
import { useStore } from '@state/base'

export default function usePostPreview() {
  const loadingImage = ref(loaderImage)
  const selectedChannelType = ref('') // channel name e.g. facebook, twitter etc
  const accountInPreview = ref(null) // current preview account
  const segValue = ref('comments')
  const previewedPost = ref(null)
  const errorMessage = ref(
    'Analytics will be available 2-3 days after publication.'
  )

  const comment = ref({
    text: '',
    mentioned_user: [],
    media: [],
  })

  const loader = ref({
    file: false,
    comments: false,
  })
  const storySelected = ref(false)

  const store = {
    getters: useStore().getters,
    dispatch: useStore().dispatch,
  }

  const { showUpgradeModal } = useBilling()
  const { postAnalytics } = usePlannerHelper()

  const { getAccountId } = useAnalyticsUtils()

  const { canAccess } = useFeatures()
  const shareAbleLinkAccess = canAccess('shareable_link')

  const router = useRouter()
  const getAnalyticsTooltip = (key) => {
    return postAnalytics.value?.[key + '_tooltip']
  }

  const getAnalyticsIcons = (value) => {
    const iconMapper = {
      engagement: EngagementIcon,
      engagement_rate: EngagementRateIcon,
      impressions: ImpressionIcon,
      comments: CommentsIcon,
      repost: ShareIcon,
      reposts: ShareIcon,
      post_clicks: PostClickIcon,
      reactions: ReactionIcon,
      likes: LikeIcon,
      shares: ShareIcon,
      views: ViewIcon,
      followers: FollowerIcon,
      post_engagement: PostEngagementIcon,
      reach: ReachIcon,
      love: LoveIcon,
      wow: WowIcon,
      anger: AngryIcon,
      media_type: MediaTypeIcon,
      haha: HahaIcon,
      sad: SadIcon,
      saves: SaveIcon,
      subscriber_gained: FollowerIcon,
      red_views: RedViewsIcon,
      minutes_watched: MinutesWatchedIcon,
      red_minutes_watched: RedMinutesWatchedIcon,
      dislikes: DislikeIcon,
      average_view_duration: AverageViewIcon,
      outbound_clicks: OutboundClickIcon,
      pin_clicks: PostClickIcon,
    }
    return iconMapper[value] ?? EngagementIcon
  }

  const navigateToPageAnalytics = () => {
    router.push({
      name: selectedChannelType.value + '_analytics_v3',
      params: {
        accountId: getAccountId(accountInPreview.value).toString(),
      },
    })
  }

  const formatKey = (key) => {
    return key
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  // function to share post via Link
  const sharePlan = (planId) => {
    if (!shareAbleLinkAccess.value?.allowed) {
      showUpgradeModal()
      return
    }
    EventBus.$emit('share-plan::show', [planId])
  }

  const analyticsMetrics = computed(() => {
    const analytics = postAnalytics.value

    // Check if analytics is a non-null object and not an array
    if (typeof analytics !== 'object') {
      return {} // Return an empty object for invalid analytics data
    }

    // Use Object.entries to get key-value pairs, then filter out keys containing 'tooltip'
    const filteredEntries = Object.entries(analytics).filter(
      ([key]) => !key.toLowerCase().includes('tooltip')
    )

    // Convert the filtered entries back to an object
    return Object.fromEntries(filteredEntries)
  })

  /**
   * Find a specific facebook account in facebook accounts list.
   * @param facebookId
   * @returns {*}
   */
  const findFacebookAccount = (facebookId) => {
    return store.getters.getFacebookAccounts.items.find(
      (x) => x.facebook_id === facebookId
    )
  }

  /**
   * Finds specific carousel account if it's a carousel post.
   * @param plan
   * @returns {*|null}
   */
  const findFacebookCarouselAccount = (plan) => {
    return plan.carousel_options?.accounts?.includes(
      plan.feed_preview?.account?.facebook_id
    )
      ? getAccount(selectedChannelType, accountInPreview, store)?.value
      : null
  }

  return {
    loadingImage,
    selectedChannelType,
    accountInPreview,
    segValue,
    previewedPost,
    errorMessage,
    comment,
    loader,
    storySelected,
    shareAbleLinkAccess,
    analyticsMetrics,

    sharePlan,
    showUpgradeModal,
    findFacebookAccount,
    findFacebookCarouselAccount,
    formatKey,
    getAnalyticsTooltip,
    getAnalyticsIcons,
    navigateToPageAnalytics,
  }
}
