<template>
  <div class="" :class="containerClass">
    <!-- Using value -->
    <button @click="$emit('header-click')" v-b-toggle="'cst-collapsible-' + id" class="border-0 bg-transparent w-full flex justify-between items-center p-3 focus:outline-none" :class="toggleButtonClass" >
      <slot name="header">Toggle Collapse</slot>
      <i class="fas fa-chevron-down text-gray-800 transition mr-2" :class="{ 'fa-rotate-180': isOpen }"></i>
    </button>

    <!-- Element to collapse -->
    <b-collapse :id="'cst-collapsible-' + id" v-model="isOpen">
      <div class="cst-collapsible__body">
        <slot></slot>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'CstCollapsible',
  props: {
    id: {
      type: String,
      default: '',
      required: true
    },
    open: {
      type: Boolean,
      default: false
    },
    toggleButtonClass: {
      type: String,
      default: ''
    },
    containerClass: {
      type: String,
      default: 'cst-editor color-border rounded-xl bg-white'
    }
  },
  emits: ['header-click'],
  data () {
    return {
      isOpen: this.open
    }
  },
  watch: {
    open(newVal) {
      this.isOpen = newVal
    }
  }
}
</script>

<style lang="scss">
.cst-collapsible {
  //border: 1px solid #dee2e6;

  &__body {
    //border-top: 1px solid #dee2e6;
  }

}
</style>
