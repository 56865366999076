<template>
  <div class="main w-full min-w-full overflow-hidden" :style="getPlannerHeight">
    <transition v-show="showFilterSidebar !== null" name="fade-sidebar">
      <FilterSidebar
          v-show="showFilterSidebar"
          ref="filter-sidebar"
          :content-types="contentTypes"
          :plans-count="plansCount"
          @close="showFilterSidebar = false"
          @fetch-plans="reFetchPlans"
          @social-account="handleSocialAccountSelection"
          @handle-query-change="handleQueryChange"
          @handle-plans-count-reset="handlePlansCountReset"
      />
    </transition>
    <div
        :class="{ 'bg-white': isListView }"
        class="w-full max-w-full h-full max-h-full overflow-hidden"
    >
      <!--      if transition time is changed, then scroll watch property in calenderView & FeedView shall also be updated -->
      <div id="mainHeader" style="transition: all 0.6s">
        <div class="sticky top-0 z-[40] bg-white p-0.5">
          <PlannerHeader
              :bulk-change-status-option-visibility-status="bulkChangeStatusOptionVisibilityStatus
                "
              :check-all-items="check_all_items"
              :hide-filter-button="showFilterSidebar"
              :loader="loader"
              :search-loader="searchLoader"
              :search-query="searchQuery"
              :selected-items-count="selectedPlans.length"
              :show-bulk-actions="showBulkActions"
              :total-items="total_items"
              @refresh-table="refreshTable"
              @set-check-all-items="setCheckAllItems"
              @set-search-query="setSearchQuery"
              @un-select-all-table-items="unSelectAllTableItems"
              @bulk-action="handleBulkAction"
              @toggle-filters="showFilterSidebar = !showFilterSidebar"
              @sort-handler="sortHandler"
              @default-view-handler="defaultViewHandler"
              @handle-query-change="handleQueryChange"
              @empty-planner-items="emptyPlannerItems"

          />
        </div>
      </div>
      <div id="mainView" style="transition: all 0.6s" class="h-full">
        <router-view
            :check-all-items="check_all_items"
            :filter-sidebar="showFilterSidebar"
            :is-platform-selected="platformSelection.length > 0"
            :loader="loader"
            :plans="items"
            :notes="notes"
            :search-loader="searchLoader"
            :search-query="searchQuery"
            :bulk-change-status-option-visibility-status="bulkChangeStatusOptionVisibilityStatus
              "
            :selected-items-count="selectedPlans.length"
            :show-bulk-actions="showBulkActions"
            :total-items="total_items"
            @calendar-date-change="handleCalendarDateChange"
            @handle-query-change="handleQueryChange"
            @set-search-query="setSearchQuery"
            @unSelectAllTableItems="unSelectAllTableItems"
            @bulk-action="handleBulkAction"
            @refresh-table="refreshTable"
            @set-check-all-items="setCheckAllItems"
            @item-select="handleItemSelection"
            @handle-change="handleMoreFetch"
            @fetch-plans-after-delete="fetchPlansAfterDelete"
            @update-plans-count="updatePlansCount"
            @preview-plan="previewPlan"
            @approve-with-comment="approveWithComment"
            @reject-with-comment="rejectWithComment"
            @replace-plan="replacePlan"
        ></router-view>
      </div>
    </div>
    <MovePublicationFolder :publication-folders="plansCount?.composer_folders || []"/>
    <CreatePublicationFolder/>
    <TweetsResponseModal></TweetsResponseModal>
    <FirstCommentResponseModalVue></FirstCommentResponseModalVue>
    <SocialApprovalModal></SocialApprovalModal>
    <ReplacePlanConfirmation></ReplacePlanConfirmation>
    <ReopenPlanConfirmation></ReopenPlanConfirmation>
    <PublishingTimeFeedView type="missed_reviewed"></PublishingTimeFeedView>
    <!-- Approve Post Modal-->
    <b-modal
        id="approve-post-modal"
        :no-close-on-backdrop="true"
        centered
        dialog-class="max-w-lg"
        hide-footer
        hide-header
        size="lg"
    >
      <ApprovalConfirmation
          :btn-loading="btnLoading"
          :plan-for-approval="planForApproval"
          :is-bulk-approval="isBulkApproval"
          @callProcessPlanApproval="callProcessPlanApproval"
          @onClickCancelApprove="onClickCancelApprove"
      />
    </b-modal>

    <!-- Reject Post Modal -->
    <b-modal
        id="reject-post-modal"
        :no-close-on-backdrop="true"
        centered
        dialog-class="max-w-lg"
        hide-footer
        hide-header
        size="lg"
    >
      <RejectionConfirmation
          :btn-loading="btnLoading"
          :plan-for-reject="planForReject"
          :is-bulk-reject="isBulkReject"
          @callProcessPlanApproval="callProcessPlanApproval"
          @onClickCancelReject="onClickCancelReject"
      />
    </b-modal>
    <PlannerPostPreview
        :plan-loader="previewPlanLoader || loader"
        :plan="currentPlanInPreview"
        :disable-left="planPreviewDisableLeft"
        :disable-right="planPreviewDisableRight"
        @close-preview="closePreview"
        @next-plan="previewNextPlan"
        @previous-plan="previewPreviousPlan"
        @approve-with-comment="approveWithComment"
        @reject-with-comment="rejectWithComment"
        @edit-plan="editPlan"
        @delete-plan="deletePlan"
        @duplicate-plan="duplicatePlan"
    />
    <template v-if="showBulkActions">
      <BulkEditPlans
          :campaign-folders="plansCount?.composer_folders || []"
          :selected-plans="selectedPlansObjects"
          @refresh-table="refreshTable"
      ></BulkEditPlans>
    </template>
    <SharePlanModal/>
    <ManageLinksModal @share-notes="shareNotes"/>
  </div>
  <DeletePostModal />

  <ApprovalModal modal-id="bulk-approval-modal" module="planner-bulk-approval" :account-selection="BulkAccountSelection" :selected-plan-ids="selectedPlans" />
</template>

<script>
import FilterSidebar from '@src/modules/planner_v2/components/FilterSidebar'
import PlannerHeader from '@src/modules/planner_v2/components/PlannerHeader'
import DeletePostModal from '@src/modules/planner_v2/components/DeletePostModal'
import Helpers from '@src/modules/planner_v2/mixins/Helpers'
import MovePublicationFolder from '@src/modules/composer/components/MovePublicationFolder'
import CreatePublicationFolder from '@src/modules/composer/components/CreatePublicationFolder'
import {socialChannelsNameArray, swalAttributes} from '@common/constants/common-attributes'
import {getStatusClass} from '@common/lib/planner'
import TweetsResponseModal from '@src/modules/planner/components/view/TweetsResponseModal'
import FirstCommentResponseModalVue from '@src/modules/planner/components/FirstCommentResponseModal'
import SocialApprovalModal from '@common/components/dialogs/approval/SocialApprovalModal'
import Router from '@src/router'
import proxy from '@common/lib/http-common'
import {
  processPlanBulkOperationURL,
  replacePlanURL,
} from '@src/modules/planner/config/api-utils'
import {
  BULK_OPERATION_ERROR,
  BULK_OPERATION_SUCCESS,
  UNKNOWN_ERROR,
} from '@common/constants/messages'
import {getWorkspaceTimeZoneTime} from '@common/lib/date-time'
import ReplacePlanConfirmation from '@src/modules/planner/components/dialogs/ReplacePlanConfirmation'
import {
  plannerDefaultSort,
  plannerDefaultView,
} from '@src/modules/publish/config/api-utils'
import {mapGetters} from 'vuex'
import PublishingTimeFeedView from '@src/modules/planner/components/view/feed-view/PublishingTimeFeedView'
import ReopenPlanConfirmation from '@src/modules/planner/components/dialogs/ReopenPlanConfirmation'
import PlannerPostPreview from '@src/modules/planner_v2/components/PlannerPostPreview_v2.vue'
import ApprovalConfirmation from '@src/modules/planner_v2/components/ApprovalConfirmation'
import RejectionConfirmation from '@src/modules/planner_v2/components/RejectionConfirmation'
import {pusherSocketPublish} from '@common/lib/pusher'
import BulkEditPlans from '@src/modules/planner_v2/components/BulkEditPlans'
import SharePlanModal from '@src/modules/planner_v2/components/SharePlanModal'
import ManageLinksModal from '@src/modules/planner_v2/components/ManageLinksModal'
import {EventBus} from '@common/lib/event-bus'
import useTiktokGridView from "@src/modules/planner_v2/composables/SocialMediaManager/Tiktok/useTiktokGridView";
import usePlannerHelper from "@src/modules/planner_v2/composables/usePlannerHelper";
import useSocialGridView from '@src/modules/planner_v2/composables/useSocialGridView'
import useDateFormat from "@common/composables/useDateFormat";
import useWhiteLabelApplication from "@modules/setting/composables/whitelabel/useWhiteLabelApplication";
import usePlatform from "@common/composables/usePlatform";
import useLabelAndCampaignAnalytics
  from "@modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign";
import ApprovalModal from '@src/modules/composer_v2/components/SendForApprovalModal/ApprovalModal'
import {planner} from '@/src/modules/planner/store/mutation-types'
import useInstagramGridView
  from '@/src/modules/planner_v2/composables/SocialMediaManager/Instagram/useInstagramGridView'
import isEmpty from 'is-empty'
const {
  fetching,
} = useSocialGridView()

const {fetchMedia} = useInstagramGridView()
const {fetchMedia: fetchTiktokMedia, hasMoreRemoteMedia, remoteMedia, fetchedMediaCursor} = useTiktokGridView()
const { bulkLabelPlans } = useLabelAndCampaignAnalytics()
const importedMethods = {
  getStatusClass,
}

const plansState = {
  scheduled: 0,
  published: 0,
  partially_failed: 0,
  review: 0,
  rejected: 0,
  missed: 0,
  failed: 0,
  draft: 0,
  repeat: 0,
  article_automations: 0,
  video_automations: 0,
  evergreen_automations: 0,
  rss_automations: 0,
  csv_automations: 0,
  queued: 0,
  composer_folders: [],
}

export default {
  name: 'MainPlanner',
  components: {
    BulkEditPlans,
    PlannerPostPreview,
    ReopenPlanConfirmation,
    FilterSidebar,
    PlannerHeader,
    MovePublicationFolder,
    CreatePublicationFolder,
    TweetsResponseModal,
    FirstCommentResponseModalVue,
    SocialApprovalModal,
    ReplacePlanConfirmation,
    PublishingTimeFeedView,
    ApprovalConfirmation,
    RejectionConfirmation,
    SharePlanModal,
    ManageLinksModal,
    DeletePostModal,
    ApprovalModal
  },
  mixins: [Helpers],

  setup() {
    const {topBannersHeight} = usePlatform()
    const {fetchPlan,fetchPlanAccounts} = usePlannerHelper()
    const {momentWrapper} =useDateFormat()
    const {appData} = useWhiteLabelApplication()

    return {
      fetchPlan,
      fetchPlanAccounts,
      momentWrapper,
      topBannersHeight,
      appData
    }
  },

  data() {
    return {
      scroller: false,
      lastScrollPosition: 0,
      scrollOffset: 40,
      showFilterSidebar: true,
      plansCount: plansState,
      items: [],
      notes: [],
      page: 1,
      limit: 20,
      check_all_statuses: true,
      date_range: '',
      total_items: 0,
      check_all: false,
      check_all_items: false,
      selectedPlans: [],
      calendar_date_range: '',
      check_all_assigned_members: true,
      check_all_created_members: true,
      check_all_labels: true,
      check_all_campaigns: true,
      check_all_categories: true,
      active_planner_filter: {
        type: '',
        statuses: [],
        members: [],
        created_by: [],
        labels: [],
        campaigns: [],
        content_category: [],
        social_accounts: [],
      },
      loader: true,
      previewPlanLoader: false,
      platformSelection: [],
      socialSelection: {},
      blogSelection: {},
      noAccountFilter: false,
      searchQuery: '',
      contentTypes: [
        {name: 'Repeat Posts ', value: 'repeat', countKey: 'repeat'},
        {
          name: 'Article Automations Posts',
          value: 'article_automation',
          countKey: 'article_automations',
        },
        {
          name: 'Video Automations Posts',
          value: 'video_automation',
          countKey: 'video_automations',
        },
        {
          name: 'Evergreen Automations Posts',
          value: 'evergreen_automation',
          countKey: 'evergreen_automations',
        },
        {
          name: 'Rss Automations Posts',
          value: 'rss_automation',
          countKey: 'rss_automations',
        },
        {
          name: 'CSV Automations Posts',
          value: 'csv_automation',
          countKey: 'csv_automations',
        },
        {name: 'Queued Posts', value: 'queued', countKey: 'queued'},
      ],
      searchLoader: false,
      plannerDefaultView: '',
      plannerViews: [
        {view: 'list', routeName: 'planner_list_v2'},
        {view: 'compact_list', routeName: 'planner_list_compact_v2'},
        {view: 'calendar', routeName: 'planner_calendar_v2'},
        {view: 'feed', routeName: 'planner_feed_v2'},
        {view: 'grid_instagram', routeName: 'planner_instagram_grid_view'},
        {view: 'grid_tiktok', routeName: 'planner_tiktok_grid_view'}
      ],
      currentPlanIndex: -1,
      currentPlanInPreview: {},
      planForApproval: null,
      planForReject: null,
      btnLoading: false,
      scrollCount: 0,
      channel: null,
      sortData: {},
      calendarDate: null,
      previewModalOpen: false,
      isBulkApproval: false,
      isBulkReject: false,
    }
  },
  computed: {
    ...mapGetters(['getProfile', 'getActiveWorkspace', 'getPlatformsList', 'getCalendarNotesPreference']),
    getPlannerHeight() {
      const height = `calc(100vh - ${this.topBannersHeight}px)`
        const maxHeight = `calc(100vh - ${this.topBannersHeight}px)`
      return {
        height,
        maxHeight,
      }
    },
    isListView() {
      return (
          this.$route.name === 'planner_list_v2' ||
          this.$route.name === 'planner_list_compact_v2'
      )
    },
    bulkChangeStatusOptionVisibilityStatus() {
      const plans = this.items

      if (
          this.items.length === this.selectedPlans.length &&
          this.items.length !== this.total_items &&
          !this.$route.query.filter_id
      ) {
        const statuses = this.$route.query.statuses
            ? this.$route.query.statuses.split(',')
            : [
              'scheduled',
              'published',
              'failed',
              'rejected',
              'under_review',
              'missed_review',
              'draft',
            ]
        // if more than one plan statuses are showing than option should not be available
        if (statuses.length > 1 || statuses.length === 0) return false
        if (statuses[0] !== 'under_review') return false
      }
      let status = true

      // if all selected plans are not of type under review than  option should not be available
      this.selectedPlans.forEach((selectedPlan) => {
        plans.forEach((plan) => {
          if (plan._id === selectedPlan) {
            if (this.getStatusClass(plan) !== 'reviewed') {
              if (
                  !(
                      plan.status === 'draft' &&
                      plan.approval &&
                      plan.approval.status === 'pending_approval' &&
                      this.checkApprovalStatus(plan.approval)
                  )
              ) {
                status = false
                return false
              }
            } else if (
                plan.approval &&
                plan.approval.status === 'pending_approval' &&
                !this.checkApprovalStatus(plan.approval)
            ) {
              status = false
              return false
            } else if (
                (plan.article_automation_id ||
                    plan.video_automation_id ||
                    plan.evergreen_automation_id ||
                    plan.rss_automation_id ||
                    plan.csv_id) &&
                !this.hasPermission('can_schedule_automation_plan')
            ) {
              status = false
              return false
            }
          }
        })
        if (!status) return false
      })
      return status
    },

    /**
     * Returns true/false if the previous button should be disabled.
     * @returns {boolean}
     */
    planPreviewDisableLeft() {
      return this.currentPlanIndex === 0
    },

    /**
     * Returns true/false if the next button should be disabled.
     * @returns {boolean}
     */
    planPreviewDisableRight() {
      return this.currentPlanIndex === this.items.length - 1
    },

    /**
     * More than one account selected will enable bulk actions
     * @returns {boolean}
     */
    showBulkActions() {
      return this.selectedPlans.length > 1
    },

    selectedPlansObjects() {
      const selectedPlanObjs = []
      this.selectedPlans.forEach((id) => {
        const tempItem = this.items.find((item) => item._id === id)
        if (tempItem) {
          selectedPlanObjs.push(tempItem)
        }
      })
      return selectedPlanObjs
    },
    // Account Selection of all selected plans
    BulkAccountSelection() {
      const accountsByPlatform = {}
      this.selectedPlansObjects.forEach((plan) => {
        if (plan?.account_selection) {
          const social = this.getPlatforms(plan?.account_selection)
          social.forEach((platform) => {
            if (!accountsByPlatform[platform]) {
              accountsByPlatform[platform] = []
            }
            plan?.account_selection[platform]?.forEach((account) => {
              accountsByPlatform[platform].push({
                ...account,
                platform,
              })
            })
          })
        }
      })
      return accountsByPlatform
    },
  },
  watch: {
    sortData(val) {
      this.setPlannerDefaultSort(val)
    },
    scroll(val) {
      if (!val) {
        const nodeEl = document.getElementsByClassName(
            'fc-scrollgrid-section-header'
        )
        if (nodeEl.length) {
          nodeEl[0].style.top = '3.1rem'
        }
      }
    },
    showFilterSidebar(value) {
      this.$cookie.set('isPlannerSidebarExpanded', JSON.stringify(value))
    },
    plansCount(val) {
      this.$store.commit(planner.SET_COUNT, val)
    },
    items(val) {
      this.$store.commit(planner.SET_ITEMS, val)
    },
    total_items(val) {
      this.$store.commit(planner.SET_TOTAL_ITEMS, val)
    },
  },
  /**
   * 1. Checks and redirects the user preferred view.
   * 2. The <FilterDropdown /> component inside <FilterSidebar /> checks for the default filter and applies it.
   *    If there are no default filters it emits an event to fetch plans.
   * 3. Event listener ('refreshPlannerTableV2') for refreshing the planner table.
   */
  mounted() {
    console.log('Mounted::MainPlanner.vue')
    // subscribing to pusher comments channel
    this.channel = pusherSocketPublish.subscribe(
        `comments_${this.getActiveWorkspace._id}`
    )
    this.isCookieValueAvailable()

    this.bindPusherComments()
    // don't need this anymore as we're using the default view from the route
    EventBus.$on('refreshPlannerTableV2', async () => {
     await this.refreshTable()
    })
    EventBus.$on('update-plan-status', ({id, res}) => {
      this.updatePlanStatus(id, res)
    })
    EventBus.$on('update-plans-count', () => {
      this.updatePlansCount()
    })
    EventBus.$on('preview-plan', (planId) => {
      this.previewPlan(planId)
    })
    EventBus.$on('refetch-more', () => {
      this.handleMoreFetch(null)
    })
    EventBus.$on('fetch-plans-after-delete-actions', () => {
      this.fetchPlansAfterDelete()
    })
    EventBus.$on('fetch-plans-after-delete-update-preview', async (event) => {
      // if plan is deleted from preview, then update the preview and fetch the next plan else don't update anything related the preview
      if(this.previewModalOpen) {
        if (event.fetchNextPlan) {
          // Case 2: Preview is open and fetchNextPlan is true
          this.previewNextPlan(true);
        } else {
          // Case 1: Preview is open and fetchNextPlan is false
          this.previewPlan(this.currentPlanInPreview._id);
        }
      }
      if(event.refetchPlans) {
        // refetch the plans
        await this.reFetchPlans()
      }
    })
    EventBus.$on('refresh-table', () => {
      this.refreshTable()
    })

    EventBus.$on('refetch-plans', async () => {
      this.$store.commit(planner.SET_ITEMS, [])
      await this.reFetchPlans()
    })
    EventBus.$on('grid-view-tab-changed', () => {
      this.page = 1
    })
    EventBus.$on('createNewCampaignFolder',(newFolder) => {
      if(newFolder) {
        this.plansCount.composer_folders.push(newFolder)
      }
    })
    EventBus.$on('increase-comment-count', ({id, isNote}) => {
      this.increaseCommentCount(id, isNote)
    })
    EventBus.$on('decrease-comment-count', ({id, isNote}) => {
      this.decreaseCommentCount(id, isNote)
    })

    this.page = 1
  },
  beforeUnmount() {
    if (this.channel) {
      this.channel.unbind('store_comment')
      this.channel.unbind('delete_comment')
      this.channel.unsubscribe(`comments_${this.getActiveWorkspace._id}`)
    }
    EventBus.$off('update-plan-status')
    EventBus.$off('update-plans-count')
    EventBus.$off('preview-plan')
    EventBus.$off('refetch-more')
    EventBus.$off('fetch-plans-after-delete-actions')
    EventBus.$off('refetch-plans')
    EventBus.$off('grid-view-tab-changed')
    EventBus.$off('createNewCampaignFolder')
    EventBus.$off('fetch-plans-after-delete-update-preview')
    EventBus.$off('increase-comment-count')
    EventBus.$off('decrease-comment-count')
    EventBus.$off('refresh-table')
  },
  methods: {
    ...importedMethods,
    getPlatforms(platforms) {
      return socialChannelsNameArray.filter(platform => !isEmpty(platforms[platform]));
    },
    handlePlansCountReset() {
      this.plansCount = plansState
    },
    emptyPlannerItems() {
      console.log('emptyPlannerItems')
      this.items = []
    },
    handleQueryChange(query) {
      console.debug('METHOD::handleQueryChange')
      this.page = 1
      if (
          this.$route.name === 'planner_calendar_v2' &&
          !this.$route.query.date
      ) {
        this.$route.query.date = this.calendarDate
      }
      this.reFetchPlans()
    },
    isCookieValueAvailable() {
      const cookieValue = JSON.parse(
          this.$cookie.get('isPlannerSidebarExpanded') || null
      )
      if (cookieValue !== null) {
        this.showFilterSidebar = cookieValue
      }
    },
    handleBulkAction(type, callback) {
      console.debug('checkpoint:: METHOD::handleBulkAction', type)
      switch (type) {
        case 'delete':
          this.removeBulkPlans(this.selectedPlans, callback)
          break
        case 'move':
          this.openMoveCampaignModel(null, this.selectedPlans)
          break
        case 'share':
          this.openSharePlanModel(this.selectedPlans)
          break
        case 'approve':
          this.isBulkApproval = true
          this.$bvModal.show('approve-post-modal')
          break
        case 'reject':
          this.isBulkReject = true
          this.$bvModal.show('reject-post-modal')
          break
        case 'edit':
          this.processPlanBulkEdit()
          break
        case 'label':
          bulkLabelPlans.value = this.selectedPlans
          break
        case 'share-calendar-view':
          this.shareCalendarView()
          break
        case 'bulk-approval':
          this.$bvModal.show('bulk-approval-modal')
          break
      }
    },
    async processPlanBulkOperation(operation = 'scheduled') {
      console.log('METHOD::processPlanBulkOperation ~ operation -> ', operation)

      this.processing_loader = true
      console.debug('Method:processPlanBulkOperation', operation)

      let data = {}
      // const plans = this.items
      if (this.check_all_items) {
        data = await this.$store.dispatch(
            'getPlansRequestFilters',
            this.$route
        )
        delete data.order
        delete data.page
        delete data.limit
        data.platformSelection = [...this.platformSelection]
        data.operation = operation
        delete data.specific_plans
      }

      if (!this.check_all_items) {
        data = {
          operation,
          selected_plans: this.selectedPlans,
          workspace_id: this.$store.getters.getWorkspaces.activeWorkspace._id,
        }
      }
      return proxy
          .post(processPlanBulkOperationURL, data)
          .then((response) => {
            if (response.data.status === true) {

              // hide the modal after bulk approval and set the isBulkApproval to false
              if(this.isBulkApproval) {
                this.$bvModal.hide('approve-post-modal')
                this.isBulkApproval = false
              }

              // hide the modal after bulk rejection and set the isBulkReject to false
              if(this.isBulkReject) {
                this.$bvModal.hide('reject-post-modal')
                this.isBulkReject = false
              }

              this.$store.dispatch('toastNotification', {
                message: BULK_OPERATION_SUCCESS,
                type: 'success',
              })
              this.check_all_items = false
              if (response) {
                this.reFetchPlans()
              }
              this.processing_loader = false

              this.refreshTable()
              return response
            }
            this.$store.dispatch('toastNotification', {
              message: BULK_OPERATION_ERROR,
              type: 'error',
            })
            return false
          })
          .catch((error) => {
            console.debug('Exception in processPlanBulkOperation', error)
            return false
          })

    },
    async removeBulkPlans(plans, flushSelectedPlansCB) {
      console.debug('Method::removePlan', this.check_all_items)
      if (this.check_all_items) {
        await this.processPlanBulkOperation('deleted')
        return
      }
      const res = await this.$bvModal.msgBoxConfirm(
          `Your posts will only be deleted from ${this.appData.businessName}. Are you sure you want to delete your posts?`,
          {
            title: 'Remove Posts',
            ...swalAttributes(),
          }
      )

      if (res) {
        const response = await this.$store.dispatch('removePlan', {
          id: plans,
          type: 'publication',
        })
        if (!response) {
          this.$store.dispatch('toastNotification', {
            message: 'An error while removing your posts, please try again.',
            type: 'error',
          })
        } else {
          plans.forEach((plan) => {
            this.items = this.items.filter((item) => item._id !== plan)
          })
          // cleaning selectedItems array in CstDatatable after removing bulk plans.
          flushSelectedPlansCB()
          this.fetchPlansAfterDelete()
        }
      }
    },
    handleItemSelection(items) {
      this.selectedPlans = items
    },
    handleMoreFetch(cb) {
      this.page++
      this.reFetchPlans(cb)
    },
    closePreview() {
      this.previewModalOpen = false
    },
    handleSocialAccountSelection(accounts, noAccount = false) {
      console.log(
          'METHOD::handleSocialAccountSelection ~ accounts, noACcount -> ',
          accounts,
          noAccount
      )
      this.platformSelection = [...accounts]
      this.noAccountFilter = noAccount
      this.page = 1
      // accounts selection for backend query
      this.socialSelection = {};
      this.blogSelection = {};
      if (accounts.length && this.getPlatformsList.length) {
        accounts.forEach((account) => {
          const matchingItem = this.getPlatformsList.find((item) => {
            return (
                (item.platform_type === 'blog' && item.platform_identifier === account) ||
                (item.platform_type === 'social' && item[item.channel_identifier] === account)
            );
          });

          if (matchingItem) {
            if (matchingItem.platform_type === 'social') {
              this.socialSelection[matchingItem.channel_type] = this.socialSelection[matchingItem.channel_type] || [];
              this.socialSelection[matchingItem.channel_type].push(matchingItem[matchingItem.channel_identifier])
            } else {
              this.blogSelection[matchingItem.integration] = this.blogSelection[matchingItem.integration] || [];
              this.blogSelection[matchingItem.integration].push(matchingItem.platform_identifier)
            }
          }
        });
      }
    },

    async getFetchPlansPayload() {
      const payload = await this.$store.dispatch(
          'getPlansRequestFilters',
          this.$route
      )
      payload.source = "web";  // to identify the source of the request from web not mobile app
      if (payload.type && payload.type !== '') {
        payload.type =
            payload.type.split(',')?.length === this.contentTypes.length
                ? []
                : payload.type?.split(',')
                    ? payload.type?.split(',')
                    : []
      } else {
        payload.type = []
      }
      // Social Account case
      payload.platformSelection = [...this.platformSelection]
      payload.social_selection = this.socialSelection;
      payload.blog_selection = this.blogSelection;
      this.$route.name === 'planner_calendar_v2'
          ? delete payload.page
          : (payload.page = this.page)

      payload.no_social_account = this.noAccountFilter
      if (
          this.searchQuery !== '' &&
          (this.$route.name !== 'planner_calendar_v2' || this.featureFlag())
      ) {
        payload.search = this.searchQuery
      }

      if (payload.page && !payload.limit) {
        payload.limit = this.limit
      }
      return payload
    },

    async reFetchPlans(cb = null) {
      // refetch plans after filter change
      console.log('METHOD::reFetchPlans')

      const payload = await this.getFetchPlansPayload()

      this.fetchPlansCall(payload, (state) => {
        if (!cb) {
          this.loader = state
        }
      })
          .then((response) => {
            if (response.data.status) {
              let plans = []

              plans = response.data.plans.data
                  ? response.data.plans.data
                  : response.data.plans
              console.log('Planner', Router.currentRoute, Router)
              if (plans.length && Router.currentRoute.value.query.plan_id) {
                plans.is_active = true
                Router.push({name: 'planner_list_v2'})
              }

              if (this.$route.name === 'planner_calendar_v2') {
                console.log('calendar name...')

                // const workspaceTimezone =
                //     this.getWorkspaces.activeWorkspace.timezone
                // plans.map((planItem) => {
                //   planItem.start = getWorkspaceTimeZoneTime(
                //       planItem.execution_time.date,
                //       workspaceTimezone,
                //       'YYYY-MM-DD'
                //   )
                //   if (planItem.post_status === 'published') {
                //     planItem.editable = false
                //   }
                // })
              }

              this.notes = response.data.notes ?? []

              this.total_items = response.data.plans.data
                  ? response.data.plans.total
                  : response.data.total_plans
                      ? response.data.total_plans
                      : 0;


              this.items = this.page > 1 ? this.items.concat(plans) : plans

              if (cb) {
                if (plans.length) {
                  console.log('plans loaded', plans)
                  cb(false) // eslint-disable-line standard/no-callback-literal
                } else {
                  cb(true) // eslint-disable-line standard/no-callback-literal
                }
              }

              this.searchLoader = false
            }
            console.log('ListView response', response)
          })
          .catch((error) => {
            console.log('Fetch plans Error', error)
          })
      this.getPlanCountData(payload)
    },

    /**
     * Gets the plans count for each status.
     * @param payload
     */
    getPlanCountData(payload) {
      // only fetch count for first page or when page is not set (calendar view)
      if (!payload.page || payload.page === 1) {
        this.fetchPlansCount(payload).then((response) => {
          if (response) {
            this.plansCount = response
          }
        })
      }
    },

    async fetchPlansAfterDelete() {
      console.log('METHOD::fetchPlansAfterDelete')
      if (this.$route.name !== 'planner_calendar_v2') {
        console.log('METHOD::fetchPlansAfterDelete')

        const payload = await this.getFetchPlansPayload()


        this.fetchPlansCall(payload)
            .then((response) => {
              if (response.data.status) {

                let plans = []
                const newPlans = []

                plans = response.data.plans.data
                    ? response.data.plans.data
                    : response.data.plans

                if (plans.length) {
                  const workspaceTimezone =
                      this.getWorkspaces.activeWorkspace.timezone

                  plans.map((planItem) => {
                    const index = this.items.findIndex(
                        (item) => item._id === planItem._id
                    )
                    if (index === -1) {
                      planItem.start = getWorkspaceTimeZoneTime(
                          planItem.execution_time.date,
                          workspaceTimezone,
                          'YYYY-MM-DD'
                      )
                      if (planItem.post_status === 'published') {
                        planItem.editable = false
                      }
                      newPlans.push(planItem)
                    }
                  })
                }

                if (newPlans.length) {
                  this.items = this.items.concat(newPlans)
                }

                this.total_items = response.data.plans.data
                    ? response.data.plans.total
                    : 1
              }
            })
            .catch((error) => {
              console.log('Fetch plans Error', error)
            })
        this.getPlanCountData(payload)
      }
    },

    /**
     * onClick event for refreshing table data.
     * this will not reset the filters, instead it will apply the currently selected filters.

     */
    async refreshTable() {
      if (
          this.$el &&
          this.$refs['filter-sidebar'] &&
          this.$refs['filter-sidebar'].handleApply
      ) {

        // if user is on grid view will also fetch the remote media
        if (this.isGridView) {
          fetching.value = true
          if (this.$route.name.includes('tiktok')) {
            hasMoreRemoteMedia.value = true
            remoteMedia.value = []
            fetchedMediaCursor.value = null
            await fetchTiktokMedia()
          } else {
            await fetchMedia()
          }
        }
        this.$refs['filter-sidebar'].handleApply(null, true)
      }
    },

    /**
     * sets all items in the table checked.
     * @param val
     */
    setCheckAllItems(val) {
      console.log('METHOD::setCheckAllItems ~ val -> ', val)
      this.check_all_items = val
    },

    /**
     * unselects all tale items on a single click.
     * @param val
     */
    unSelectAllTableItems(val) {
      console.log('METHOD::unSelectAllTableItems ~ val ->  ', val)
      this.check_all_items = val
    },

    /**
     * sets the search query value and runs a search. Re-fetches all plans if the search query is empty
     * @param val
     */
    setSearchQuery(val) {
      console.log('METHOD::setSearchQuery ~ val ->', val)
      this.searchQuery = val
      if (val !== '') {
        this.searchLoader = true
        this.reFetchPlans()
      } else {
        this.searchLoader = false
        this.page = 1
        this.reFetchPlans()
      }
    },

    /**
     * fetches the user prefered default view from store and navigates to that view.
     */
    handlePlannerDefaultView() {
      if (!location.pathname.includes('/composer')) {
        // if in composer view (for socialModal), then don't navigate to default view
        this.plannerDefaultView = this.getProfile.planner_default_view
        const defaultRouteName = this.plannerViews.find(
            (item) => item.view === this.plannerDefaultView
        )
        this.$router.push({
          name: defaultRouteName.routeName,
          query: this.$route.query,
        })
      }
    },
    /**
     * Handle set Default view event
     * @param view
     */
    defaultViewHandler(view) {
      console.debug('defaultViewHandler', view)
      const viewName = this.plannerViews.find((item) => item.routeName === view)
      if (viewName) {
        this.setPlannerDefaultView(viewName.view)
      }
    },

    /**
     * Sets the user default view based on the current user route
     * @param view
     */
    setPlannerDefaultView(view) {
      const self = this
      proxy
          .post(plannerDefaultView, {default_view: view})
          .then(() => {
            self.$store.dispatch('setDefaultView', view)
          })
          .catch((err) => {
            console.log('PROXY::plannerDefaultView ~ err -> ', err)
          })
    },

    /**
     * Sets user default sort based on current selected sort option
     * @param sort
     */
    setPlannerDefaultSort(sort) {
      const self = this
      proxy
          .post(plannerDefaultSort, {default_sort: sort})
          .then(() => {
            self.$store.dispatch('setDefaultSort', sort)
          })
          .catch((err) => {
            console.log('PROXY::plannerDefaultView ~ err -> ', err)
          })
    },

    async updatePlansCount() {
      const payload = await this.$store.dispatch(
          'getPlansRequestFilters',
          this.$route
      )
      if (payload.type && payload.type !== '') {
        payload.type =
            payload.type.split(',')?.length === this.contentTypes.length
                ? []
                : payload.type?.split(',')
                    ? payload.type?.split(',')
                    : []
      } else {
        payload.type = []
      }
      // Social Account case
      payload.platformSelection = [...this.platformSelection]
      this.$route.name === 'planner_calendar_v2'
          ? delete payload.page
          : (payload.page = this.page)

      payload.no_social_account = this.noAccountFilter
      if (
          this.searchQuery !== '' &&
          this.$route.name !== 'planner_calendar_v2'
      ) {
        payload.search = this.searchQuery
      }

      if (payload.page && !payload.limit) {
        payload.limit = this.limit
      }

      const counts = await this.fetchPlansCount(payload)
      if (counts) {
        this.plansCount = counts
      }
    },

    async updatePlanStatus(id, res) {

      let nbr = -1;
      const element = this.items.find(item => item._id === id);

      if (element) {
        console.log('updatePlanStatus ~ element -> ', res);
        nbr = this.items.indexOf(element);

        if (res.data?.data) {
          element.approval = res.data?.data;
        }
        element.status = res.data.plan_status;
        if (res.data?.execution_time) {
          element.execution_time = res.data.execution_time;
        }

        switch (res.data.plan_status) {
          case 'scheduled':
            element.render_class.tooltip = 'Scheduled';
            element.render_class.icon = 'far fa-clock';
            if (element.approval?.status) {
              element.approval.status = 'completed_approval';
            }
            element.post_state = 'scheduled';
            break;
          case 'review':
            if (element.execution_time.date < this.momentWrapper().formatDateTime()) {
              element.post_state = 'missedReview';
              element.render_class.icon = 'far fa-eye-slash';
              element.render_class.tooltip = 'Missed review';
            } else {
              element.post_state = 'reviewed';
              element.render_class.icon = 'far fa-eye';
              element.render_class.tooltip = 'In review';
            }
            break;
          case 'rejected':
            element.post_state = 'rejected';
            element.render_class.tooltip = 'Rejected';
            element.render_class.icon = 'far fa-times-circle';
            break;
          case 'draft':
            element.post_state = 'draft';
            element.render_class.tooltip = 'Draft';
            element.render_class.icon = 'far fa-folder-plus';
            break;
          case 'queued':
            element.post_state = 'queued';
            element.render_class.tooltip = 'In Progress';
            element.render_class.icon = 'far fa-clock';
            break;
          case 'published':
            element.post_state = 'published'
            element.render_class.tooltip = 'Published'
            element.render_class.icon = 'far fa-check-circle'
            if (res.data?.posting && res.data.posting.length > 0) {
              element.posting = res.data.posting
            }
            if (element.can_perform) {
              element.can_perform.edit = false
              element.can_perform.duplicate = true
            }
            break;
          case 'failed':
            element.post_state = 'failed'
            element.render_class.tooltip = 'Failed'
            element.render_class.icon = 'far fa-exclamation-circle'
            if (res.data?.posting && res.data.posting.length > 0) {
              element.posting = res.data.posting
            }
            break
        }
      }
      const statuses = this.$route.query.statuses
          ? this.$route.query.statuses.split(',')
          : ''
      console.log('updatePlanStatus ~ statuses -> ', statuses, res.data.plan_status);
      if (statuses && !statuses.includes(res.data.plan_status) && nbr > -1) {
        this.items.splice(nbr, 1)
      }

      if (res?.data?.refresh_count) {
        this.updatePlansCount?.()
      }

      return true;
    },

    /**
     * Set Plan for preview
     * @param planIndex - index of the plan in items array
     * @returns void
     */
    setPlanForPreview(planIndex) {
      this.$bvModal.show('planner-post-preview')
      this.currentPlanIndex = planIndex
      // render_class is not available after calendar performance improvement ( extra fields removed from planner response)
      // so we need to fetch the plan again for preview
      if (this.items[planIndex]?.render_class && !this.items[planIndex]?.preview) {
        this.currentPlanInPreview = this.items[planIndex]
        return
      }
      if (!this.items[planIndex]?._id) return
      this.previewPlanLoader = true
      this.fetchPlan(this.items[planIndex]?._id).then((res) => {
        if (res) {
          this.currentPlanInPreview = res
          this.previewModalOpen = true
        } else {
          this.$store.dispatch('toastNotification', {
            message: 'Post(s) has been deleted from the planner.',
            type: 'error',
          })
          this.$bvModal.hide('planner-post-preview')
          this.previewModalOpen = false
        }
        this.previewPlanLoader = false
      })
    },

    /**
     * Event fired from PlannerPostPreview to show a modal and preview the selected plan
     * @param planId
     */
    previewPlan(planId) {
      console.log('METHOD::previewPlan ~ planId -> ', planId)
      const planIndex = this.items.findIndex((item) => item._id === planId)
      if (planIndex > -1) {
        this.setPlanForPreview(planIndex)
      }
    },

    /**
     * onClick event for previewing next plan
     */
    previewNextPlan(removed = false) {
      if (
          this.total_items - this.items.length > 0 &&
          this.items.length - this.currentPlanIndex <= 5 &&
          !this.loader
      ) {
        this.handleMoreFetch(null)
      }
      if (this.currentPlanIndex === this.items.length - 1 && removed) {
        this.$bvModal.hide('planner-post-preview')
        this.currentPlanIndex = -1
        this.currentPlanInPreview = null
        return
      }
      this.setPlanForPreview(++this.currentPlanIndex)
    },

    /**
     * onClick event for previewing previous plan
     */
    previewPreviousPlan() {
      console.log('METHOD::previewPreviousPlan')
      if (this.currentPlanIndex === 0) return
      this.setPlanForPreview(--this.currentPlanIndex)
    },

    /**
     * Event for displaying the approve modal
     * @param item
     */
    approveWithComment(item) {
      console.log('METHOD::approveWithComment ~ item -> ', item)
      this.planForApproval = item
      this.$bvModal.show('approve-post-modal')
    },

    /**
     * Event for displaying the reject modal
     * @param item
     */
    rejectWithComment(item) {
      console.log('METHOD::rejectWithComment ~ item -> ', item)
      this.planForReject = item
      this.$bvModal.show('reject-post-modal')
    },

    /**
     * Handling the approval/rejection of a plan with comment.
     * @param plan
     * @param status
     * @param comment
     * @param postComment
     * @returns {Promise<void>}
     */
    async callProcessPlanApproval(plan, status, comment = false, postComment) {
      const self = this
      console.debug('callProcessPlanApproval::', postComment)
      // if bulk approval is true then call the bulk approval method
      if (this.isBulkApproval) {
        await this.processPlanBulkOperation('scheduled')
        return
      }

      // if bulk reject is true then call the bulk reject method
      if (this.isBulkReject) {
        await this.processPlanBulkOperation('rejected')
        return
      }

      self.btnLoading = true

      if (status === 'approve' && postComment.approvePostComment === '') {
        comment = false
      } else if (status === 'reject' && postComment?.rejectPostComment === '') {
        comment = false
        this.alertMessage('Comment is required on post rejection', 'error')
        // this.$bvModal.hide('reject-post-modal')
        self.btnLoading = false
        return
      }

      plan.new_comment = postComment.approvePostComment
          ? postComment.approvePostComment
          : postComment.rejectPostComment
      plan.new_comment_media = postComment.media ? postComment.media : []

      const res = this.processPlanApproval(plan, status, comment)
      if (res) {
        this.$emit('update-plans-count')
        if (res && comment) await this.addCommentAction(plan, true)
      }
      if (status === 'approve') {
        this.$bvModal.hide('approve-post-modal')
        this.planForApproval = null
        this.approvePostComment = ''
      } else if (status === 'reject') {
        this.$bvModal.hide('reject-post-modal')
        this.planForReject = null
        this.rejectPostComment = ''
      }
      this.btnLoading = false
      this.$bvModal.hide('planner-post-preview')
    },

    /**
     * Adds comment to the plan while approving/rejecting a plan with comment if the comment existed
     * @param item
     * @param notification
     * @returns {Promise<boolean>}
     */
    async addCommentAction(item, notification = false) {
      console.debug('addCommentAction', item)

      if (!item.new_comment || item.new_comment.trim().length === 0) {
        this.$store.dispatch('toastNotification', {
          message: 'Please add comment.',
          type: 'error',
        })
        return false
      }
      this.$set(item, 'loader_store', true)

      const payload = {
        workspace_id: item.workspace_id,
        plan_id: item._id,
        comment: item.new_comment,
        mentioned_user: this.mentionedUserIdsList(item.new_comment),
        type: this.$route.name === 'planner_list_v2' ? 'list_view' : '',
        title: item.title || null,
        is_note: false,
        media: item.new_comment_media ? item.new_comment_media : [],
        notification,
      }
      const res = await this.$store.dispatch('storeFeedViewComment', payload)
      this.$set(item, 'loader_store', false)
      console.log('stored comment response > ', res)
      if (res) {
        if (res.data.status) {
          if (!item.comments) item.comments = []
          item.new_comment = ''
          item.write_comment = false
          item.new_comment_media = []
          this.messageType = 'message'
        } else {
          this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'error',
          })
        }
        this.toggleStatus = true
      }
    },

    /**
     * onclick event for closing the approval modal dialog box
     */
    onClickCancelApprove() {
      this.planForApproval = null
      this.approvePostComment = ''
      this.isBulkApproval = false
      this.$bvModal.hide('approve-post-modal')
    },

    /**
     * onclick event for closing the approval modal dialog box
     */
    onClickCancelReject() {
      this.planForReject = null
      this.rejectPostComment = ''
      this.$bvModal.hide('reject-post-modal')
    },

    /**
     * click capture event for editing a plan
     * @param item
     */
    editPlan(item) {
      console.debug('Method::editPlan', item)
      item.stateObject = this
      this.$store.dispatch('editPlan', item)
    },

    /**
     * click capture event for deleting a plan.
     * @param plan
     * @returns {Promise<void>}
     */
    async deletePlan(plan, status,hasBlogReference) {
      console.debug('Method::removePlan', plan)
      if (status === 'published' && !hasBlogReference) {
        await this.fetchPlanAccounts(plan)
        this.$bvModal.show('delete-post-modal')
        return
      }
      const res = await this.$bvModal.msgBoxConfirm(
          'Are you sure you want to delete your post?',
          {
            title: 'Remove Post',
            ...swalAttributes(),
          }
      )
      if (res) {
        const response = await this.$store.dispatch('removePlan', {id: plan})
        if (!response) {
          this.$store.dispatch('toastNotification', {
            message: 'An error while removing your post, please try again.',
            type: 'error',
          })
        } else {
          this.$store.dispatch('toastNotification', {
            message: 'Post removed successfully.',
            type: 'success',
          })
          this.previewNextPlan(true)
          this.items.splice(
              this.items.findIndex((item) => item._id === plan),
              1
          )
          this.$emit('fetch-plans-after-delete')
        }
      }

    },

    /**
     * Calls an api to replace post and update the item with the newly replaced post.
     * @param plan
     * @returns {Promise<void>}
     */
    async replacePlan(plan) {
      console.log('METHOD::replacePlan ~ plan -> ', plan)
      const response = await this.$bvModal.msgBoxConfirm(
          "You won't be able to undo",
          {title: 'Replace Post?', ...swalAttributes()}
      )
      if (!response) return

      this.$store.dispatch('toastNotification', {
        message: 'Replacing post...',
        type: 'info',
      })

      const payload = {
        id: plan._id,
        workspace_id: this.$store.getters.getWorkspaces.activeWorkspace._id,
        route_name: Router.currentRoute.value.name,
      }

      if (plan.article_automation_id) {
        payload.type = 'Article Automation'
        payload.automation_id = plan.article_automation_id
      }
      if (plan.video_automation_id) {
        payload.type = 'Video Automation'
        payload.automation_id = plan.video_automation_id
      }

      proxy
          .post(replacePlanURL, payload)
          .then((response) => {
            if (response.data.status) {
              const foundItem = this.items.find(item => item._id === plan._id);
              if (foundItem) {
                if (response.data.item.post) {
                  foundItem.publish_source = response.data.item.publish_source;
                  foundItem.post = response.data.item.post;
                } else {
                  for (const key in response.data.item) {
                    if (typeof response.data.item[key] !== 'function') {
                      foundItem[key] = response.data.item[key];
                    }
                  }
                }
              }
              // this.items.forEach((element, index) => {
              //   if (element._id === plan._id) {
              //     if (response.data.item.post) {
              //       this.items[index].publish_source =
              //           response.data.item.publish_source
              //       this.items[index].post = response.data.item.post
              //       return false
              //     }
              //     for (const key in response.data.item) {
              //       if (typeof response.data.item[key] !== 'function') {
              //         const plan = this.items[index]
              //         plan[key] = response.data.item[key]
              //         this.items[index] = plan
              //       }
              //     }
              //     return false
              //   }
              // })
            } else {
              this.$store.dispatch('toastNotification', {
                message: response.data.message,
                type: 'error',
              })
            }
          })
          .catch((error) => {
            console.debug('Exception in replacePlan', error)
            this.$store.dispatch('toastNotification', {
              message: UNKNOWN_ERROR,
              type: 'error',
            })
          })
    },

    /**
     * OnClick capture event for duplicating a plan
     * @param item
     */
    duplicatePlan(item) {
      console.debug('Method::duplicatePlan', item)
      item.stateObject = this
      this.$store.dispatch('clonePlan', item)
    },

    /**
     * binding pusher events so that upon trigger, comments can be updated.
     * NOTE: Events are being triggered from backend
     * listens for store_comment and delete_comment event for comments and updates the local comments state of plans.
     */
    bindPusherComments() {
      console.log('METHOD::bindPusherComments')
      this.channel.bind('store_comment', (data) => {
        const plan = this.items.find((item) => item._id === data.plan_id)
        if (plan && plan.comments) {
          if (plan.comments?.length > 0) {
            const comment = plan.comments.find(
                (commentItem) => commentItem._id === data._id
            )
            if (!comment) {
              plan.comments.unshift(data)
            } else {
              const commentItemIndex = plan.comments.findIndex(
                  (commentItem) => commentItem._id === data._id
              )
              if (commentItemIndex >= 0) {
                plan.comments.splice(commentItemIndex, 1, data)
              }
            }
          } else {
            plan.comments.unshift(data)
          }
        }
      })
      this.channel.bind('delete_comment', (data) => {
        const plan = this.items.find((item) => item._id === data.plan_id)
        if (plan) {
          const getCommentIndex = plan.comments?.findIndex(
              (commentItem) => commentItem._id === data._id
          )
          if (getCommentIndex >= 0) plan.comments.splice(getCommentIndex, 1)
        }
      })
    },

    /**
     * @description sorts the plans w.r.t the column name and order received from planner header. (schedule) sort if for (scheduled+in_review)
     * @param column to be sorted
     * @param order of sorting
     */
    sortHandler(column, order) {
      console.log('METHOD::sortHandler ~ column, order -> ', column, order)
      this.sortData = {
        column,
        order,
      }
      const query = Object.assign({}, this.$route.query)

      query.order_by = order
      query.sort_column = column
      if (this.$refs['filter-sidebar']) {
        this.$refs['filter-sidebar'].sort = query.order_by
        this.$refs['filter-sidebar'].handleApply(query)
      }
    },

    handleCalendarDateChange(date) {
      console.log('METHOD::handleCalendarDateChange ~ date -> ', date)
      this.calendarDate = date
      this.items = []
    },

    processPlanBulkEdit() {
      console.log(
          'METHOD::processPlanBulkEdit ~ this.selectedPlans -> ',
          this.selectedPlans
      )
      // showBulkActions
      // bulk-edit-plans
      this.$bvModal.show('bulk-edit-plans')
    },
    increaseCommentCount(id, isNote){
      // update the comment count in the items array only in list and compact list view
      if (this.$route.name === 'planner_list_v2' || this.$route.name === 'planner_compact_list_v2') {
        this.items.map((item) => {
          if (item._id === id) {
            item.comment_counts[isNote ? 'note' : 'comment'] += 1;
            item.comment_counts.total += 1;
          }
          return item
        })
      }
    },
    decreaseCommentCount(id, isNote){
      // update the comment count in the items array only in list and compact list view
      if (this.$route.name === 'planner_list_v2' || this.$route.name === 'planner_compact_list_v2') {
        this.items.map((item) => {
          if (item._id === id) {
            item.comment_counts[isNote ? 'note' : 'comment'] -= 1;
            item.comment_counts.total -= 1;
          }
          return item
        })
      }
    },

    /**
     * Method to share the calendar view via link
     */
    shareCalendarView() {
      const planIds = this.items.map((item) => item._id)
      const notes = this.notes.filter(item => item.is_private === false)

      if ((planIds.length === 0 && (this.getCalendarNotesPreference && !notes.length)) ||
        (planIds.length === 0 && !this.getCalendarNotesPreference)) {
        this.$store.dispatch('toastNotification', {
          message: "You don't have any public content to share in calendar view.",
          type: 'error',
        })
        return
      }

      this.shareNotes()

      EventBus.$emit('share-plan::show', planIds)
    },
    /**
     * Method to share the notes via link
     */
    shareNotes(notes = []) {
      let noteIds= []

      if(this.getCalendarNotesPreference) {
        noteIds= this.notes
            .filter(item => item.is_private === false)
            .map(item => item._id);
      }

      EventBus.$emit('share-notes::show', notes.length ? notes : noteIds)
    }
  },
}
</script>

<style scoped lang="scss">
.main {
  display: flex;
  flex-direction: row;
}

#reject-post-modal {
  &___BV_modal_outer_ {
    z-index: 99999999 !important;
  }
}

#approve-post-modal {
  &___BV_modal_outer_ {
    z-index: 99999999 !important;
  }
}
</style>
