<template>
  <div class="cst-editor color-border bg-white rounded-xl relative mt-3">
    <!-- Pinterest Post Title -->
    <div
        v-if="
        accountsData.accountSelection.pinterest.length &&
        (type === 'common' || type === 'pinterest')
      "
        class="px-3 py-2"
    >
      <div>
        <input
            id="pin-title"
            v-model="sharingDetails.pinterest_title"
            class="border-0 bg-white mb-1 font-normal text-sm w-full text-black-200"
            placeholder="Title for Pinterest"
            maxlength="100"
        />
      </div>
      <hr class="m-0 p-0 hr-color" />
    </div>

    <div
        v-if="dragEntered"
        class="absolute z-99 w-full rounded-lg h-full text-[#2e7cff] backdrop-blur-[1px] border-[1px] !border-dashed border-[#2e7cff] flex items-center justify-center flex-col"
        @dragover="handleDragEnter"
        @drop="handleDrop"
        @dragleave="handleDragLeave"
    >
        <img
        src="@assets/img/composer/upload-icon.svg"
        alt=""
        class="w-10 h-10 my-2 drop-shadow-lg" />
        <p class="font-bold drop-shadow-md">Drop here</p>

    </div>

    <CstTextArea
        :key="getEditorKey()"
        ref="editorElement"
        :placeholder="type === 'common' ? commonPlaceHolder : singlePlaceHolder"
        :get-suggestions="getSuggestions"
        :initial-value="sharingDetails.message"
        :max-limit="maxLimit"
        :twitter-text-enable="twitterTextEnable"
        :get-suggestions-loader="getSuggestionsLoader"
        :saved-links="savedLinks"
        :type="type"
        @change="handleChange"
        @paste="handlePaste"
        @drag-enter="handleDragEnter"
        @handle-keydown="hanldeKeyboardOptions"

    />

    <!-- AI Action Buttons -->
    <div class="flex items-center mx-2 2xl:mx-4 gap-x-2 2xl:gap-x-3 pb-2">
      <button
        v-if="!templateText"
        class="flex items-center gap-x-2 px-2 py-1 rounded-lg bg-white hover:!bg-yellow-500/10 border-[1.5px] !border-dashed border-yellow-500/60"
         @click="handleAIClick('ai-caption-generation')"
        >
        <img
          class="h-4 w-4"
          src="@assets/img/common/write-ai.svg"
          alt="Write with AI"
        />
        <span class="text-[#4a4a4a] text-xs 2xl:text-sm pt-[1px] text-nowrap leading-none">Write with AI</span>
      </button>

      <button
        class="flex items-center gap-x-2 px-2 py-1 rounded-lg bg-white hover:!bg-purple-500/10 border-[1.5px] !border-dashed border-purple-500/60"
        @click="handleAIClick('ai-image-generation')"
        >
        <img
          class="h-4 w-4"
          src="@assets/img/common/ai-image-generation.svg"
          alt="Generate Image"
        />
        <span class="text-[#4a4a4a] text-xs 2xl:text-sm pt-[1px] text-nowrap leading-none">Generate Image</span>
      </button>

      <button
        v-if="showHashtagButton"
        class="flex items-center gap-x-2 px-2 py-1 rounded-lg bg-white hover:!bg-blue-500/10 border-[1.5px] !border-dashed border-blue-500/60"
        @click="handleAIClick('ai-hashtags-generation')"
        >
        <img
          class="h-4 w-4"
          src="@assets/img/common/hashtag-ai.svg"
          alt="Generate Hashtags"
        />
        <span class="text-[#4a4a4a] text-xs 2xl:text-sm pt-[1px] text-nowrap leading-none">Generate Hashtags</span>
      </button>

      <div
      v-if="templateText"
      class="flex items-center gap-x-1">
        <button
        class="flex items-center gap-x-2 px-2 py-1 rounded-lg bg-white hover:!bg-pink-500/10 border-[1.5px] !border-dashed border-pink-500/60"
        @click="handleAIClick('ai-improve')"
      >
        <img
          class="h-4 w-4"
          src="@assets/img/common/improve-ai.svg"
          alt="Improve with AI"
        />
        <span class="text-[#4a4a4a] text-xs 2xl:text-sm pt-[1px] text-nowrap leading-none">Improve <span class="hidden 2xl:inline">with AI</span></span>
      </button>

     <div class="relative">
      <button
      class="flex items-center gap-x-2 p-[2px] 2xl:p-[0.28rem]  rounded-lg bg-white hover:!bg-pink-500/10 border-[1.5px] !border-dashed border-pink-500/60 "
      :class="{ '!bg-pink-500/10': AiActionDropDown }"
      @click="AiActionDropDown = !AiActionDropDown"
      >
      <!-- Arrow down -->
      <svg
        class="w-5 h-5 text-gray-900 transform transition-transform duration-200"
        :class="{ 'rotate-180': AiActionDropDown }"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path d="M6 9l6 6 6-6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>

    <!-- Dropdown menu   -->
    <div
      v-if="AiActionDropDown"
      v-click-away="() => {
 AiActionDropDown = false
}"
      class="absolute top-full mt-1 w-48 rounded-md shadow z-50 2xl:left-0 right-0"
    >
      <div
        class="rounded-md bg-white shadow-xs"
      >
        <div class="p-2" role="none">
          <div
            class="flex items-center justify-between px-4 py-2 text-sm leading-5 text-gray-800 hover:bg-[#f0f7ff] hover:text-gray-900 group cursor-pointer rounded-xl "
            role="menuitem"
            @click="handleAIClick('ai-rephrase')"
          >
          <span class="flex items-center gap-x-4">
            <img src="@src/assets/img/composer/rephrase.svg" alt="rephrase" class="w-4 h-4">
            <span>Rephrase</span>
          </span>

          <i class="fa fa-check invisible group-hover:!visible"></i>
        </div>
          <div
            class="flex items-center justify-between px-4 py-2 text-sm leading-5 text-gray-800 hover:bg-[#f0f7ff] hover:text-gray-900 group cursor-pointer rounded-xl "
            role="menuitem"
            @click="handleAIClick('ai-shorten')"
          >
          <span class="flex items-center gap-x-4">
            <img src="@src/assets/img/composer/shorten.svg" alt="shorten" class="w-4 h-4">
            <span>Shorten</span>
          </span>
            <i class="fa fa-check invisible group-hover:!visible"></i>
          </div>

          <div
            class="flex items-center justify-between px-4 py-2 text-sm leading-5 text-gray-800 hover:bg-[#f0f7ff] hover:text-gray-900 group cursor-pointer rounded-xl "
            role="menuitem"
            @click="handleAIClick('ai-lengthen')"
          >
          <span class="flex items-center gap-x-4">
            <img src="@src/assets/img/composer/lengthen.svg" alt="lengthen" class="w-4 h-4">
            <span>Lengthen</span>
          </span>
          <i class="fa fa-check invisible group-hover:!visible"></i>

          </div>

          <div
            class="flex items-center justify-between px-4 py-2 text-sm leading-5 text-gray-800 hover:bg-[#f0f7ff] hover:text-gray-900 group cursor-pointer rounded-xl "
            role="menuitem"
            @click="handleAIClick('ai-grammar-fix')"
          >
          <span class="flex items-center gap-x-4">
            <img src="@src/assets/img/composer/spelling.svg" alt="spelling" class="w-4 h-4">
            <span>Grammar Fix</span>
          </span>
          <i class="fa fa-check invisible group-hover:!visible"></i>

          </div>

        </div>
      </div>
    </div>
     </div>

      </div>

      <!-- AI Caption from Image  -->
      <div
      v-if="HasImageMedia"
      class="flex items-center gap-x-1">
        <button
        class="flex items-center gap-x-2 px-2 py-1 rounded-lg bg-white hover:!bg-green-500/10 border-[1.5px] !border-dashed border-green-500/60"
        @click="handleImageAction('ai-caption-from-image')"
      >
        <img
          class="h-4 w-4"
          src="@assets/img/common/caption-ai.svg"
          alt="Improve with AI"
        />
        <span class="text-[#4a4a4a] text-xs 2xl:text-sm pt-[1px] text-nowrap leading-none ">Caption Image with AI</span>
      </button>

     <div class="relative">
      <button
      class="flex items-center gap-x-2 p-[2px] 2xl:p-[0.28rem]  rounded-lg bg-white hover:!bg-green-500/10 border-[1.5px] !border-dashed border-green-500/60 "
      :class="{ '!bg-green-500/10': AiCaptionDropDown }"
      @click="AiCaptionDropDown = !AiCaptionDropDown"
      >
      <!-- Arrow down -->
      <svg
        class="w-5 h-5 text-gray-900 transform transition-transform duration-200"
        :class="{ 'rotate-180': AiCaptionDropDown }"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path d="M6 9l6 6 6-6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>

    <!-- Dropdown menu   -->
    <div
      v-if="AiCaptionDropDown"
      v-click-away="() => {
        AiCaptionDropDown = false
}"
      class="absolute top-full mt-1 w-48 rounded-md shadow z-50 2xl:left-0 right-0"
    >
      <div
        class="rounded-md bg-white shadow-xs"
      >
        <div class="p-2" role="none">
          <div
            class="flex items-center justify-between px-4 py-2 text-sm leading-5 text-gray-800 hover:bg-[#f0f7ff] hover:text-gray-900 group cursor-pointer rounded-xl "
            role="menuitem"
            @click="handleImageAction('ai-extract-text-image')"
          >
          <span class="flex items-center gap-x-4">
            <img src="@src/assets/img/composer/extract-text.svg" alt="extract-text" class="w-4 h-4">
            <span>Extract Text</span>
          </span>

          <i class="fa fa-check invisible group-hover:!visible"></i>
        </div>

        <div
        class="flex items-center justify-between px-4 py-2 text-sm leading-5 text-gray-800 hover:bg-[#f0f7ff] hover:text-gray-900 group cursor-pointer rounded-xl "
        role="menuitem"
        @click="handleImageAction('ai-summarize-image')"
      >
      <span class="flex items-center gap-x-4">
        <img src="@src/assets/img/composer/summarize.svg" alt="summarize" class="w-4 h-4">
        <span>Summarize</span>
      </span>

      <i class="fa fa-check invisible group-hover:!visible"></i>
    </div>

    <div
    class="flex items-center justify-between px-4 py-2 text-sm leading-5 text-gray-800 hover:bg-[#f0f7ff] hover:text-gray-900 group cursor-pointer rounded-xl "
    role="menuitem"
    @click="handleImageAction('ai-describe-image')"
  >
  <span class="flex items-center gap-x-4">
    <img src="@src/assets/img/composer/describe.svg" alt="describe" class="w-4 h-4">
    <span>Describe</span>
  </span>

  <i class="fa fa-check invisible group-hover:!visible"></i>
</div>

      </div>
    </div>
     </div>

      </div>
      </div>
    </div>

    <!-- Pinterest Source URL-->
    <div
        class="cst-editor__inner text-base bg-white px-2 pl-3 py-2"
        :class="{
        'hr-color':
          accountsData.accountSelection.pinterest.length &&
          (type === 'common' || type === 'pinterest'),
      }"
    >
      <div
          v-if="
          accountsData.accountSelection.pinterest.length &&
          (type === 'common' || type === 'pinterest')
        "
          class="flex flex-row space-x-2 justify-between items-center"
      >
        <div class="flex items-center">
          <div class="text-sm text-gray-700 font-normal">
            Source URL for Pinterest
          </div>
          <div>
            <CstInputFields
                :value="sharingDetails.source_url"
                class="ml-4 w-80"
                type="text"
                placeholder="e.g. https://www.example.com/xyz"
                @change="onPinterestSourceUrlChange"
            />
          </div>
          <div
              v-if="
              sharingDetails.source_url &&
              !isShortLink(sharingDetails.source_url)
            "
              class="pl-2"
          >
            <button
                class="flex flex-row items-center space-x-2 text-xs text-white border-0 rounded-md px-3 py-2 font-semibold focus:outline-none bg-cs-primary"
                @click="
                shrinkSharingSourceLinkNew(type, sharingDetails.source_url)
              "
            >
              <span>Shrink</span>
              <clip-loader
                  v-if="pinterestUrlLinkLoader"
                  :color="'#ffffff'"
                  :size="'12px'"
              ></clip-loader>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Editor toolbar-->
    <div
        class="cst-editor__toolbar text-base flex justify-between bg-white px-3 py-2"
    >
      <div class="flex items-center">
        <div
            v-tooltip="'Saved Captions'"
            class="cst-editor__toolbar-btn text-[#808080] relative mr-2 cursor-pointer w-7 h-7 flex items-center justify-center bg-cs-secondary rounded-md transition-all ease-in-out"
            @click="openSaveCaptionModal"
        >
          <i class="icon-Save pointer" />
        </div>
        <!-- location dropdown btn is placed here to have same style -->
        <div v-if="toolbar.location" class="location-dropdown relative mr-2">
          <div
              aria-expanded="true"
              aria-haspopup="true"
              @click="showLocationModal"
          >
            <div
                v-tooltip.top="'Add Location'"
                class="cst-editor__toolbar-btn cursor-pointer w-7 h-7 flex items-center justify-center bg-cs-secondary rounded-md transition-all ease-in-out"
            >
              <LocationIcon :color="hasSelectedLocations ? '#2A70FC' : '#75797C'" />
            </div>
          </div>
        </div>
        <!-- Hashtag dropdown -->
        <div class="hashtag-dropdown relative mr-2">
          <div
              aria-expanded="true"
              aria-haspopup="true"
              @click="hashtagToggle = !hashtagToggle"
          >
            <div
                v-tooltip.top="'Add Hashtags'"
                class="cst-editor__toolbar-btn cursor-pointer w-7 h-7 flex items-center justify-center bg-cs-secondary rounded-md transition-all ease-in-out"
            >
              <img
                  src="@assets/img/composer/hashtag-icon.svg"
                  alt=" "
                  class="w-4 h-4"
              />
            </div>
          </div>
          <HashtagDropdown
              v-if="hashtagToggle"
              :is-open="hashtagToggle"
              :type="type"
              :apply-hashtag="applyHashtag"
              :external-hashtags="externalTags"
              @handle-hashtags="handleHashtagsChanges"
              @handle-click="handleClickAwayEvents"
          >
          </HashtagDropdown>
        </div>
        <!-- UTM dropdown -->
        <div class="utm-dropdown relative mr-2">
          <div
              aria-expanded="true"
              aria-haspopup="true"
              @click="utmToggle = !utmToggle"
          >
            <div
                v-tooltip.top="'Add UTM'"
                class="cst-editor__toolbar-btn cursor-pointer w-7 h-7 flex items-center justify-center bg-cs-secondary rounded-md transition-all ease-in-out"
            >
              <img
                  src="@assets/img/composer/utm-icon.svg"
                  alt=" "
                  class="w-5 h-5"
              />
            </div>
          </div>
          <UtmDropdown
              v-if="utmToggle"
              :is-open="utmToggle"
              :detail="sharingDetails"
              :utm="utm"
              @handle-utm="handleUtmChanges"
              @handle-click="handleClickAwayEvents"
          >
          </UtmDropdown>
        </div>
        <!-- Replug dropdown -->
        <div class="replug-dropdown relative mr-2">
          <div
              aria-expanded="true"
              aria-haspopup="true"
              @click="replugToggle = !replugToggle"
          >
            <div
                v-tooltip.top="'Add Replug CTA'"
                class="cst-editor__toolbar-btn cursor-pointer w-7 h-7 flex items-center justify-center bg-cs-secondary rounded-md transition-all ease-in-out"
            >
              <img
                  src="@assets/img/composer/replug-icon.svg"
                  alt=" "
                  class="w-5 h-5"
              />
            </div>
          </div>
          <ReplugDropdown
              v-if="replugToggle"
              :is-open="replugToggle"
              :detail="sharingDetails"
              :loader="replugLoader"
              @handle-replug="handleReplugChanges"
              @handle-click="handleClickAwayEvents"
          >
          </ReplugDropdown>
        </div>

        <!-- Formatting option -->
        <div
            v-tooltip="'Applies 𝗕𝗼𝗟𝗱 using special Unicode characters, which may affect font appearance.'"
            unselectable="on"
            class="cst-editor__toolbar-btn cursor-pointer w-7 h-7  mr-2 flex items-center justify-center bg-cs-secondary rounded-md hover-trigger relative transition-all ease-in-out"
            @click="handleUnicodeVariant('bs')"
        >
          <i class="fas fa-bold w-4 h-4 text-cs-smoke text-center"></i>
        </div>

        <div
            v-tooltip="'Applies 𝙄𝙩𝙖𝙡𝙞𝙘𝙨 using special Unicode characters, which may affect font appearance.'"
            unselectable="on"
            class="cst-editor__toolbar-btn cursor-pointer w-7 h-7 mr-2  flex items-center justify-center bg-cs-secondary rounded-md hover-trigger relative transition-all ease-in-out"
            @click="handleUnicodeVariant('is')"
        >
          <i class="fas fa-italic w-4 h-4 text-cs-smoke text-center"></i>
        </div>
        <!-- Emoji dropdown -->
        <div v-if="toolbar.emoji" class="relative">
          <div
              aria-expanded="true"
              aria-haspopup="true"
              @click="emojiToggle = !emojiToggle"
          >
            <div
                v-tooltip.top="'Add an emoji'"
                class="cst-editor__toolbar-btn cursor-pointer w-7 h-7 flex items-center justify-center bg-cs-secondary rounded-md transition-all ease-in-out"
            >
              <img
                  src="@assets/img/composer/emoji-icon.svg"
                  alt=" "
                  class="w-4 h-4"
              />
            </div>
          </div>
          <CstEmojiPicker
              v-if="emojiToggle"
              class="right-12"
              position="top-start"
              :is-open="emojiToggle"
              @on-select="onEmojiSelect"
              @handle-click="handleClickAwayEvents"
          >
          </CstEmojiPicker>
        </div>

              <!-- Split Tweet Content -->
              <transition
              enter-active-class="transition-opacity ease-linear duration-300"
              enter-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="transition-opacity ease-linear duration-300"
              leave-class="opacity-100"
              leave-to-class="opacity-0"
            >
            <div
                v-if="toolbar.splitContent && getCharacterCount > maxLimit"
                v-tooltip="
                'Automatically split long content into multiple threaded tweets'
              "
                class="ml-2 cursor-pointer px-1.5 3xl:px-2.5 py-1.5 3xl:py-1 flex items-center justify-center bg-cs-secondary hover:bg-opacity-75 rounded-md relative"
                @click="$emit('split-tweet-content')"
            >
              <img
                  src="@assets/img/integration/twitter-x-rounded.svg"
                  alt=""
                  class="w-4"
              />
              <span
                  class="ml-2 text-sm font-normal text-black-200 hidden 3xl:block"
              >Auto-Thread</span
              >
              </div>
            </transition>


            <!-- Facebook Template Box -->
            <FacebookBackgroundBox
            v-if="type === 'common' || type === 'facebook'"
            :facebook-background-id="facebookBackgroundId"
            :is-allowed-facebook-backgrounds="isAllowedFacebookBackgrounds"
            :facebook-post-type="facebookPostType"
            :facebook-group-selected="facebookGroupSelected"
            :facebook-page-selected="facebookPageSelected"
            :character-count="getCharacterCount"
            @set-facebook-background-id="$emit('set-facebook-background-id', $event)"
            />
            <!-- End Facebook Template Box -->


        <!-- Character limit -->
        <div
            class=" ml-3 pl-3 flex gap-3 items-center"
            style="border-left: 1px solid #eef1f4"
        >
          <v-menu
            :popper-triggers="['hover']"
            placement="top"
            popper-class="first-comment__info-popover"
            :delay="300"
            class="relative select-none cursor-pointer mr-2"
          >
            <img
              src="@assets/img/composer/x-premium-icon.svg"
              alt="X Premium"
              class="select-none"
              :class="`${isTwitterPremiumSelected ? 'opacity-100' : 'opacity-50'}`"
            />
            <template v-slot:popper>
              <b class="text-sm">X (Twitter) Long-Form Content</b>
              <br/>
              <span class="text-sm font-normal mt-2">
                X (Twitter) Premium users can publish long-form posts with up to 25,000 characters.
                <a
                  href="https://docs.contentstudio.io/article/1052-x-twitter-long-post"
                  target="_blank" rel="noopener"
                  class="text-cs-primary">
                  Learn more
                </a>
              </span>
            </template>
          </v-menu>
          <div
            v-if="maxLimit"
            v-tooltip.bottom-end="
                  maxLimit >= getCharacterCount
                    ? `Remaining Characters: ${maxLimit - getCharacterCount}`
                    : `Extra Characters: ${getCharacterCount - maxLimit}`"
            class="inline-flex gap-[10px] py-[3px] px-[10px] justify-center items-center rounded-full cursor-pointer min-w-[57px]"
            :class="getCharacterCount > maxLimit ? 'bg-red-400 bg-opacity-10' : 'bg-[#3A74D3] bg-opacity-10'"
          >
            <span
              class="text-center text-sm leading-5"
              :class="getCharacterCount > maxLimit ? 'text-red-400 ' : 'text-[#3A74D3]'"
            >
              {{ maxLimit - getCharacterCount }}
            </span>
          </div>
        </div>
      </div>

      <!-- Customize -->
    <div class="flex justify-between items-center">
        <span
            v-tooltip="'Customize your content for each platform.'"
            class="font-normal text-sm text-gray-900 mr-1 xl:mr-2"
            >Customize</span>
        <div id="separate-box-switch">
          <CstSwitch
              v-model="separateBoxes"
              :disabled="isEmptyAccountSelection"
              size="small"
              @input="handleCustomBoxToggle"
          />
        </div>
    </div>

    </div>

    <!-- Link Preview Box -->
    <transition name="slidedown">
      <template v-if="canHaveLinkPreview()">
        <div
            v-if="(linkPreviewLoader || linkShortenerLoader) && !isMediaEnabled"
            class="p-3"
        >
          <div class="flex w-full">
            <SkeletonBox width="12rem" height="9rem" radius="12px" />

            <div class="p-3 w-full">
              <div class="mb-2">
                <SkeletonBox height="1.2rem" radius=".2rem" />
              </div>
              <div class="mb-1">
                <SkeletonBox radius=".2rem" />
              </div>
              <div class="mb-1">
                <SkeletonBox radius=".2rem" />
              </div>
              <SkeletonBox radius=".2rem" />
            </div>
          </div>
        </div>
        <div v-else-if="isLinkPreviewEnabled">
          <EditorLinkPreview
              :title="sharingDetails.title ? sharingDetails.title : ''"
              :url="sharingDetails.website ? sharingDetails.website : ''"
              :description="
              sharingDetails.description ? sharingDetails.description : ''
            "
              :image="
              sharingDetails.image[0]
                ? sharingDetails.image[0]
                : sharingDetails.image
                ? sharingDetails.image
                : ''
            "
              @on-remove="() => removeLinkPreview(true, true)"
          />
        </div>
      </template>
    </transition>

    <!-- Media Type should be either image | video -->
    <transition name="slidedown">
      <template
          v-if="
          (!isLinkPreviewEnabled &&
            !isLoadersEnabled &&
            (isMediaEnabled || getSuggestedImages.length)) ||
          mediaUploadLoader ||
          isUploadingLinkImage ||
          getSharingImageUploadLoader(type).status ||
          uploadingAiImages
        "
      >
        <EditorMediaBox
            :type="getMediaType"
            :media="getMedia"
            :account-type="type"
            :accounts-data="accountsData"
            :media-loader="mediaUploadLoader || getSharingImageUploadLoader(type).status"
            :progress-bar="progressBar"
            :has-custom-thumbnail="hasCustomThumbnail"
            :suggested-images="getSuggestedImages"
            :sharing-details="sharingDetails"
            :linkedin-options="linkedinOptions"
            :update-image-loader="updateImageLoader"
            :insta-posting-method="instaPostingMethod"
            :is-uploading-link-image="isUploadingLinkImage"
            :instagram-post-type="instagramPostType"
            :facebook-post-type="facebookPostType"
            :youtube-post-type="youtubePostType"
            :uploading-ai-images="uploadingAiImages"
            :instagram-share-to-story="instagramShareToStory"
            :selected-tabs="selectedTabs"
            :active-tabs="activeTab"
            @on-sort="sortMedia"
            @on-edit="onEdit"
            @set-alt="setAlt"
            @remove="removeMediaAsset"
            @add-suggested-image="uploadImageFromLink"
            @profile-tagging="profileTagging"
        />
      </template>
    </transition>

    <!-- AI Hashtag Suggestions -->
    <EditorAIHashtags
        ref="editor_AI_hashtags"
        @add-hashtag="addSocialHashtag"
        @save-hashtag="loadHashtagDropdown"
        @re-generate="handleFetchAIHashtags(true)"
        @close="handleCloseHashtag"
    >
    </EditorAIHashtags>
    <!--Composer - Video title modal-->
    <b-modal
        id="video-title-modal-v2"
        ref="video-title-modal-v2"
        v-model="videoTitleModal"
        centered
        hide-header
        no-close-on-backdrop
        size="lg"
    >
      <header class="modal-header media-library-modal-header">
        <h3 class="font-weight-bold my-auto text-capitalize">
          Add title for video file
          <!--<a class="beacon ml-2" v-tooltip.top="'Learn more about Alt-Texts'"
               href="#">
                <i class="far fa-question-circle"
                ></i>
            </a>-->
        </h3>
        <button
            aria-label="Close"
            class="close"
            type="button"
            @click="closeVideoTitleModal"
        >×
        </button>
      </header>

      <b-container class="pt-4 px-4 pb-2" fluid>
        <b-row>
          <b-col class="my-auto" sm="3">
            <b-img
                :src="computeVideoThumbnail"
                alt="Image 1"
                center
                height="150"
                width="150"
            ></b-img>
          </b-col>
          <b-col sm="9">
            <b-textarea
                id="textarea"
                v-model="videoTitle"
                :state="textAreaValidation"
                max-rows="6"
                no-resize
                placeholder="Video Title..."
                rows="6"
            ></b-textarea>
          </b-col>
          <b-col class="d-flex justify-content-end" offset-sm="10" sm="2">
            <span>{{ videoTitleCharCount }}/{{ videoTitleCharLimit }}</span>
          </b-col>
        </b-row>
      </b-container>

      <template v-slot:modal-footer>
        <div class="px-3">
          <p
          >Available for:
            <span style="color: #3b5998">
              <i class="fab fa-facebook-square fa-lg mx-1" size="24"> </i>
            </span>
            <span style="color: #1da1f2">
              <i class="fab fa-linkedin fa-lg mx-1" size="24"></i>
            </span>
          </p>
        </div>
        <b-button
            :disabled="videoTitleSaveButtonStatus || videoTitleCharCount === 0"
            size="lg"
            variant="primary"
            @click="onClickSaveVideoTitle($event)"
        >
          Save
        </b-button>
        <!-- Emulate built in modal footer ok and cancel button actions -->
      </template>
    </b-modal>
  </div>

  <MediaSelection :type="activeTab" @media-action="handleMediaAction" @handle-articles="handleDrop" />
</template>

<script>
import MediaSelection from "@modules/composer_v2/components/MediaSelection.vue";
import CstTextArea from '@ui/TextArea/CstTextArea'
import EditorMediaBox from '@src/modules/composer_v2/components/EditorBox/EditorMediaBox'
import EditorLinkPreview from '@src/modules/composer_v2/components/EditorBox/EditorLinkPreview'
import debounce from 'lodash/debounce'
import UtmDropdown from '@src/modules/composer_v2/components/EditorBox/EditorOptions/UtmDropdown'
import EditorAIHashtags from '@src/modules/composer_v2/components/EditorBox/EditorAIHashtags.vue'
import isEmpty from 'is-empty'
import HashtagDropdown from '@src/modules/composer_v2/components/EditorBox/EditorOptions/HashtagDropdown'
import ReplugDropdown from '@src/modules/composer_v2/components/EditorBox/EditorOptions/ReplugDropdown'
import ComposerHelper from '@src/modules/composer_v2/mixins/ComposerHelper'
import FacebookBackgroundBox from '@modules/composer_v2/components/EditorBox/FacebookBackground/FacebookBackgroundBox.vue'
import {
  getHashtagsFromText,
  getLinksFromText,
  memoizeCallback,
  parseFilesMimeTypes,
} from '@common/lib/helper'
import { commonTypes } from '@src/modules/publish/store/states/mutation-types'
import { mapGetters } from 'vuex'
import {
  fetchAIHashTags,
  fetchShortLinksUrl,
} from '@src/modules/publish/config/api-utils'
import { EventBus } from '@common/lib/event-bus'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import editorInitialValue from '@src/modules/composer_v2/components/EditorBox/editorInitialValue'
import CstInputFields from '@ui/Input/CstInputFields'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { MediaHelperMixin } from '@src/modules/publish/components/media-library/utils/MediaHelpers'
import proxy from '@common/lib/http-common'
import {inject, toRaw} from 'vue'
import {defaultLinkedinOptions} from "@src/modules/composer_v2/views/composerInitialState";
import usePermissions from "@common/composables/usePermissions";
import useWhiteLabelApplication from "@modules/setting/composables/whitelabel/useWhiteLabelApplication";
import LocationIcon from "@src/modules/composer_v2/components/EditorBox/LocationIcon.vue";
import CstSwitch from "@ui/Switch/CstSwitch.vue";
const clone = require('rfdc/default')
const memoizedGetLinksFromText = memoizeCallback(getLinksFromText, true)
export default {
  name: 'EditorBox',
  components: {
    CstSwitch,
    FacebookBackgroundBox,
    EditorAIHashtags,
    HashtagDropdown,
    UtmDropdown,
    ReplugDropdown,
    CstTextArea,
    CstEmojiPicker: () => import('@ui/EmojiPicker/CstEmojiPicker'),
    EditorMediaBox,
    EditorLinkPreview,
    SkeletonBox,
    CstInputFields,
    LocationIcon,
    MediaSelection
  },
  mixins: [ComposerHelper, MediaHelperMixin],
  props: {
    planId: {
      type: String,
      default: '',
    },
    accountSelection: {
      type: Object,
      default: () => {},
    },
    tweetIndex: {
      type: Number,
      default: 0,
    },
    username: {
      type: String,
      default: '',
      required: true,
    },
    toolbar: {
      type: Object,
      default: () => editorInitialValue.toolbar,
    },
    maxLimit: {
      type: Number,
      default: 5000,
    },
    twitterTextEnable: {
      type: Boolean,
      default: false,
    },
    sharingDetails: {
      type: Object,
      default: () => {},
    },
    linkedinOptions:{
      type: Object,
      default: clone(defaultLinkedinOptions),
    },
    accountsData: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'common',
      required: true,
    },
    utm: {
      type: Object,
      default: () => {},
    },
    replugLoader: {
      type: Boolean,
      default: false,
    },
    instaPostingMethod: {
      type: String,
      default: '',
    },
    instagramPostType: {
      type: String,
      default: 'feed',
    },
    instagramShareToStory: {
      type: Boolean,
      default: false
    },
    facebookPostType: {
      type: String,
      default: 'feed',
    },
    facebookBackgroundId: {
      type: String,
      default: '',
    },
    youtubePostType: {
      type: String,
      default: 'video',
    },
    applyHashtag: {
      type: Function,
      default: () => {},
    },
    socialPostType: {
      type: String,
      default: 'new',
    },
    uploadingAiImages: {
      type: Boolean,
      default: false,
    },
    selectedTabs: {
      type: String,
      default: '',
    },
    activeTab: {
      type: String,
      default: '',
    },
    commonBoxStatus: {
      type: Boolean,
      default: true,
    },
    facebookGroupSelected: {
      type: Boolean,
      default: false
    },
    facebookPageSelected: {
      type: Boolean,
      default: false
    },
    isEmptyAccountSelection: {
      type: Boolean,
      default: false
    },
    isTwitterPremiumSelected:{
      type: Boolean,
      default: false
    },
    hasSelectedLocations:{
      type: Boolean,
      default: false
    }
  },
  emits: ['add-document', 'removeMedia', 'set-facebook-background-id', 'toggle-custom-content', 'show-location-modal','isSeparateBoxes', 'split-tweet-content'],
  setup(){
    const { $bvModal } = inject('root')
    const {hideVista} = usePermissions()
    const { isWhiteLabelDomain } = useWhiteLabelApplication()
    return {
      $bvModal,
      hideVista,
      isWhiteLabelDomain,
    }
  },
  data() {
    return {
      debounceTextUpdate: () => null,
      commonPlaceHolder:
          'Add your content here e.g. caption, images, videos, URL, mention, emoji etc.',
      singlePlaceHolder: 'Add your content here ...',
      templateText: '',
      characterCount: 0,
      hashtags: [],
      externalTags: [],
      mediaType: 'image',
      linkPreviewLoader: false,
      linkShortenerLoader: false,
      cancelledLink: '',
      progressBar: {
        image: 0,
        numberOfImages: 0,
        video: 0,
      },
      mediaUploadLoader: false,
      updateImageLoader: { status: false, position: -1 },
      isUploadingLinkImage: false,
      usedSuggestedImages: [],
      mentionsLoader: false,
      links: {},
      pinterestUrlLinkLoader: false,
      videoTitleModal: false,
      videoTitle: '',
      videoTitleCharLimit: 100,
      locationToggle: false,
      utmToggle: false,
      replugToggle: false,
      hashtagToggle: false,
      emojiToggle: false,
      crelloToggle: false,
      canvaToggle: false,
      savedLinks: [],
      index: 0,
      separateBoxes: false,
      AiActionDropDown: false,
      AiCaptionDropDown: false,
      dragEntered: false,
    }
  },
  computed: {
    ...mapGetters([
      'getTwitterVariationsIndex',
      'getTwitterVariations',
      'getPublishLoaders',
    ]),
    /**
     * Returns the image or video thumbnail based on the type
     * @returns {*|string}
     */
    computeVideoThumbnail() {
      if(this.isTypeCommonOrInstagram(this.type)){
        return this.sharingDetails?.multimedia?.length > 0 ? this.sharingDetails?.multimedia[this.index]?.thumbnail : ''
      }
      return this.sharingDetails?.video?.thumbnail || ''
    },
    /**
     * Calculates the length of the accounts selected
     * @returns {number}
     */
    selectedAccountsLength() {
      let length = 0
      for (const channel in this.accountSelection) {
        length += this.accountSelection[channel].length
      }
      return length
    },
    getMedia() {
      if(this.isLinkedinDocumentAdded()){
        return [this.linkedinOptions.document]
      }

      if(this.isTypeCommonOrInstagram(this.type) && this.sharingDetails?.multimedia?.length > 0){
        return this.sharingDetails?.multimedia || []
      }

      if (this.sharingDetails.video && this.sharingDetails.video.link)
        return [this.sharingDetails.video]
      else return [].concat(this.sharingDetails.image)
    },
    HasImageMedia() {
      return (
          (this.sharingDetails?.image?.length > 0) ||
          (this.sharingDetails?.multimedia?.filter(media => typeof media === 'string')?.length > 0)
      )
    },

    getMediaType() {
      console.debug('getMediaType', this.progressBar)
      if(this.isLinkedinDocumentAdded())
        return 'document'
      if(this.isTypeCommonOrInstagram(this.type) && this.sharingDetails?.multimedia?.length > 0) {
        return 'multimedia'
      }
      if (
          (this.sharingDetails.video && this.sharingDetails.video.link) ||
          (this.mediaUploadLoader && this.progressBar.video)
      ) {
        return 'video'
      }
      return 'image'
    },
    percentage() {
      return Math.round((100 / this.maxLimit) * this.getCharacterCount)
    },

    isLinkPreviewEnabled() {
      // link preview is not enabled for instagram, pinterest, tumblr, gmb, youtube, tiktok (only show link preview image)
      if (
          this.type === 'instagram' ||
          this.type === 'pinterest' ||
          this.type === 'tumblr' ||
          this.type === 'gmb' ||
          this.type === 'youtube' ||
          this.type === 'tiktok'
      ) {
        return false
      }
      if (this.sharingDetails.url && this.sharingDetails.url !== '') {
        if (this.sharingDetails.image && this.sharingDetails.image.length)
          return true
        if (this.sharingDetails.title) return true
        if (this.sharingDetails.website) return true
        return !!this.sharingDetails.description
      }
      return false
    },
    isLoadersEnabled() {
      if (this.getMedia && this.getMedia.length > 0) return false
      return this.linkPreviewLoader || this.linkShortenerLoader
    },
    isMediaEnabled() {
      return (
          (this.sharingDetails?.image?.length > 0) ||
          (this.sharingDetails?.video?.link !== '') ||
          (this.sharingDetails?.multimedia?.length > 0) ||
          this.isLinkedinDocumentAdded()
      )
    },

    hasCustomThumbnail() {
      return (
          this.type === 'common' ||
          this.type === 'facebook' ||
          this.type === 'youtube' ||
          this.type === 'instagram' ||
          this.type === 'linkedin' ||
          this.type === 'pinterest' ||
          this.type === 'tiktok'
      )
    },

    linksFromText() {
      if (this.sharingDetails.message) {
        const links = memoizedGetLinksFromText(this.sharingDetails.message)
        console.log(' linksFromTextlinks::', links)
        return links ? links[0] : ''
      }
      return ''
    },
    isLocationAccount() {
      return {
        facebook: this.accountsData.accountSelection.facebook.length > 0,
        instagram: this.accountsData.accountSelection.instagram.length > 0,
        twitter: this.accountsData.accountSelection.twitter.length > 0,
      }
    },
    textAreaValidation() {
      let resp = null
      resp = this.videoTitle.length <= 100 ? null : false
      return resp
    },
    videoTitleCharCount() {
      return this.videoTitle.length
    },

    videoTitleSaveButtonStatus() {
      return this.videoTitleCharCount > this.videoTitleCharLimit
    },

    getSuggestedImages() {
      const images = []
      if (
          this.sharingDetails.image_suggestions &&
          this.sharingDetails.image_suggestions.length
      ) {
        this.sharingDetails.image_suggestions.forEach((image) => {
          if (this.usedSuggestedImages.includes(image) === false) {
            images.push(image)
          }
        })
      }
      return images
    },
    getCharacterCount() {
      if (
          this.twitterTextEnable &&
          this.sharingDetails.url &&
          !this.sharingDetails.message.includes(this.sharingDetails.url)
      ) {
        return this.characterCount + 24
      }
      return this.characterCount
    },
    /**
     * check if facebook background option allowed
     * @returns {boolean}
     */
    isAllowedFacebookBackgrounds() {
      return ['common', 'facebook'].includes(this.type) && this.accountSelection.facebook.length && !this.sharingDetails?.image?.length && !this.sharingDetails?.video?.link
    },

    showHashtagButton() {
     return this.getCharacterCount >= 1
    },
  },

  watch: {
    maxLimit: function (val) {
      if (this.type === 'twitter' || this.type === 'common') {
        const editor = toRaw(this.$refs.editorElement.editor)
        editor.setMaxCharacterLimit(val)
      }
    },
    twitterTextEnable: function (val) {
      if (this.type === 'common') {
        const editor = toRaw(this.$refs.editorElement.editor)
        editor.setTwitterTextStatus(val)
      }
    },
    'sharingDetails.message': function (val) {
      if (this.sharingDetails.message !== this.templateText) {
        console.debug('Update Editor Content', this.type)
        this.templateText = this.sharingDetails.message
        const editor = toRaw(this.$refs.editorElement.editor)
        editor.setContent(this.sharingDetails.message)
        // this.$refs.editorElement.editor.setContent(this.sharingDetails.message)
      }
    },
    getCharacterCount: function (val) {
      EventBus.$emit('editor-character-count', {editorType: this.type, characterCount: val})
    },
    commonBoxStatus: function (val) {
      this.separateBoxes = !val
    },
  },
  created() {
    const self = this
    // hack: fix for the issue related to the editor boxes content update - https://stackoverflow.com/a/60030038/15265413
    this.debounceTextUpdate = debounce(function (count) {
      self.$emit('onEditorBoxTextChange', self.type, self.templateText, this.getCharacterCount)
    }, 500)
  },

  mounted() {
    console.debug('EditorBox mounted', this.type)
    this.savedLinks = this.sharingDetails.saved_urls
        ? this.sharingDetails.saved_urls
        : []

    this.separateBoxes = !this.commonBoxStatus
    setTimeout(() => {
      const activeTab = this.commonBoxStatus ? 'common' : this.activeTab
      if(this.type !== activeTab) return
      EventBus.$emit('editor-character-count', { editorType: this.type, characterCount: this.characterCount });
    }, 500)

    EventBus.$on(`generate-hashtags-${this.type}`, (shouldRegenerate) => {
      this.handleFetchAIHashtags(shouldRegenerate)
    })
  },

  beforeUnmount() {
    console.debug('editor before destroy', this.type)
    EventBus.$off(`generate-hashtags-${this.type}`)
    this.$off([
      'toggleFirstComment',
      'toggleThreadedTweets',
      'toggleCarouselPost',
      'onEditorBoxTextChange',
      'removeLinkPreview',
      'handleSharingLink',
      'setLinkPreview',
      'initializeVideoUpload',
      'addMedia',
      'removeMedia',
      'add-location',
      'handle-utm',
      'handle-replug',
      'handle-hashtags',
      'handle-change',
      'add-single-image',
    ])
  },

  methods: {
    handleMediaAction(action) {
      switch (action.type) {
        case 'upload':
          this.handleFileUpload(action.mediaType, action.files);
          break;
        case 'openMediaLibrary':
          this.openMediaLibrary();
          break;
        case 'openCanva':
          this.createCanvaDesign(this.type, action.designType, this.sharingDetails);
          break;
        case 'openVista':
          this.createCrelloDesign(this.type, action.designType, this.sharingDetails);
          break;
        case 'openPostnitro':
          this.designWithPostnitro();
          break;
      }
    },

    handleCloseHashtag() {
      // Hide EditorAIHashtags component
      const hashtags = this.$refs.editor_AI_hashtags
      hashtags?.setLoader(false)
      hashtags?.setHashtags([])
    },

    handleFileUpload(mediaType, files) {
      switch (mediaType) {
        case 'image':
          this.composerUploadImage({ target: { files } });
          break;
        case 'video':
          this.composerUploadVideo({ target: { files } });
          break;
        case 'pdf':
          this.composerUploadPdf({ target: { files } });
          break;
      }
    },

    hanldeKeyboardOptions(key) {
      if (key === 'b') {
        this.handleUnicodeVariant('bs');
      }

      if(key === 'i') {
        this.handleUnicodeVariant('is');
      }
    },

    /**
     * Open Postnitro modal
     */
    designWithPostnitro() {
      this.openPostNitroModal(
        this.type,
        this.getSharingDetail,
        this.cancelledLink,
        this.handleCallback,
        this.uploadImageConfirmation
      )
    },

    isLinkedinDocumentAdded(){
      return (this.linkedinOptions?.document_added && (this.type === 'linkedin'|| this.type === 'common'))
    },

    profileTagging(link) {
      this.$emit('profile-tagging', link, this.type)
    },
    handleClickAwayEvents(toggle, type) {
      console.log('handleClickAwayEvents', toggle, type)
      if (toggle) {
        switch (type) {
          case 'location':
            this.locationToggle = false
            break
          case 'utm':
            this.utmToggle = false
            break
          case 'replug':
            this.replugToggle = false
            break
          case 'hashtag':
            this.hashtagToggle = false
            this.externalTags = []
            break
          case 'emoji':
            this.emojiToggle = false
            break
          case 'canva':
            this.canvaToggle = false
            break
          case 'crello':
            this.crelloToggle = false
            break
        }
      }
    },
    async handleDrop(event, view = null) {
      let data = ''
      const editor = toRaw(this.$refs.editorElement.editor)
      data = event.dataTransfer.getData('myData')

      if (data) {
        data = JSON.parse(data)
        // to remove previous link preview if any
        await this.$emit(
            'removeLinkPreview',
            this.type,
            data.type !== 'image',
            true
        )
        let msg = this.templateText
        switch (data.type) {
          case 'article':
            msg = data.heading + '\n' + data.link + ' '
            this.templateText = ''
            editor.setContent(msg)
            break
          case 'embed':
            msg = data.heading + '\n' + data.media + ' '
            this.templateText = ''
            editor.setContent(msg)
            break
          case 'image':
            this.uploadImageFromLink(data.media)
            break
        }
        this.dragEntered = false
      } else {
        this.dragEntered = false
        this.processMediaPasteDropEvent(event)
      }
    },
    handleDragEnter(event, view = null) {
      event.preventDefault()
      this.dragEntered = true
    },
    handleDragLeave(event, view = null) {
      this.dragEntered = false
    },
      /**
     * upload Image From Link
     * @param image
     * @param isSuggested is link preview suggested image (default false)
     * @param index
     */
     async uploadImageFromLink(image, isSuggested = false) {
      if (this.isUploadingLinkImage) return
      try {
        this.isUploadingLinkImage = true
        const filters = {
          link: [image],
          folder_id: null,
        }
        const self = this
        await this.uploadLinksFilesHelper(
            filters,
            (status, message, mediaContainer) => {
              if (
                  status &&
                  mediaContainer &&
                  mediaContainer[0] &&
                  mediaContainer[0].link
              ) {
                self.$store.dispatch('updateSocialSharingMedia', mediaContainer)
                self.$emit('add-single-image', this.type, mediaContainer[0].link)
                if (isSuggested) self.usedSuggestedImages.push(image)
              }
              self.isUploadingLinkImage = false
            }
        )
      } catch (exception) {
        console.error('Exception occur while adding suggested image', exception)
        this.alertMessage(UNKNOWN_ERROR, 'error')
        this.isUploadingLinkImage = false
      }
    },
    closeOther() {
      this.locationToggle = false
      this.utmToggle = false
      this.replugToggle = false
      this.hashtagToggle = false
      this.emojiToggle = false
      this.crelloToggle = false
    },
    addSuggestedImage(image) {
      this.$emit('add-single-image', this.type, image)
    },

    canHaveLinkPreview() {
      return !(this.type === 'youtube' || this.type === 'tiktok')
    },

    getEditorKey() {
      return `editor_${this.type}`
    },
    getImageUploadInputId() {
      return `image-upload-input-${this.type}`
    },
    getVideoUploadInputId() {
      return `video-upload-input-${this.type}`
    },
    getPdfUploadInputId() {
      return `pdf-upload-${this.type}`
    },
    getImageUpdateId() {
      return `image-update-${this.type}`
    },

    getSharingDetail() {
      return this.sharingDetails
    },

    sortMedia(media, type) {
      if (['multimedia', 'image'].includes(type)) {
        this.$emit('handle-sort-media', this.type, type, media)
      }
    },

    async triggerComposerImageUpload() {
      if(await this.uploadImageConfirmation() === false) return false
      document.getElementById(this.getImageUploadInputId()).click()
    },

    async uploadImageConfirmation() {

      // For type common or instagram we are allowing multiple media type in case of instagram carosuel post
      if(this.isTypeCommonOrInstagram(this.type)) {
        return true
      }

      // checking video already uploaded or not
      if (this.sharingDetails?.video?.link || this.isLinkedinDocumentAdded()) {
        // showing modal to confirm the video replacing
        if (!(await this.confirmCurrentTypeReplace(this.type))) return false
        this.$emit('removeMedia', this.type, 'video', null, true)
        this.$emit('removeMedia', this.type, 'document', null, false)
      }
      return true
    },

    async triggerComposerVideoUpload() {
      if (await this.uploadVideoConfirmation() === false) return false
      document.getElementById(this.getVideoUploadInputId()).click()
    },

    async uploadVideoConfirmation() {

      // For type common or instagram we are allowing multiple media type in case of instagram carosuel post
      if(this.isTypeCommonOrInstagram(this.type)) {
        return true
      }

      // checking file warning, different file is already added. Like Image or Gif,
      if ((this.sharingDetails?.url === '' && this.sharingDetails?.image?.length > 0) || this.isLinkedinDocumentAdded()) {
        // if image already added then we will show the confirmation dialog box to user.
        if (!(await this.confirmCurrentTypeReplace(this.type))) return false
        this.$emit('removeMedia', this.type, 'image', null, true)
        this.$emit('removeMedia', this.type, 'document', null, false)
      }
      return true
    },

    async triggerComposerPdfUpload(){
      if (await this.pdfUploadConfirmation() === false) return false
      document.getElementById(this.getPdfUploadInputId()).click()
    },

    async pdfUploadConfirmation() {
      if (this.sharingDetails?.video?.link || this.sharingDetails?.image?.length > 0) {
        // if image already added then we will show the confirmation dialog box to user.
        if (!(await this.confirmCurrentTypeReplace(this.type))) return false
        this.$emit('removeMedia', this.type, 'image', null, true)
        this.$emit('removeMedia', this.type, 'video', null, false)
      }
      return true
    },

    async openMediaLibrary() {
      if(this.isLinkedinDocumentAdded()){
        if (!(await this.confirmCurrentTypeReplace(this.type))) return false
      }
      console.debug('openMediaLibrary-v2', this.type)
      EventBus.$emit('show-media-library-modal', {
        source: this.type,
        details: {
          image:
              !this.sharingDetails.url || this.sharingDetails.url === ''
                  ? this.sharingDetails.image
                  : [],
          video: this.sharingDetails.video,
          threadedTweetIndex: this.tweetIndex,
        },
        sideTabIndex: 1,
      })
    },

    /**
     * open saved caption modal
     */
    openSaveCaptionModal() {
      EventBus.$emit('save-caption-modal', {
        caption: this.sharingDetails.message,
        isOldComposer: false,
        source: this.type,
      })
    },
    handleUnicodeVariant(variant) {
      const editor = toRaw(this.$refs.editorElement.editor)
      editor.replaceSelected((selectedText) => {
        return this.toUnicodeVariant(selectedText, variant)
      })

      editor.view.focus()
    },
    toggleFirstComment: function () {
      this.$emit('toggleFirstComment')
    },
    toggleThreadedTweets: function () {
      this.$emit('toggleThreadedTweets')
    },
    toggleCarouselPost() {
      this.$emit('toggleCarouselPost')
    },

    getTemplateText() {
      return this.templateText
    },
    handlePaste(event, view, data) {
      if (
          (event.clipboardData || event.originalEvent.clipboardData).items.length
      ) {
        this.processMediaPasteDropEvent(event)
      }
    },
    async processMediaPasteDropEvent(event) {
      console.log('METHOD::processPasteDropEvent ~ event -> ', event)
      event.preventDefault()
      event.stopPropagation()

      const types = parseFilesMimeTypes(event, true)
      // checking for the the files other than image or video
      if (types.others) {
        this.$store.dispatch('toastNotification', {
          message: 'Your are allowed to upload only image or video file.',
          type: 'error',
        })
        return false
      }
      // checking for the the multiple gifs
      if (types.gifs > 1) {
        this.$store.dispatch('toastNotification', {
          message: 'Your are allowed to upload only 1 GIF image.',
          type: 'error',
        })
        return false
      }
      // checking for the multiple videos
      if (types.videos > 1) {
        this.$store.dispatch('toastNotification', {
          message: 'Your are allowed to upload only 1 video.',
          type: 'error',
        })
        return false
      }
      // checking for the multiple pdfs
      if (types.pdf > 1) {
        this.$store.dispatch('toastNotification', {
          message: 'Your are allowed to upload only 1 PDF.',
          type: 'error',
        })
        return false
      }

      // checking for the multiple files types
      if (
          (types.gifs && (types.images || types.videos)) ||
          (types.images && types.videos) ||
          (types.pdf && (types.images || types.videos || types.gifs))
      ) {
        this.$store.dispatch('toastNotification', {
          message: 'Please upload one file type at a time.',
          type: 'error',
        })
        return false
      }
      // calling method for uploading image
      if (types.gifs || types.images) {
        // if(await this.uploadImageConfirmation() === false) return false
        await this.CSUploadImage(
            event,
            this.type,
            this.getSharingDetail,
            this.cancelledLink,
            this.handleCallback,
            this.uploadImageConfirmation
        )
      }

      // calling method for uploading video
      if (types.videos) {
        this.CSUploadVideo(
            event,
            this.type,
            this.getSharingDetail,
            this.handleCallback,
            this.uploadVideoConfirmation
        )
      }

      // calling method for uploading pdf
      if (types.pdf) {
        if(!(this.type === 'linkedin'|| this.type === 'common')) {
          this.$store.dispatch('toastNotification', {
            message: 'Your are allowed to upload PDF only on LinkedIn.',
            type: 'error',
          })
          return false
        }
        this.CSUploadPdf(
            event,
            this.type,
            this.handleCallback,
            this.pdfUploadConfirmation
        )
      }
    },
    handleChange: function ({
                              templateText,
                              text,
                              links,
                              count,
                              lastChar,
                              hashtags,
                            }) {
      console.log('templateText:: ', this.templateText)
      this.characterCount = count
      this.hashtags = hashtags
      if (
          lastChar === ' ' ||
          (this.templateText === '' && this.socialPostType !== 'edit')
      ) {
        this.templateText = templateText
        this.sharingDetails.message = templateText
        this.$emit('onEditorBoxTextChange', this.type, templateText, this.getCharacterCount)
        if (links.length > 0) this.processEditorLinks(links, templateText)
        if (this.templateText === '') {
          this.removeLinkPreview()
        }
      } else {
        this.templateText = templateText
        this.debounceTextUpdate(count)
      }
    },

    handleEditorTextUpdate: debounce(function (templateText, text, count) {
      console.debug(
          'handleEditorTextUpdate',
          this.type,
          templateText,
          text,
          count
      )
      this.$emit('onEditorBoxTextChange', this.type, this.templateText, this.getCharacterCount)
    }, 500),

    getSuggestions: async function (type, text, done) {
      console.log(
          'METHOD::getSuggestions ~ type, text, done -> ',
          type,
          text,
          done
      )
      console.log(
          'getSuggestions',
          this.type,
          this.accountsData.accountSelection
      )
      if (type === 'mention') {
        const suggestions = []
        if (
            this.type === 'common' ||
            this.type === 'facebook' ||
            this.type === 'twitter' ||
            this.type === 'instagram' ||
            this.type === 'linkedin' ||
            this.type === 'bluesky'
        ) {
          const platforms = []
          if (this.type === 'common') {
            // if bluesky account selected
            if (!isEmpty(this.accountsData.accountSelection.bluesky)) {
              platforms.push('bluesky')
            }
            // if facebook account selected
            if (!isEmpty(this.accountsData.accountSelection.facebook)) {
              platforms.push('facebook')
            }
            // if twitter account selected
            if (!isEmpty(this.accountsData.accountSelection.twitter)) {
              platforms.push('twitter')
            }
            if (!isEmpty(this.accountsData.accountSelection.linkedin)) {
              platforms.push('linkedin')
            }
          } else if (this.type === 'facebook') {
            // if facebook account selected
            if (!isEmpty(this.accountsData.accountSelection.facebook)) {
              platforms.push('facebook')
            }
          } else if (this.type === 'twitter') {
            // if Twitter account selected
            if (!isEmpty(this.accountsData.accountSelection.twitter)) {
              platforms.push('twitter')
            }
          } else if (this.type === 'linkedin') {
            // if LinkedIn account selected
            if (!isEmpty(this.accountsData.accountSelection.linkedin)) {
              platforms.push('linkedin')
            } else if (this.type === 'bluesky') {
              // if LinkedIn account selected
              if (!isEmpty(this.accountsData.accountSelection.bluesky)) {
                platforms.push('bluesky')
              }
            }
          }
          this.mentionsLoader = true
          const tempSuggestions = await this.fetchComposerMentionSuggestions(
              text,
              platforms
          )
          this.mentionsLoader = false

          if (tempSuggestions) {
            console.log('suggestions', tempSuggestions)
            if (tempSuggestions.facebook && tempSuggestions.facebook.length) {
              const facebookMentions = tempSuggestions.facebook.map((item) => ({
                name: item.name,
                username: item.username,
                id: item.id,
                tab: 'facebook',
                verification_status:
                    item.verification_status === 'blue_verified',
                fan_count: item.fan_count,
                picture: item.picture?.data?.url,
                selectable_field: 'name',
              }))

              suggestions.push(...facebookMentions)
            }

            if (tempSuggestions.twitter && tempSuggestions.twitter.length) {
              const twitterMentions = tempSuggestions.twitter.map((item) => ({
                name: item.name,
                username: `@${item.username}`,
                id: `@${item.username}`,
                tab: 'twitter',
                verification_status: item.is_verified,
                fan_count: item.followers_count,
                picture: item?.picture,
                selectable_field: 'username',
              }))

              suggestions.push(...twitterMentions)
            }

            if (tempSuggestions.bluesky && tempSuggestions.bluesky.length) {
              const blueskyMentions = tempSuggestions.bluesky.map((item) => ({
                name: item?.display_name,
                username: `@${item.handle}`,
                id: `@${item.did}`,
                tab: 'bluesky',
                picture: item?.avatar,
                selectable_field: 'username',
              }))

              suggestions.push(...blueskyMentions)
            }

            if (tempSuggestions?.linkedin?.length) {
              const linkedInMentions = tempSuggestions.linkedin.map((item) => ({
                name: item.name,
                username: `${item.name}`,
                id: `${item.id}`,
                tab: 'linkedin',
                verification_status: item.is_verified,
                picture: item?.picture,
                selectable_field: 'username',
              }))

              suggestions.push(...linkedInMentions)
            }
          }

          if (suggestions.length) {
            done(suggestions)
          } else {
            done([])
          }
        } else {
          done([])
        }

        // setTimeout(() => {
        //   done([
        //     {name: 'Seerat Awan', username: '@seeratawan', id: '999999', tab:'facebook', verification_status: true, fan_count: 123456, picture: 'https://www.w3schools.com/howto/img_avatar.png' },
        //     {name: 'John Doe', username: '@johndoe', id: '123123', tab:'facebook', verification_status: false, fan_count: 123456, picture: 'https://www.w3schools.com/howto/img_avatar.png' },
        //     {name: 'John Doe', username: '@johndoe', id: '123123', tab:'facebook', verification_status: true, fan_count: 123456, picture: 'https://www.w3schools.com/howto/img_avatar.png' },
        //     {name: 'John Doe', username: '@johndoe', id: '123123', tab:'facebook', verification_status: true, fan_count: 123456, picture: 'https://www.w3schools.com/howto/img_avatar.png' },
        //     {name: 'John Doe', username: '@johndoe', id: '123123', tab:'facebook', verification_status: true, fan_count: 123456, picture: 'https://www.w3schools.com/howto/img_avatar.png' },
        //     {name: 'John Doe', username: '@johndoe', id: '123123', tab:'facebook', verification_status: true, fan_count: 123456, picture: 'https://www.w3schools.com/howto/img_avatar.png' },
        //     {name: 'JoeLewis', username: '@joelewis', id: '@JoeLewis', tab:'twitter', verification_status: true, fan_count: 2313, picture: 'https://www.w3schools.com/howto/img_avatar.png'},
        //     {name: 'JoeLewis', username: '@joelewis', id: '@JoeLewis', tab:'twitter', verification_status: true, fan_count: 2313, picture: 'https://www.w3schools.com/howto/img_avatar.png'},
        //     {name: 'JoeLewis', username: '@joelewis', id: '@JoeLewis', tab:'twitter', verification_status: true, fan_count: 2313, picture: 'https://www.w3schools.com/howto/img_avatar.png'},
        //     {name: 'JoeLewis', username: '@joelewis', id: '@JoeLewis', tab:'twitter', verification_status: true, fan_count: 2313, picture: 'https://www.w3schools.com/howto/img_avatar.png'},
        //
        //   ])
        // }, 500)
        // pass dummy mention suggestions
      } else if (type === 'tag') {
        // pass dummy tag suggestions
        // done([{tag: 'WikiLeaks'}, {tag: 'NetNeutrality'}])
      }
  },

    getSuggestionsLoader() {
      if (
          (this.type === 'common' ||
              this.type === 'facebook' ||
              this.type === 'twitter' ||
              this.type === 'bluesky' ||
              this.type === 'linkedin') &&
          ((this.accountsData.accountSelection.facebook &&
                  this.accountsData.accountSelection.facebook.length) ||
              (this.accountsData.accountSelection.twitter &&
                  this.accountsData.accountSelection.twitter.length) ||
              (this.accountsData.accountSelection.linkedin &&
                  this.accountsData.accountSelection.linkedin.length)) ||
          (this.accountsData.accountSelection.bluesky && this.accountsData.accountSelection.bluesky.length)
      ) {
        return "<div class='mention-loader mx-auto my-3'></div>"
      } else {
        return ''
      }
    },
    onEmojiSelect: function (emojiUnicode) {
      const editor = toRaw(this.$refs.editorElement.editor)
      editor.insertText(emojiUnicode)
      editor.view.focus()
    },
    addLocation(locationType, location) {
      this.$emit('add-location', this.type, locationType, location)
    },
    handleUtmChanges(flag, data) {
      this.$emit('handle-utm', flag, this.type, data)
    },
    handleReplugChanges(data) {
      this.$emit('handle-replug', this.type, data)
    },
    handleHashtagsChanges(flag, data) {
      this.$emit('handle-hashtags', this.type, flag, data)
    },
    /**
     * Remove the link preview from the editor
     */
    removeLinkPreview: function (removeImage = false, trigger = false) {
      this.$emit('removeLinkPreview', this.type, removeImage, trigger)
    },

    /**
     * This method will be called when user clicks on the cross button on media asset
     */
    removeMediaAsset: function (asset, mediaType, index) {
      console.debug('Method:removeMediaAsset-v2', this.type, mediaType, asset, index)
      this.$emit('removeMedia', this.type, mediaType, asset, false, index)
    },

    processEditorLinks(links, text) {
      console.debug('Method:processEditorLinks', links, this.type)
      if (!this.linkPreviewLoader && !this.linkShortenerLoader)
        this.CSProcessLinks(
            this.type === 'threaded-tweet' ? 'twitter' : this.type,
            links,
            this.getTemplateText,
            this.getSharingDetail,
            this.cancelledLink,
            this.handleCallback,
            this.savedLinks,
            this.isMediaEnabled
        )
    },


    /**
     * @description: This method is used upload image
     * @param event
     */
    composerUploadImage(event) {
      this.CSUploadImage(
          event,
          this.type,
          this.getSharingDetail,
          this.cancelledLink,
          this.handleCallback,
          this.uploadImageConfirmation
      )
    },

    composerUploadPdf(event){
      this.CSUploadPdf(
          event,
          this.type,
          this.handleCallback,
          this.pdfUploadConfirmation
      )
    },

    /**
     * @description: This method is used upload video
     * @param event
     */
    composerUploadVideo(event) {
      this.CSUploadVideo(
          event,
          this.type,
          this.getSharingDetail,
          this.handleCallback,
          this.uploadVideoConfirmation
      )
    },

    /**
     * @description: This method is used handle callback from mixin
     * @param {string} actionType - action type
     * @param {object} payload - action payload
     */
    handleCallback(actionType, payload) {
      console.debug(
          'Method:processEditorLinks handleCallback ',
          actionType,
          payload
      )
      switch (actionType) {
        case 'details':
          this.sharingDetails[payload.propertyName] = payload.value
          break
        case 'local-property':
          if (payload.objectName)
            this[payload.objectName][payload.propertyName] = payload.value
          else this[payload.propertyName] = payload.value
          break
        case 'setLinkPreview':
          this.$emit('setLinkPreview', this.type, payload.value)
          break
        case 'handle-change':
          this.$emit('handle-change', this.sharingDetails, this.type)
          break
        case 'removeLinkPreview':
          this.removeLinkPreview(true, true)
          break
        case 'removeMedia':
          // For instagram carosuel post we will not remove the media
          if(this.isTypeCommonOrInstagram(this.type)) return
          this.$emit(
              'removeMedia',
              this.type,
              payload.mediaType,
              payload.media,
              payload.removeAll
          )
          break
        case 'addMedia':
          this.$emit('addMedia', this.type, payload.media, payload.mediaType)
          break
        case 'addDocument':
          this.$emit('add-document', this.type, payload.document, payload.mediaType)
          break
        case 'initializeVideoUpload':
          this.$emit('initializeVideoUpload', this.type)
          break
        case 'handle-url':
          this.saveUrl(payload)
          break
      }
    },
    saveUrl(payload) {
      const { original, short } = payload
      const value = this.savedLinks.find(
          (item) => item.short === short || item.original === original
      )
      if (value) return
      this.savedLinks.push(payload)
      this.$emit('save-editor-url', this.type, this.savedLinks)
    },

    async handleFetchAIHashtags(reGenerate = false) {
      const hashtags = this.$refs.editor_AI_hashtags
      hashtags?.setLoader(true)
      hashtags?.setHashtags([])
      const payload = {
        workspace_id: this.$store.getters.getActiveWorkspace._id,
        prompt: this.sharingDetails.message,
        regenerate: reGenerate,
      }
      await proxy
          .post(fetchAIHashTags, payload)
          .then((res) => {
            if (!res.data.status) {
              if (res.data.creditFull)
                this.$store.getters.getPlan.used_limits.caption_generation_credit =
                    res.data.usedCredits
              this.alertMessage(res.data.message, 'error')
              hashtags?.setLoader(false)
              return
            }
            this.$store.getters.getPlan.used_limits.caption_generation_credit =
                res.data.limits.used
            hashtags?.setHashtags(res.data.hashtags)
            hashtags?.setLoader(false)
          })
          .catch((err) => {
            console.log('FetchAiHashtagsCall', err)
            this.alertMessage('Something went wrong. Please try again.', 'error')
            hashtags?.setLoader(false)
          })
    },

    loadHashtagDropdown(value = []) {
      this.hashtagToggle = true
      this.externalTags = value
    },
    /**
     * Changes the variations of the caption generated
     */
    changeVariation() {
      const message = this.sharingDetails.message
      const tags = getHashtagsFromText(message)
      const links = memoizedGetLinksFromText(message)
      const mentions = this.getTextMentions(message)
      // this.$store.commit(sharingTypes.SET_HASHTAGS_DROPDOWN_STATUS, false)
      let index = 0
      if (
          this.getTwitterVariationsIndex !==
          this.getTwitterVariations.length - 1
      ) {
        index = this.getTwitterVariationsIndex + 1
      }
      this.$store.commit(commonTypes.SET_ACTIVE_TWITTER_VARIATION_INDEX, index)

      let newMessage = this.getTwitterVariations[index]
      if (mentions) {
        for (const prop in mentions) {
          newMessage = newMessage + ' ' + mentions[prop]
        }
      } // apply mentions
      if (tags) {
        for (const prop in tags) newMessage = newMessage + ' ' + tags[prop]
      } // apply hashtags
      if (links) {
        for (const prop in links) newMessage = newMessage + ' ' + links[prop]
      } // apply links

      const editor = toRaw(this.$refs.editorElement.editor)
      editor.setContent(newMessage)
      // this.setSharingMessage('Common', newMessage)
    },
    addSocialHashtag(tag, index, isBulk = false) {
      console.log('METHOD::addSocialHashtag', tag, index, isBulk)
      const editor = toRaw(this.$refs.editorElement.editor)
      // this.sharingDetails.message = this.sharingDetails.message + ' ' + tag
      if (isBulk && tag.length > 1) {
        tag.forEach((item) =>
            editor.insertText(' ' + item)
        )
        return
      }
      editor.insertText(' ' + tag)
      // this.$refs.editorElement.editor.insertText(tag)
    },

    onPinterestSourceUrlChange: debounce(function (value) {
      console.log('METHOD::onPinterestSourceUrlChange ~ event -> ', value)
      this.sharingDetails.source_url = value
      this.$emit('handle-change', this.sharingDetails, this.type)
    }, 500),


    shrinkSharingSourceLinkNew(type, link) {
      console.debug(
          'Method:shrinkSharingSourceLinkNew ~ type, link -> ',
          type,
          link
      )

      if (this.isValidUrl(link)) {
        this.pinterestUrlLinkLoader = true
        /* if (type === 'Common') {
          this.setShrinkCommonSharingSourceLinkLoader(true)
        } else if (type === 'Pinterest') {
          this.setShrinkPinterestSharingSourceLinkLoader(true)
        } */

        this.postRequest(
            fetchShortLinksUrl,
            {
              urls: [link],
              workspace_id: this.getWorkspaces.activeWorkspace._id,
            },
            (response) => {
              if (response.data.links && response.data.links.length) {
                /* if (type === 'Common') {
                    this.setCommonSharingSourceLink(response.data.links[0].short)
                  } else if (type === 'Pinterest') {
                    this.setPinterestSharingSourceLink(response.data.links[0].short)
                  } */
                this.sharingDetails.source_url = response.data.links[0].short
                this.$emit('handle-change', this.sharingDetails, this.type)
                this.pinterestUrlLinkLoader = false
              } else if (
                  response.data.status === false &&
                  response.data.message
              ) {
                this.alertMessage(response.data.message, 'error')
              }
              this.pinterestUrlLinkLoader = false
            },
            (error) => {
              console.debug('Exception in shrinkSharingSourceLink', error)
              this.pinterestUrlLinkLoader = false
              /* if (type === 'Common') {
                  this.setShrinkCommonSharingSourceLinkLoader(false)
                } else if (type === 'Pinterest') {
                  this.setShrinkPinterestSharingSourceLinkLoader(false)
                } */
            }
        )
      } else {
        this.alertMessage('Please enter a valid URL to shorten.', 'error')
      }
    },

     /**
     * Event for editing image.
     * @param asset
     * @param index
     */
     onEdit(asset, index) {
      console.log('METHOD::onEdit ~ asset -> ', asset, index)
      this.index = index
      if (typeof asset === 'string') {
        // New Version Implementation.
        EventBus.$emit('enhanceImagePintura', {
          stateObject: this,
          type: this.type,
          image: asset,
          index,
          threadedTweetIndex: this.tweetIndex,
          modalVisible: true,
        })
      } else if (typeof asset === 'object') {
        if (asset?.converted_mime_type === 'video/mp4') {
          this.triggerVideoTitleModal(asset)
        }
      }
    },

    /**
     * event for adding alt text to the image.
     * @param image
     * @param altText
     * @param index
     */
    setAlt(image, altText, index) {
      console.log('METHOD::setAltText ~ image, index -> ')
      if (!('alt_texts' in this.sharingDetails)) {
        this.sharingDetails.alt_texts = []
      }

      this.sharingDetails.alt_texts.push({ image, alt_text: altText })
    },

    /**
     * enable the video-title modal
     * @param video
     */
    triggerVideoTitleModal(video) {
      console.log('Method:: TriggerVideoTitleModal')
      this.videoTitle = video.title
      this.videoTitleModal = true
    },

    /**
     * close modal method.
     * @param event
     */
    closeVideoTitleModal(event) {
      console.log('Method:: closeVideoTitleModal ~ event -> ', event)
      this.videoTitleModal = false
    },
    onClickSaveVideoTitle(event) {
      console.log('METHOD::onClickSaveVideoTitle ~ event -> ', event)

      // For type common or instagram we will be updating the title in multimedia array
      if (this.isTypeCommonOrInstagram(this.type)) {
        const { multimedia } = this.sharingDetails || {};
        if (multimedia && multimedia.length > 0 && multimedia[this.index]) {
          multimedia[this.index].title = this.videoTitle;
        }
      }
      this.sharingDetails.video.title = this.videoTitle;
      this.videoTitleModal = false
      this.videoTitle = ''
    },
    handleCustomBoxToggle(val) {
      this.$emit('isSeparateBoxes', val)
    },
    showLocationModal(){
      this.$bvModal.show('set-location-modal')
      this.$emit('show-location-modal', this.type)

    },
     /**
     * @description: This method is used handle AI Actions
     * @param event
     */
     handleAIClick(type = ''){
      const textActions = ['ai-improve','ai-rephrase', 'ai-shorten', 'ai-lengthen', 'ai-grammar-fix', 'ai-caption-generation']

      if (textActions.includes(type)) {
        EventBus.$emit('ai-text-action', {
          type: type.replace('ai-', ''),
          text: this.templateText
        })
        EventBus.$emit('open-chat-modal')
      } else {
        switch (type) {
          case 'ai-image-generation':
            EventBus.$emit('close-chat-modal')
            EventBus.$emit('imageGeneratorModal', { isOldComposer: false })
            break
          case 'ai-hashtags-generation':
            this.handleFetchAIHashtags(true)
            break
        }
      }

      this.AiActionDropDown = false
    },

    handleImageAction(type = '') {
      const imageActions = ['ai-caption-from-image', 'ai-extract-text-image', 'ai-summarize-image', 'ai-describe-image']
      if (imageActions.includes(type)) {
        EventBus.$emit('ai-image-action', {
          type: type.replace('ai-', ''),
          images: this.sharingDetails.multimedia.filter(media => typeof media === 'string')
        })
        EventBus.$emit('open-chat-modal')
      }
      this.AiActionDropDown = false
    },
  },


}
</script>

<style lang="scss">
.cst-editor {
  @apply p-px;
  transition: all 0.2s ease;
  &:hover {
    border-color: #409aff;
    box-shadow: 0 0 0 1px rgba(64, 154, 255, 0.8);
  }
  input::placeholder {
    color: #a4a8ac;
    font-size: 0.875rem;
  }
  input::-webkit-input-placeholder {
    color: #a4a8ac;
  }
  .cst-textarea {
    min-height: 120px;
    @apply text-black-200;
    //padding:0 4px;
    @apply text-base;
    @apply rounded-t-lg;
  }
  &__toolbar {
    border-top: 1px solid #eff0f1;
    @apply rounded-b-lg;
  }
  &__media,
  &__link-preview {
    overflow: hidden;
    border-top: 1px solid #eff0f1;
    @apply rounded-b-lg;
  }
}
</style>
