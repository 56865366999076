<template>
  <div class="dropboxFolers">
    <div class="media-tab__header"> Add Media Assets from Dropbox </div>
    <div class="media-tab__body">
      <div v-if="images.length > 0" class="media-tab__info">
        <div class="media-tab__count">
          Item{{ images.length > 1 ? 's' : '' }} of size
          {{ bytesToSize(totalSize) }}
          {{ images.length > 1 ? 'are' : 'is' }} ready to upload.
        </div>
        <div class="media-tab__actions">
          <button :disabled="isUploading" @click="clearFiles">
            Remove All
          </button>
        </div>
      </div>

      <div v-if="images.length == 0" class="dropbx-main-wraper">
        <div class="dropbx-logo-wrap mb-4">
          <div class="logo"><i class="fab fa-dropbox logo"></i> Dropbox</div>
        </div>
        <b-button variant="primary" class="my-3" @click="chooseDropBox">
          Upload from dropbox
        </b-button>
      </div>

      <div class="media-tab__items">
        <div class="grid-flex">
          <div v-if="images.length > 0" class="cell-md-3">
            <label for="upload-modal-btn" class="media-tab__upload-btn">
              <div>
                <i class="fab fa-dropbox logo"></i>
                Add From Dropbox
              </div>
            </label>

            <input
              v-show="false"
              id="upload-modal-btn"
              type="file"
              @click="chooseDropBox"
            />
          </div>

          <div
            v-for="(image, k) in images"
            :key="k"
            class="cell-md-3 mb-3 imgBox"
          >
            <clip-loader
              v-if="isUploading"
              class="spinner"
              :color="'#0a97f3'"
              :size="'24px'"
            ></clip-loader>
            <Asset
              v-if="imagesShow"
              :id="image._id"
              :hide-delete="isUploading"
              type="secondary"
              :src="image.url"
              :alt="image.name"
              :info="image"
              @delete="deleteItem"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="media-tab__footer">
      <div class="d-flex align_center">
        <div class="mr-3 font-0-95rem">Folder:</div>
        <div class="media-tab__footer-folders">
          <i class="far fa-folder mr-3"></i>
          <TreeSelect
            v-model="selectedFolderValue"
            :disabled="isUploading"
            placeholder="Select Folder"
            :clearable="false"
            :flatten-search-results="true"
            :options="treeFolders"
            :normalizer="normalizer"
          >
          </TreeSelect>
        </div>
      </div>

      <div
        class="
          media-tab__footer-actions
          d-flex
          flex-grow-1
          justify-content-end
          flex-shrink-0
          items-center
          gap-x-8
        "
      >
          <div v-if="isUploading" >
            <b-progress
            v-if="progress"
            class="w-60 2xl:w-96"
            :value="progress"
            :max="100"
            show-progress
            animated
        >
          <b-progress-bar :value="progress" >
            {{ progress }}%
          </b-progress-bar>
        </b-progress>
          </div>

          <div class="flex items-center gap-x-4">

            <CstButton
              v-tooltip="'Upload to Media Library'"
              variant="secondary"
              :disabled="images.length == 0 || isUploading"
              @click="uploadFiles(false)"
            >
              Upload
              <clip-loader
                v-if="isUploading"
                class="spinner ml-2"
                :color="'#e0dfdf'"
                :size="'16px'"
              ></clip-loader>
            </CstButton>

            <CstButton
            v-tooltip="'Add to Composer and Upload to Media Library'"
            :disabled="images.length == 0 || isUploading"
            @click="uploadFiles(true)"
          >
            Add to Composer
            <clip-loader
              v-if="isUploading"
              class="spinner ml-2"
              :color="'#e0dfdf'"
              :size="'16px'"
            ></clip-loader>
          </CstButton>

          </div>
      </div>
    </div>
  </div>
</template>

<script>
import isEmpty from 'is-empty'
import { EventBus } from '@common/lib/event-bus'
import CstButton from '@ui/Button/CstButton.vue';
import Asset from '../Asset.vue';
import { MediaHelperMixin } from '../../utils/MediaHelpers'
import { DROPBOX_APP_KEY } from '../../../../../../config/api-utils'

export default {
  name: 'DropBoxMediaTab',
  components: {
    Asset,
    CstButton
  },
  mixins: [MediaHelperMixin],
  props: {
    type: {
      type: String,
      default: '',
    },
    folders: {
      type: Array,
      default: () => [],
    },
    modalId: {
      type: String,
      required: true
    }
  },
  emits: ['changeTab'],
  data() {
    return {
      images: [],
      isUploading: false,
      selectedFolderValue: 'uncategorized',
      progress: null,
      imagesShow: false,
    }
  },

  computed: {
    treeFolders() {
      let list = [{ id: 'uncategorized', label: 'Uncategorized' }]

      list = [
        ...list,
        ...this.folders.map((folder) => {
          const temp = folder

          temp.children = folder.sub_folders.map((subfolder) => {
            return {
              id: subfolder._id,
              label: subfolder.folder_name,
            }
          })

          if (temp.children.length === 0) {
            return {
              id: temp._id + '-root',
              label: temp.folder_name,
            }
          }

          return {
            id: temp._id + '-root',
            label: temp.folder_name,
            children: temp.children,
          }
        }),
      ]

      return list
    },
    totalSize() {
      let size = 0

      this.images.forEach((image) => {
        size = size + image.size
      })

      return size
    },
  },

  watch: {
    isUploading(val) {
      this.$emit('uploading', val)
    },
  },
  mounted() {
    const dropBox = document.createElement('script')
    dropBox.setAttribute(
      'src',
      'https://www.dropbox.com/static/api/2/dropins.js'
    )
    dropBox.setAttribute('id', 'dropboxjs')
    dropBox.setAttribute('data-app-key', DROPBOX_APP_KEY)
    document.head.appendChild(dropBox)

    if (!isEmpty(this.$route.query.type)) {
      if (this.$route.query.type === 'folder' && this.$route.query.folderId) {
        let folderValue = this.$route.query.folderId

        if (this.$route.query.root) {
          folderValue = folderValue + '-root'
        }

        this.selectedFolderValue = folderValue
      }
    }
  },
  methods: {
    chooseDropBox() {
      self = this
      const options = {
        success: async (files) => {
          this.isUploading = true
          for (let i = 0; i < files.length; i++) {
            const attachment = {}

            const getUrlExtension = (url) => {
              return url.split(/[#?]/)[0].split('.').pop().trim()
            }

            const imgExt = getUrlExtension(files[i].link)

            const response = await fetch(files[i].link)
            const blob = await response.blob()
            const file = new File([blob], files[i].name + imgExt, {
              type: blob.type,
            })

            attachment.file = file
            attachment._id = i
            attachment.name = files[i].name
            attachment.size = files[i].bytes
            attachment.url = files[i].link
            // attachment.type = "image/"+`${files[i].name.split(".")[1]}`;
            attachment.type = attachment.file.type
            self.images.push(attachment)
          }

          this.isUploading = false
          this.imagesShow = true
        },

        cancel: function () {},
        linkType: 'direct',
        multiselect: true,
        extensions: [
          '.png',
          '.jpg',
          '.jpeg',
          '.gif',
          '.mp4',
          '.mov',
          // '.3gp',
        ],
        folderselect: false,
        sizeLimit: 102400000,
      }

      Dropbox.choose(options)
    },

    normalizer(node) {
      let name = node.label

      if (node.id === 'uncategorized') {
        name = name + ' (Main)'
      }

      return {
        id: node.id,
        label: name,
        children: node.children,
      }
    },

    clearFiles() {
      this.images = []
    },

    deleteItem(id) {
      this.images = this.images.filter((img) => img._id !== id)
    },

    async uploadFiles(addToComposer) {
      this.isUploading = true
      let folderId = null
      let isRoot = false

      const filters = {
        media: this.images,
        folder_id: null,
      }

      // If any folder selected
      if (!isEmpty(this.selectedFolderValue)) {
        ;[folderId, isRoot] = this.selectedFolderValue.split('-')

        folderId = folderId === 'uncategorized' ? null : folderId
        isRoot = !isEmpty(isRoot)
        filters.folder_id = folderId

        if (folderId) {
          filters.is_root = isRoot
        }
      }

      await this.uploadFilesHelper(
        filters,
        (status, message, mediaContainer) => {
          if (status) {
            if(addToComposer){
              EventBus.$emit('add-media-to-editor', mediaContainer)
            }
            this.clearFiles()
            this.isUploading = false
            if (this.type === 'library') {
              this.$bvModal.hide(this.modalId)
              EventBus.$emit('refetch-folders')
              EventBus.$emit('refetch-media', { folderId, isRoot })
              EventBus.$emit('refetch-media-limits')
            } else {
              if(addToComposer){
                this.$bvModal.hide(this.modalId)
                return
              }
              this.$emit('changeTab', 1)
            }
          }
        },
        (percentCompleted) => {
          this.progress = percentCompleted
        }
      )
      this.isUploading = false
    },
  },
}
</script>

<style lang="scss">
.dropbx-main-wraper {
  text-align: center;
  position: absolute;
  top: 40%;
  margin: auto;
  left: 0;
  right: 0;
  width: 215px;

  .dropbx-logo-wrap {
    background: #fff;
    padding: 8px;
    border-radius: 5px;
    // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    //   0 2px 4px -1px rgba(0, 0, 0, 0.06);

    .logo {
      // background: #eee;
      font-size: 34px;
      font-weight: 600;
      line-height: 1.5;

      i {
        font-weight: initial;
        font-size: 38px;
        color: #0a97f3;
      }
    }
  }
}

.imgBox {
  position: relative;
  height: 158px;

  .spinner {
    position: absolute;
    top: 43%;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
  }
}
</style>
