<template>
  <div>
    <div class="media-tab__header"> Find an image on {{ title }} </div>
    <div class="media-tab__body">
      <form @submit.prevent="fetchMicroDetails(false)">
        <div class="simple-form__text-field mt-3">
          <label for="url">
            <i class="icon-Search"></i>
          </label>
          <input
            id="url"
            v-model="keyword"
            type="text"
            placeholder="Type and search something"
            :disabled="isUploading || isFetching"
            @keydown.enter="fetchMicroDetails(false)"
          />

          <button
            v-if="keyword.trim().length > 0"
            class="btn btn-studio-theme-transparent-grey btn-size-small add-btn"
            type="submit"
            :disabled="isUploading || isFetching"
          >
            Search
          </button>
        </div>
      </form>

      <div v-if="isFetching && page === 1" class="mt-3">
        <clip-loader
          class="spinner"
          :color="'#9da6ac'"
          :size="'20px'"
        ></clip-loader>
      </div>
      <template v-else>
        <div
          v-if="fetchedImages !== null && fetchedImages.length === 0"
          class="text-center"
        >
          <i
            >Your search
            <template v-if="lastKeyword !== ''">
              - <b>{{ lastKeyword }}</b> -
            </template>
            did not match any images</i
          >
        </div>

        <div
          v-else-if="fetchedImages !== null"
          class="media-tab__items"
          @scroll="handleScroll"
        >
          <div class="item-row mx-auto mt-2">
            <div v-for="(image, k) in fetchedImages" :key="k" class="item-card">
              <Asset
                :id="image.id"
                :hide-eye="true"
                :hide-delete="true"
                :src="getImage(image).thumbnail"
                :alt="getImage(image).thumbnail"
                :info="image"
                @check="selectItem"
              />
            </div>
          </div>
        </div>

        <div v-if="notFound" class="text-center">
          <div
            class="
              d-flex
              w-100
              justify-content-center
              flex-column
              align-items-center
            "
          >
            <img
              draggable="false"
              src="@src/assets/img/no_data_found.svg"
              alt=""
              width="250"
            />
            <i>Media not found</i>
          </div>
        </div>
      </template>
    </div>

    <div class="media-tab__footer">
      <div class="d-flex align_center">
        <div class="mr-3 font-0-95rem">Folder:</div>
        <div class="media-tab__footer-folders">
          <i class="far fa-folder mr-3"></i>

          <TreeSelect
            v-model="selectedFolderValue"
            :normalizer="normalizer"
            placeholder="Select Folder"
            :clearable="false"
            :flatten-search-results="true"
            :options="treeFolders"
          />
        </div>
      </div>

      <div
        class="
          media-tab__footer-actions
          d-flex
          flex-grow-1
          justify-content-end
          flex-shrink-0
          ml-2
        "
      >
        <button
          class="btn btn-studio-theme-space"
          :disabled="
            selectedImages.length === 0 ||
            isUploading ||
            (fetchedImages !== null && fetchedImages.length === 0) ||
            fetchedImages === null
          "
          @click="uploadFiles"
        >
          Upload
          <clip-loader
            v-if="isUploading"
            class="spinner ml-2"
            :color="'#e0dfdf'"
            :size="'16px'"
          ></clip-loader>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import isEmpty from 'is-empty'
import { EventBus } from '@common/lib/event-bus'
import { MediaHelperMixin } from '../../utils/MediaHelpers'
import Asset from '../Asset'
export default {
  name: 'SearchMediaTab',
  components: {
    Asset,
  },
  mixins: [MediaHelperMixin],
  props: {
    type: {
      default: '',
    },
    kind: {
      default: '',
    },
    folders: {},
    modalId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedFolderValue: 'uncategorized',
      isUploading: false,
      isFetching: false,
      keyword: '',
      lastKeyword: '',
      fetchedImages: null,
      selectedImages: [],
      at_bottom: false,
      page: 1,
      notFound: false,
    }
  },
  computed: {
    title() {
      switch (this.kind) {
        case 'pixabay': {
          return 'Pixabay'
        }
        case 'giphy': {
          return 'Giphy'
        }
        default: {
          return ''
        }
      }
    },

    treeFolders() {
      let list = [{ id: 'uncategorized', label: 'Uncategorized' }]

      list = [
        ...list,
        ...this.folders.map((folder) => {
          const temp = folder

          temp.children = folder.sub_folders.map((subfolder) => {
            return {
              id: subfolder._id,
              label: subfolder.folder_name,
            }
          })

          if (temp.children.length === 0) {
            return {
              id: temp._id + '-root',
              label: temp.folder_name,
            }
          }

          return {
            id: temp._id + '-root',
            label: temp.folder_name,
            children: temp.children,
          }
        }),
      ]

      return list
    },
  },
  watch: {
    isUploading(val) {
      this.$emit('uploading', val)
    },
  },
  mounted() {
    if (!isEmpty(this.$route.query.type)) {
      if (this.$route.query.type === 'folder' && this.$route.query.folderId) {
        let folderValue = this.$route.query.folderId

        if (this.$route.query.root) {
          folderValue = folderValue + '-root'
        }

        this.selectedFolderValue = folderValue
      }
    }
  },
  methods: {
    normalizer(node) {
      let name = node.label

      if (node.id === 'uncategorized') {
        name = name + ' (Main)'
      }

      return {
        id: node.id,
        label: name,
        children: node.children,
      }
    },

    async handleScroll(el) {
      let scrollHeight, totalHeight
      scrollHeight = el.target.scrollHeight
      totalHeight = el.target.offsetHeight + el.target.scrollTop

      if (
        totalHeight >= scrollHeight &&
        !this.at_bottom &&
        !isEmpty(this.fetchedImages) &&
        !this.fetchedImages.completed
      ) {
        this.at_bottom = true
        console.log('at the bottom')

        await this.fetchMicroDetails(true)
        this.at_bottom = false
      }
    },

    getImage(obj) {
      switch (this.kind) {
        case 'pixabay': {
          return {
            thumbnail: obj.webformatURL,
            original: obj.largeImageURL,
          }
        }
        case 'giphy': {
          return {
            thumbnail: obj.images.downsized.url,
            original: obj.images.original.url,
          }
        }
        default: {
          return {
            thumbnail: '',
            original: '',
          }
        }
      }
    },

    selectItem(element) {
      console.debug('selectItem', element)
      const id = element.getAttribute('id')

      const selectedImageObj = this.fetchedImages.filter((item) => {
        if (typeof item.id === 'string') {
          return item.id === id
        } else {
          return item.id === parseInt(id)
        }
      })[0]

      const isExist = this.selectedImages.filter(
        (item) => item === this.getImage(selectedImageObj).original
      )

      if (!isExist.length) {
        this.selectedImages.push(this.getImage(selectedImageObj).original)
        element.setAttribute('data-item-selected', true)
      } else {
        const index = this.selectedImages.indexOf(
          this.getImage(selectedImageObj).original
        )

        console.debug('selectItem', index)

        this.selectedImages.splice(index, 1)
        element.setAttribute('data-item-selected', false)
      }
    },

    async uploadFiles() {
      this.isUploading = true

      let folderId = null
      let isRoot = false

      const filters = {
        link: [...this.selectedImages],
        folder_id: null,
      }

      // If any folder selected
      if (!isEmpty(this.selectedFolderValue)) {
        ;[folderId, isRoot] = this.selectedFolderValue.split('-')

        folderId = folderId === 'uncategorized' ? null : folderId
        isRoot = !isEmpty(isRoot)
        filters.folder_id = folderId

        if (folderId) {
          filters.is_root = isRoot
        }
      }

      await this.uploadLinksFilesHelper(filters, (status, message) => {
        if (status) {
          this.keyword = ''
          if (this.type === 'library') {
            this.isUploading = false
            this.$bvModal.hide(this.modalId)
            EventBus.$emit('refetch-folders')
            EventBus.$emit('refetch-media', { folderId, isRoot })
            EventBus.$emit('refetch-media-limits')
          } else {
            this.$emit('changeTab', 1)
          }
        }
      })
      this.isUploading = false
    },

    async fetchMicroDetails(flag = false) {
      console.debug('fetchMicroDetails', flag)
      if (flag) {
        this.page = this.page + 1
      }

      this.isFetching = true
      this.lastKeyword = this.keyword
      this.selectedImages = []
      await this.searchMediaHelper(
        {
          keyword: this.keyword,
          source: this.kind,
          page: this.page,
        },
        (status, photos) => {
          console.debug(status, photos)
          if (status) {
            if (flag) {
              this.fetchedImages = [...this.fetchedImages, ...photos]
              // this.fetchedImages = this.fetchedImages + photos
            } else {
              this.fetchedImages = [...photos]
            }
          } else {
            this.notFound = true
          }
        }
      )

      this.isFetching = false
    },
  },
}
</script>
