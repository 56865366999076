<template>
  <div class="auth_parent_container">
    <div v-if="accountExistLoader" class="flex flex-col h-full items-center justify-center">
      <beat-loader :color="'#436aff'"></beat-loader>
    </div>
    <div v-else class="flex mx-auto h-full">
      <!-- Auth form -->
      <SignupSideComponent />

      <!-- Auth slides -->
      <div class="w-full flex flex-col h-full bg-white justify-between py-[1rem] 2xl:py-[2rem] 3xl:py-[3rem] px-28 2xl:px-40">
        <p class="text-[#3A4557] 2xl:py-[2rem] py-[1rem] text-right">Back to<router-link class="text-[#157FFF] hover:text-[#157FFF] hover:underline ml-2" :to="{ name: 'signup' }">Sign up</router-link></p>
        <div class="flex flex-col items-center justify-center flex-grow">
          <form class="auth-form w-[29.5rem]">
            <div class="flex justify-center flex-col align-items-center auth-form__heading auth-form__heading--center">
              <h2 class="text-left text-[#2D2D2D] font-bold text-md 2xl:text-3xl">Continue with {{ getAccountName }}</h2>
              <p class="text-md text-[#757A8A] text-center mt-5">To secure your account and access it via email, set your password and create your workspace</p>
            </div>

            <div class="flex flex-col gap-2 pt-12">
              <CstFloatingLabelInput
                  id="email"
                  v-model="account.email"
                  type="email"
                  label="Your email address"
                  class="!h-[3.3rem] mb-2.5"
                  show-icon-left
                  value="email"
                  :disabled="emailDisabled"
              >
                <template v-slot:icon>
                  <i class="icon-Email"></i>
                </template>
              </CstFloatingLabelInput>

              <!-- Password -->
              <div>
                <CstFloatingLabelInput
                    id="password"
                    v-model="account.password"
                    class="!h-[3.3rem] mb-2.5"
                    :type="showPassword ? 'text' : 'password'"
                    label="Password"
                    :maxlength="18"
                    show-icon-left
                    show-icon-right
                    value="account.password">
                  <template v-slot:icon>
                    <i class="icon-Password"></i>
                  </template>
                  <template v-slot:icon-right>
                    <i
                        class="cursor-pointer"
                        :class="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                        @click="toggleShowPassword"
                    ></i>
                  </template>
                </CstFloatingLabelInput>

                <div v-if="password_strength_message" class="simple-form__text-field-message flex items-center justify-between mx-1 relative top-[1.11rem]" :class="{ danger: password_state === 'danger' }">
                  <p
                      class="text-xs"
                  >
                    {{ password_strength_message }}
                  </p>
                  <div class="flex gap-1">
                    <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-red-500': password_state === 'weak', ' !bg-green-500': password_state === 'strong', '!bg-yellow-500': password_state === 'fair' }"></div>
                    <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-green-500': password_state === 'strong', 'bg-yellow-500': password_state === 'fair' }"></div>
                    <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-green-500': password_state === 'strong' }"></div>
                  </div>
                </div>
              </div>

              <!-- Business Name -->
              <CstFloatingLabelInput
                  id="bName"
                  v-model="account.business_name"
                  class="!h-[3.3rem]"
                  type="text"
                  label="Workspace Name"
                  show-icon-left
                  show-icon-right
                  value="account.business_name">
                <template v-slot:icon>
                  <i class="far fa-briefcase"></i>
                </template>
                <template v-slot:icon-right>
                  <i v-tooltip="'A workspace is a unique (or dedicated) dashboard for each brand or client segregating content, calendar, teams, and tasks.'" class="far fa-question-circle text-lg text-blue-900 cursor-pointer"></i>
                </template>
              </CstFloatingLabelInput>
              <!-- Google Recaptcha-->
              <vueRecaptcha
                  ref="recaptcha"
                  :sitekey="getGoogleRecaptchaKey"
                  size="invisible"
                  theme="light"
                  loading-timeout="30000"
                  @verify="createAccount"
                  @expire="onCaptchaExpired"
                  @fail="onCaptchaExpired"
                  @error="onCaptchaExpired">
              </vueRecaptcha>
            </div>
            <!-- Terms & Conditions -->
            <div class="pt-5 pb-7">
              <p class="text-[#3A4557]">
                By signing up, you agree to our
                <a
                    class="text-[157FFF] hover:text-[#157FFF] hover:underline"
                    href="https://contentstudio.io/terms-and-conditions"
                    target="_blank" rel="noopener" >terms of service</a>
                and
                <a
                    class="text-[157FFF] hover:text-[#157FFF] hover:underline"
                    href="https://contentstudio.io/privacy-policy"
                    target="_blank" rel="noopener"
                >privacy policy.</a
                >
              </p>
            </div>
            <div class="auth-form__button-field">
              <button
                  data-cy="continue_button"
                  class="btn btn-studio-theme-space btn-size-large w-full !h-12 !rounded-lg"
                  @click.prevent="executeRecaptcha"
              >
                <span class="w-full">Continue <img
                    v-if="registerLoader"
                    style="width: 20px; margin-left: 8px"
                    src="../../assets/img/common/gif_loader_white.gif"
                    alt=""
                /></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {workspaceTypes} from '@src/modules/setting/store/states/mutation-types'
import {authenticationTypes} from '@state/mutation-types'
import {accountExistURL, registerURL} from '@src/config/api-utils.js'
import vueRecaptcha from "vue3-recaptcha2";
import CstFloatingLabelInput from '@ui/Input/CstFloatingLabelInput'
import useWhiteLabelApplication from "@modules/setting/composables/whitelabel/useWhiteLabelApplication";
import useTime from "@/src/modules/common/composables/useTime"
import useIp from "@/src/modules/common/composables/useIp"
const SignupSideComponent = () => import("@src/components/authentication/SignupSideComponent.vue")
export default {
  components: {
    SignupSideComponent,
    vueRecaptcha,
    CstFloatingLabelInput
  },
  setup() {
    const { getClientTimeZone } = useTime()
    const {isWhiteLabelDomain} = useWhiteLabelApplication()
    const { ipv4 } = useIp()

    return {
      getClientTimeZone,
      isWhiteLabelDomain,
      ipv4,
    }
  },
  data() {
    return {
      emailDisabled: false,
      showPassword: false,
      account: {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        plan: 'trial',
        business_type: '',
        picture: '',
        id: '',
        type: '',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        phone_no: ''
      },
      size: '14px',
      loaderColor: '#fff',
      registerLoader: false,

      email: '',
      password: '',
      cpassword: '',
      accountExistLoader: false,
      password_strength_message: '',
      password_state: '',
      socialAccounts: {
        twitter: {
          name: 'X (Twitter)'
        },
        facebook: {
          name: 'Facebook'
        },
        google: {
          name: 'Google'
        },
      }
    }
  },
  computed: {
    ...mapGetters(['getProfile']),
    getAccountName() {
      return this.account?.type?.length ? this.socialAccounts[this.account.type].name : ''
    },
    getGoogleRecaptchaKey() {
      return process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY
    },
  },
  watch: {
    'account.password'(value) {
      if (value) {
        if (value.length === 0) {
          this.password_strength_message = ''
          this.password_state = ''
        }
        if (value.length < 12) {
          this.password_strength_message = 'Password must be 12 characters or more';
          this.password_state = 'danger';
        } else if (value.length < 14) {
          this.password_strength_message = 'Password could be more secure';
          this.password_state = 'weak';
        } else if (value.length < 16) {
          this.password_strength_message = 'Password is satisfactory';
          this.password_state = 'fair';
        } else {
          this.password_strength_message = 'Password is strong';
          this.password_state = 'strong';
        }
      } else {
        this.password_strength_message = ''
        this.password_state = ''
      }
    },
  },
  mounted() {},
  async created() {
    this.accountExistLoader = true
    await this.processSSO()
    this.accountExistLoader = false
  },
  methods: {
    ...mapMutations(['SET_PROFILE']),
    ...mapActions(['fetchProfile']),
    toggleShowPassword() {
      this.showPassword = !this.showPassword
    },
    /**
     * Process the SSO token
     * @returns {Promise<void>}
     */
    async processSSO() {
      const token = this.$route.query?.ssoResponse ?? ''
      const error = this.$route.query?.ssoError ?? ''

      // Early return if there's an error
      if (error) {
        this.alertMessage(error, 'error')
        await this.$router.push({ name: 'login' })
        return
      }

      // Early return if token is missing
      if (!token) {
        await this.$router.push({ name: 'login' })
        return
      }

      // Decode and parse token
      let response = null
      try {
        const decodedString = atob(token)
        response = JSON.parse(decodedString)
      } catch (error) {
        console.error('Error while decoding the token:', error)
        await this.$router.push({ name: 'login' })
        return
      }

      // Early return if response is invalid
      if (!response) {
        await this.$router.push({ name: 'login' })
        return
      }

      // Handle successful authentication
      if (response.status) {

        // Handle 2FA
        if (response['2fa_enabled']) {
          await this.$router.push({
            name: 'twoFactor',
            params: {
              token: response.user_info,
            },
            query: {
              ...this.$route.query,
              redirected_url: this.$route.fullPath,
            },
          })
          return
        }

        // Set authentication state
        this.$store.commit(authenticationTypes.SET_JWT_TOKEN, response.token)
        this.$store.commit(authenticationTypes.SET_LOGGED_USER, response.logged_user)
        this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, true)

        await this.fetchProfile()

        if (this.getProfile.onBoarding) {
          await this.isUserOnboarded()
        }

        if (this.shouldRedirectBasedOnUserState(this.getProfile?.state)) {
          return
        }

        if (this.getProfile?.trial_finished) {
          this.$router.push({ name: 'trial_expired' })
          return
        }

        // Handle workspace routing
        try {
          const workspaces = await this.fetchWorkspaces()
          const activeWorkspace = workspaces.find(workspace => workspace.default)

          if (activeWorkspace?.workspace?.slug) {
            await this.$router.push({
              name: this.getProfile?.preferences?.default_landing_page || 'dashboard',
              params: { workspace: activeWorkspace.workspace.slug },
            })
            return
          }

          await this.$router.push({ name: 'workspaces' })
        } catch (error) {
          console.error('Login: Error while fetching workspaces:', error)
          await this.$router.push({ name: 'workspaces' })
        }

        return
      }

      // Handle non-authenticated state
      if (this.isWhiteLabelDomain) {
        this.alertMessage('Account does not exist', 'error')
        await this.$router.push({ name: 'login' })
        return
      }

      // Set account information
      this.emailDisabled = Boolean(response.email)
      this.account.type = response.type
      this.account.id = response.id
      this.account.email = response.email
      this.account.firstname = response.first_name
      this.account.lastname = response.last_name
      this.account.picture = response.picture

    },
    /**
     * Redirect user based on the user state
     * @param state
     * @returns {boolean}
     */
    shouldRedirectBasedOnUserState(state) {
      const stateRedirectMap = {
        'canceled': 'subscription_cancelled',
        'cancelled': 'subscription_cancelled',
        'deleted': 'subscription_deleted',
        'paused': 'subscription_paused',
      }

      if (state && stateRedirectMap[state]) {
        this.$router.push({ name: stateRedirectMap[state] })
        return true
      }

      return false

    },
    executeRecaptcha() {
      const validate = this.validateRegisterForm()
      if(validate) this.$refs.recaptcha.execute()
    },
    onCaptchaExpired() {
      this.registerLoader = false
      this.$refs.recaptcha.reset()
    },

    async createAccount(recaptchaToken) {
      this.registerLoader = true
      this.account.captcha_code = recaptchaToken
      this.$refs.recaptcha.reset()
      const response = await this.$http
          .post(registerURL, {...this.account, ip: this.ipv4})
          .then((response) => {
            this.registerLoader = false
            if (response.data.status) {
              this.$store.commit(
                  authenticationTypes.SET_JWT_TOKEN,
                  response.data.token
              )
              this.$store.commit(
                  authenticationTypes.SET_LOGGED_USER,
                  response.data.logged_user
              )
              this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, true)

            } else {
              this.alertMessage(response.data.message, 'error')
            }
            return response
          })
          .catch((response) => {})
      if (response.data.status) {
        await this.fetchProfile()
        // await this.identify()
        await this.Plan()

        await this.trackSignUpEvent()
        // this.$store.dispatch('trackEvent', { event: 'signed_up' })

        if (response.data.user && response.data.user.email_verify === false) {
          await this.$router.push({ name: 'email_verification' })
        } else {
          this.trackUserMaven('email_verified',{
            auto: true
          })
          gtag('event', 'account_sign_up', {
              'send_to': process.env.VUE_APP_GTAG_SEND_TO,
              'email' : this.$store.getters?.getProfile?.email,
            });
          // this.fetchWorkspaces()
          await this.$router.push({ name: 'onboardingWorkspace' })
        }
      }
    },
    /**
     * Validate the register form
     */
    validateRegisterForm() {
      const regexp = /^[\p{L} .0-9]+$/u

      if (!this.account.password) {
        this.alertMessage('Please enter a password', 'error')
        return false
      }

      if (this.password_state === 'danger') {
        this.alertMessage(this.password_strength_message, 'error')
        return false
      }


      if (!this.account.business_name?.length) {
        this.alertMessage('Please enter a workspace name', 'error')
        return
      }

      const trimmedBusinessName = this.account.business_name?.trim()
      if (trimmedBusinessName.length > 35) {
        this.alertMessage('Workspace name should not be greater than 35 characters', 'error')
        return
      }


      if (!regexp.test(trimmedBusinessName)) {
        this.alertMessage('Workspace name should be letters and numbers only (Special characters are not allowed)', 'error')
        return
      }

      return true
    }
  },
}
</script>
