/**
 *  This file is composable conversion of analyticsUtilsMixin (src/modules/analytics/components/common/analyticsUtilsMixin.js)
 *  You can move required methods from platformMixin to this file and use it in your components
 */
import { useStore } from '@state/base'
import useString from '@/src/modules/common/composables/useString'
import usePlatform from '@/src/modules/common/composables/usePlatform'
import userHelper from '@/src/modules/common/composables/useHelper'

export default function useAnalyticsUtils() {
  const { getters } = useStore()
  const { capitalizeFirstLetter } = useString()
  const { isIGPersonalAccount } = usePlatform()
  const { getResizedImageURL } = userHelper()

  /**
   * @name selectAllAccounts
   * @returns {Array} List of all accounts from all platforms
   * @description Get all accounts from all platforms
   * @example selectAllAccounts()
   */
  function selectAllAccounts(ignorePlatforms = []) {
    const platforms = [
      'facebook',
      'instagram',
      'linkedin',
      'tiktok',
      'twitter',
      'youtube',
      'pinterest',
    ]
    let accountsList = []
    for (const platform in platforms) {
      if (!ignorePlatforms.includes(platforms[platform])) {
        accountsList = accountsList.concat(
          getPlatformAccounts(platforms[platform])
        )
      }
    }
    return accountsList
  }

  /**
   * @name getPlatformAccounts
   * @param {String} platform
   * @returns {Array} List of accounts from a specific platform
   * @description Get accounts list from a specific platform
   * @example getPlatformAccounts('facebook')
   */
  function getPlatformAccounts(platform) {
    const accountsList = []
    switch (platform) {
      case 'facebook':
        getters.getFacebookPagesList.forEach((item) => {
          item.account_type = 'facebook'
          accountsList.push(item)
        })
        break
      case 'twitter':
        getters.getTwitterAccounts.items.forEach((item) => {
          if (item?.developer_app?.analytics_enabled) {
            item.account_type = 'twitter'
            accountsList.push(item)
          }
        })
        break
      case 'linkedin':
        getters.getLinkedinAccounts.items.forEach((item) => {
          item.account_type = 'linkedin'
          if (typeof item.linkedin_id !== 'string') {
            accountsList.push(item)
          }
        })
        break
      case 'instagram':
        getters.getInstagramAccounts.items.forEach((item) => {
          item.account_type = 'instagram'
          accountsList.push(item)
        })
        break
      case 'pinterest':
        getters.getPinterestBoards.forEach((item) => {
          item.account_type = 'pinterest'
          accountsList.push(item)
        })
        break
      case 'tiktok':
        getters.getTiktokAccounts.items.forEach((item) => {
          item.account_type = 'tiktok'
          accountsList.push(item)
        })
        break
      case 'youtube':
        getters.getYoutubeAccounts?.items?.forEach((item) => {
          item.account_type = 'youtube'
          accountsList.push(item)
        })
        break
      case 'gmb':
        getters.getGmbAccounts.items.forEach((item) => {
          item.account_type = 'gmb'
          accountsList.push(item)
        })
        break
      case 'tumblr':
        getters.getTumblrAccounts.items.forEach((item) => {
          item.account_type = 'tumblr'
          accountsList.push(item)
        })
        break
    }

    return accountsList
  }
  /***
   * @name getProfileType
   * @param {String} accountType
   * @param {Object} account
   * @returns {String} Profile type of the account
   * @description Get profile type of the account based on account type
   */
  function getProfileType(accountType, account = null) {
    switch (accountType) {
      case 'instagram':
        return isIGPersonalAccount(account) ? 'Personal' : 'Business'
      case 'facebook':
        return 'Page'
      case 'pinterest':
        return account?.type || 'Unknown Type'
      case 'linkedin':
        return account ? account.type : 'Page'
      case 'tiktok':
        return account?.type ? capitalizeFirstLetter(account.type) : 'Page'
      case 'youtube':
        return account?.type ? capitalizeFirstLetter(account.type) : 'Page'
      case 'tumblr':
        return account ? account.type : 'Page'
      default:
        return 'Page'
    }
  }
  /**
   * @name getAccountId
   * @param {Object} account
   * @returns {String} Account ID
   * @description Get account ID based on account type
   *
   */
  function getAccountId(account) {
    switch (account.account_type) {
      case 'facebook':
        return account.facebook_id
      case 'twitter':
        return account.twitter_id
      case 'instagram':
        return account.instagram_id
      case 'pinterest':
        return account.board_id
      case 'linkedin':
        return account.linkedin_id?.toString()
      case 'tiktok':
        return account.platform_identifier
      case 'youtube':
        return account.platform_identifier
    }
  }
  /**
   * @name getAccountIdKey
   * @param {String} platformType - Platform type
   * @returns {String} Account ID key
   * @description Get account ID key based on platform type
   *
   */
  function getAccountIdKey(platformType) {
    switch (platformType) {
      case 'facebook':
        return 'facebook_id'
      case 'twitter':
        return 'twitter_id'
      case 'instagram':
          return 'instagram_id'
        case 'pinterest':
          return 'board_id'
        case 'linkedin':
          return 'linkedin_id'
        case 'tiktok':
          return 'platform_identifier'
        case 'youtube':
          return 'platform_identifier'
      }
    }

  /**
   * @name getAccountById
   * @param {String} accountId
   * @returns {Object} Account details
   * @description Get account details based on account ID
   *
   */

  function getAccountById (accountId) {
    const allAccounts = selectAllAccounts()
    return allAccounts.find((account) => getAccountId(account) === accountId)
  }

  /**
   * Retrieves the appropriate image URL for a given social media post based on the network.
   *
   * @param {string} network - The social media network (e.g., 'instagram', 'facebook', 'linkedin', 'twitter', 'pinterest', 'tiktok', 'youtube').
   * @param {Object} post - The post object containing media information.
   * @returns {string} - The URL of the image to be displayed for the post.
   */
  function getPostImage(network, post) {
    switch (network) {
      case 'instagram':
        if (!post.media_url?.length && post.permalink) {
          return getResizedImageURL(
            getMediaLink(post.permalink),
            570,
            0
          )
        }
        return post.media_url[post.media_url.length - 1]
      case 'facebook':
        return post.full_picture
      case 'linkedin':
        return post.image
      case 'twitter':
        return post.media_url
      case 'pinterest':
        return post.image
      case 'tiktok':
        return post.cover_image_url
      case 'youtube':
        return post.thumbnail_url
    }
  }

  /**
     * @description creates a thumbnail for every media link. (was required in case of videos and reels.)
     * @param url
     * @returns {string}
     */
  function getMediaLink(url) {
    switch (true) {
      case url.includes('/tv/'):
        return `${url.replace('/tv/', '/p/')}media?size=l`
      case url.includes('/reel/'):
        return `${url.replace('/reel/', '/p/')}media?size=l`
      default:
        return `${url}media?size=l`
    }
  }

  return {
    selectAllAccounts,
    getPlatformAccounts,
    getProfileType,
    getAccountId,
    getAccountById,
    getAccountIdKey,
    getPostImage,
    getMediaLink,
  }
}
