export const AiChatPrompts = [
  {
    title: 'Full blog generator',
    prompts: [
      'Compose a blog article centered around [your chosen topic], ensuring that you emphasize [essential aspects].',
      'Write a blog about [your topic], includes [keywords]. ' +
        'Voice tone [voice tone e.g. educational, formal etc.]. ' +
        'Word count [count]',
      'Assume the role of a [specific role, e.g. blogger, content creator, etc.] and compose an in-depth blog post on the subject of [your chosen topic]. Be sure to concentrate on [essential aspects].',
      'Assume the role of a [specify role, e.g. blogger, content creator, etc.] and create a comprehensive blog post on the subject of [your chosen topic]. Be sure to concentrate on [key points].',
      'Write an engaging and informative blog post about the topic [your topic], including [key points]. Make sure the content is well-structured with relevant subheadings, bullet points, and actionable advice for readers. ' +
        'Additionally, include relevant statistics, anecdotes, and examples to make the blog post enjoyable and easy to understand.',
    ],
  },
  {
    title: 'Blog ideas and titles',
    prompts: [
      'Generate 5 attention-grabbing headlines for an article about [your topic].',
      'Brainstorm blog title ideas about [your topic] that engage readers and rank well on goggle.',
      'Suggest SEO-friendly blog title ideas on the topics about [your topic]. Use an emotional and persuasive tone in post titles.',
      'Write a catchy headline for a blog post about [your topic/theme]',
      'Provide 5 blog topic titles about [your topic]',
    ],
  },
  {
    title: 'Blog introduction',
    prompts: [
      'Write compelling blog introduction about [your topic]',
      'Write an introduction for an article about [your topic]',
      'Write a clear and concise introduction for a blog post [your topic]',
      'Craft a brief and engaging introductory paragraph for a blog post centered on [your topic]',
      'Write compelling blog introduction.,',
      'Write a clear and concise introduction of the given topic.',
      'Craft a brief and engaging introductory paragraph of the given topic.',
      'Write an engaging and captivating introductory paragraph for a blog post on the given topic.',
      'Compose an insightful introductory paragraph for a blog post on the provided theme, emphasizing the significance and impact of the subject matter.',
      'Craft a compelling introductory paragraph for a blog post on the given topic, highlighting the main challenges and opportunities in the field, while avoiding any brand promotions, contentious claims, or overly technical details.',
    ],
  },
  {
    title: 'Blog outline',
    prompts: [
      'Generate blog outline from a given topic',
      'Create lists and outlines for articles. Works best for listicle and how to style blog posts or articles',
      'Generate headings and subheadings for a blog post [your topic]',
      'Kindly create a comprehensive and engaging outline for the blog post on the given topic [Your topic here]',
      'I need your assistance in developing a well-structured and captivating outline for my blog post about [Your topic here]',
      'Please help me create an appealing and informative outline for my upcoming blog post on the topic [Your topic here]',
      'Could you provide me with an organized and reader-friendly outline for my blog post focusing on [Your topic here]',
      'Generate a good blog outline for a post about [your topic]',
      'Provide an appealing blog outline for [your title]',
    ],
  },
  {
    title: 'Blog section completer',
    prompts: [
      'Elaborate on the importance of the outline point in relation to main blog topic.\n' +
        '[Outline point]',
      'Discuss the benefits and challenges of the outline point within the scope of main blog topic.\n' +
        '[Outline point]',
      'Explain the role of the outline point in achieving success with main blog topic.\n' +
        '[Outline point]',
      'Provide a comprehensive analysis of the outline point and its impact on main blog topic.\n' +
        '[Outline point]',
      'Describe the best practices and strategies for implementing the outline point in the context of main blog topic.\n' +
        '[Outline point]',
      'Please elaborate on the provided outline points in the context of main blog topic, creating a detailed and informative section for each point individually:\n' +
        '[Outline point 1]\n' +
        '[Outline point 2]\n' +
        '[Outline point 3]\n' +
        '[Outline point 4]\n' +
        'Cover the significance, benefits, challenges, and best practices related to each point',
    ],
  },
  {
    title: 'Blog conclusion paragraph',
    prompts: [
      'Wrap up your blog post',
      'Wrap up your blog post with an engaging conclusion paragraph.',
      'Write a clear and concise conclusion for the blog post.',
      'Create a clear and easy-to-understand closing paragraph that goes over the main points of the blog post.',
      'Craft a compelling closing paragraph that summarizes the key takeaways of the blog post.',
    ],
  },
  {
    title: 'Social media post caption',
    prompts: [
      'Act as a professional social media content creator, to write engaging and attractive social media post caption related to the topic of [your topic].',
      'Act as a professional social media content creator, to write engaging and attractive social media post caption related to the topic of [your topic]. Please [do / do not] generate hashtag in the caption. ',
      'Act as a professional social media content creator, to write engaging and attractive social media post caption related to the topic of [your topic].\n' +
        '\n' +
        '      Please [do / do not] generate hashtag in the caption. \n' +
        '\n' +
        '      Please [do / do not] generate emojis in the caption.\n' +
        '\n' +
        '      Use voice tone [voice tone e.g. educational, formal etc.]\n' +
        '\n' +
        '      Word count [count]',
      'Craft an engaging social media post that digs deeper into the provided idea:\n' +
        '[Your idea]',
      'Compose an informative social media post highlighting these fascinating facts:\n' +
        '[Facts]',
      'Construct a thought-provoking social media post discussing the provided argument, and include counter-arguments:\n' +
        '[Argument]',
      'Formulate a compelling social media post emphasizing the significance of [concept/idea].',
      'Develop an insightful social media post exploring various strategies to tackle [problem].',
      'Curate an inspiring social media post about the provided quote and its connection to [topic]:\n' +
        '[Quote]',
      'Produce a persuasive social media post addressing the provided statement, backed by evidence:\n' +
        '[Statement]',
      'Identify and discuss the factors that contribute to [problem/success] in a comprehensive social media post.',
      'Examine the role and impact of [profession/industry] on society in a compelling social media post.',
    ],
  },
  {
    title: 'Tweet ideas',
    prompts: [
      'Act as a professional social media content creator, to help writing engaging tweet related to the topic of [your topic]',
      'Act as a professional social media content creator, to help writing engaging tweet related to the topic of [your topic]\n' +
        'Please [do / do not] generate hashtag in the caption. \n' +
        'Please [do / do not] generate emojis in the caption.\n' +
        'Use voice tone [voice tone e.g. educational, formal etc.] \n' +
        'Word count [count]',
      'Craft a X (Twitter) thread about [Your topic]',
      'Generate a humorous tweet.',
      'Create 10 tweet ideas on [Your topic]',
      'Convert the provided content into a tweet \n' + '[Insert content]',
      "Create a tweet demonstrating your [product/service] solving your [ideal customer persona]'s pain points in an engaging way.",
      'Generate a X (Twitter) thread comparing your [product/service] to market alternatives, convincing [ideal customer persona] to choose you with compelling evidence.',
      'Craft a tweet to share a personal story or experience about [topic].',
      'Create a tweet to encourage followers to share thoughts by asking a question related to [topic].',
      'Design a X (Twitter) thread idea sharing a unique and relatable story about how your [product/service] helped [ideal customer persona] achieve their [goal].',
      'Develop a viral X (Twitter) thread idea that captivates high-quality leads for your [product/service] using an impactful call-to-action and engaging visuals.',
      'Craft a tweet offering a valuable tip or advice related to [topic].',
      'Compose a tweet presenting a hypothetical scenario linked to [topic] and ask followers for their thoughts on handling the situation.',
      'Share a tweet discussing a personal goal or challenge associated with [topic] while motivating followers to share their experiences.',
      'Write a tweet conveying a valuable lesson learned from a mistake or setback in the context of [topic].',
      'Share a tweet reflecting on advice or wisdom related to [topic] that you wish you had discovered earlier in life.',
      'Formulate a tweet expressing your personal stance on a trending topic or ongoing debate concerning [topic].',
      'Craft a tweet narrating a personal story of growth or transformation linked to [topic] and inspire followers to share their own journey of self-improvement.',
    ],
  },
  {
    title: 'Summarize',
    prompts: [
      'Act as an assistant to read and understand the given text and help summarizing the key points.\n' +
        '[Insert text]',
      'Act as an assistant to read and understand the given text and help summarizing the key points.\n' +
        '[Insert text]\n' +
        'Please [do / do not] generate hashtag in the caption. \n' +
        'Please [do / do not] generate emojis in the caption.\n' +
        'Use voice tone [voice tone e.g. educational, formal etc.] \n' +
        'Word count [count]',
      'Act as an assistant to extract the content from the provided URL, read and understand the content and help summarizing it.\n' +
        '[Insert Link]',
      'Act as an assistant to extract the content from the provided URL, read and understand the content and help summarizing it.\n' +
        '[Insert Link]\n' +
        'Please [do / do not] generate hashtag in the caption. \n' +
        'Please [do / do not] generate emojis in the caption.\n' +
        'Use voice tone [voice tone e.g. educational, formal etc.] \n' +
        'Word count [count]',
      'Summarize the given text\n' + '[Insert text]',
      'Turn this content into a summarized social media post.',
      'Make this paragraph easy to understand.',
      'Give a one-sentence summary of this paragraph.',
      'State the main points of this content.',
      'Craft a social media post that conveys the provided message.\n' +
        '[Provide message]',
      'Shorten this text while keeping its main ideas.',
      'Provide a quick summary of this text.',
      'Simplify this sentence.',
      'Rewrite this sentence in easy-to-understand words.',
      'List the steps involved in this process.',
      'Sum up this research study in three main points.',
      'Turn this technical process into simple, step-by-step instructions.',
      'Change this text into a practical tip.',
      'Turn this text into an attention-grabbing phrase.',
      'Summarize the text below and give me a list of bullet points with key insights and the most important facts\n' +
        '[Insert text]',
    ],
  },
  {
    title: 'Instagram caption',
    prompts: [
      'Generate scroll-stopping engaging caption to be published on Instagram about the topic of [your topic]',
      'Generate scroll-stopping engaging caption to be published on Instagram about the topic of [your topic]\n' +
        'Please [do / do not] generate hashtag in the caption. \n' +
        'Please [do / do not] generate emojis in the caption.\n' +
        'Use voice tone [voice tone e.g. educational, formal etc.] \n' +
        'Word count [count]',
      'Write a catchy Instagram caption about [your theme] and try to play with words to make it fun and engage users in the end.',
      'Compose a witty Instagram caption for [topic].',
      'Provide Instagram captions for an image of [photo description].',
      'Create 10 engaging Instagram Reel ideas on [topic].',
      'Narrate a story or personal experience related to [topic] for an Instagram post.',
      'Share useful tips, hacks, or advice on [topic] via Instagram.',
      'Post an inspiring quote or message related to [topic] on Instagram.',
      'Design an interactive Instagram poll or quiz about [topic].',
      'Invite followers to share their thoughts or experiences in the comments on [topic].',
      "Attract my [ideal customer persona] with a captivating Instagram post showcasing my [product/service]'s unique features and benefits.",
      'Provide clear, step-by-step instructions for using my [product/service] in an Instagram post that encourages my [ideal customer persona] to make a purchase.',
      'Convince my [ideal customer persona] to select my [product/service] over competitors with a persuasive Instagram post featuring evidence.',
      "Build trust and credibility with my [ideal customer persona] using an Instagram post emphasizing my [company/brand]'s expertise and professionalism.",
      'Motivate my [ideal customer persona] to share positive experiences with my [product/service] by highlighting them creatively in an Instagram post. ',
    ],
  },
  {
    title: 'Inspirational',
    prompts: [
      'Act as an inspiration and help write an eye-catching quote about [your topic].',
      'Craft a heartwarming social media message to lift up [target audience].',
      'Share an uplifting quote on [topic] for some #MondayMotivation.',
      "Tell a powerful success story on [topic] that'll inspire others.",
      'Share the secret to conquering [challenge] in an uplifting social media post.',
      'Spread some motivation with an empowering message for your followers.',
      'Share the inspiring tale of someone who overcame hardship and achieved greatness.',
      'Encourage risk-taking and leaving your comfort zone with an inspiring post.',
      'Remind your followers with a heartfelt post to keep chasing their dreams.',
      'Share top tips on staying upbeat and focused during tough times.',
      'Celebrate the incredible power of grit and determination in reaching goals.',
      'Reveal your own story of triumph over adversity and how it made you stronger.',
      'Highlight the importance of self-love in chasing your dreams with a thoughtful post.',
      'Spread positivity with a post on the benefits of a growth mindset.',
      'Showcase the power of community and how it can help uplift one another.',
      'Share an inspiring story of someone who left a lasting, positive impact on the world.',
      'Praise the value of hard work and persistence.',
    ],
  },
  {
    title: 'Rewrite',
    prompts: [
      'Act as a content writing assistant to help rewriting the given content.\n\n[Insert text]',
      'Enhance the provided content:\n\n[Provide content]',
      'Reframe the provided content from the perspective of a [individual/profession]:\n\n[Provide content]',
      'Revise the provided content in a [voice tone e.g. Professional, formal, hopeful etc.] voice tone:\n\n[Provide content]',
      'Rewrite the text below and make it easy for a beginner to understand.\n\n[Insert text]',
      'Revamp the provided paragraph for enhanced readability:\n\n[Provide content]',
      'Elevate the provided paragraph with sophisticated vocabulary:\n\n[Provide content]',
      'Transform the provided list into a paragraph:\n\n[Provide content]',
      'Convert the provided paragraph into a list:\n\n[Provide content]',
      'Reconstruct the provided sentence using an alternative grammatical structure:\n\n[Provide content]',
      'Adapt the provided paragraph to fit a [genre e.g. poetic, journalistic or academic] style:\n\n[Provide content]',
      'Reframe the provided paragraph using active voice:\n\n[Provide content]',
      'Enrich the provided paragraph with additional sensory details:\n\n[Provide content]',
      'Revise the provided sentence to remove redundancy:\n\n[Provide content]',
      'Condense the provided paragraph using shorter sentences:\n\n[Provide content]',
      'Alter the focus of the provided paragraph to emphasize a different point:\n\n[Provide content]',
      'Tailor the provided paragraph to address [audience]:\n\n[Provide content]',
      'Refine the provided paragraph with more precise language:\n\n[Provide content]',
      'Shift the viewpoint of the provided paragraph to highlight a different perspective:\n\n[Provide content]',
      'Rewrite the content to be more formal\n\n[Give text]',
    ],
  },
  {
    title: 'Improve',
    prompts: [
      'Improve your content to look interesting and engaging\n\n[Insert text]',
      'Enhance the content by focusing on [adjective]\n\n[Provide content]',
      'Refine the content with an emphasis on [angle]\n\n[Provide content]',
      'Renew the social media post to give it a fresh look\n\n[Provide content]',
      'Boost engagement in the social media post by adding a question or poll\n\n[Provide content]',
      'Optimize the social media post to better appeal to [specific audience]',
      'Increase the visibility of the social media post by including relevant hashtags',
      'Incorporate current trends or events into the content',
      'Enhance the social media post with emojis',
      'Capture attention by using a bold statement in the text',
      'Concisely convey the content without losing its essence\n\n[Provide content]',
      'Modify the text formatting for enhanced readability\n\n[Provide content]',
      'Eliminate any irrelevant information from the content',
      'Restructure the social media post to fit within [character limit]',
    ],
  },
  {
    title: 'Extend/Expand',
    prompts: [
      'Act as a content writing assistant to understand the context of the given content, continue writing and expand the content.\n\n[Provide content]',
      'Expand your content into longer sentences\n\n[Provide content]',
      'Act as a content writing assistant to understand the context of the given content, continue writing and expand the content.\n\n[Provide content]\n\nPlease [do / do not] generate hashtag in the caption.\n\nPlease [do / do not] generate emojis in the caption.\n\nUse voice tone [voice tone e.g. educational, formal etc.]\n\nWord count [count]',
      'Write the next sentence.',
      'Complete the provided sentence.\n\n[Your sentence]',
      'Give a detailed breakdown of [topic].',
      'Tell me more about [topic] with a focus on [angle/subtopic].',
      'Tell me about the history of [topic].',
      'What are the factors that contribute to [problem/success]',
      'Expand the provided quote and how it relates to [topic].\n\n[Enter quote]',
      'Expand the provided statement and provide evidence to support it:\n\n[Provide statement]',
      'Expand the provided statistic and what it tells us about [topic].\n\n[Provide statistics]',
      'Expand the provided misconception and provide clarifications.\n\n[Provide statement]',
    ],
  },
  {
    title: 'Continue writing',
    prompts: [
      'Continue with the given text, adding more depth and detail.',
      'Help me out by continuing this paragraph for me.',
      'Can you write the next part for me?',
      'Can you suggest some possible twists to add to this story?',
      "I can't think of what to write next, please assist me by continuing from here.",
      'Share some ideas to develop this story further.',
      'Can you take the lead and write the next segment for me?',
      'Help me expand on this idea and continue the text.',
      'Please continue the story, but maintain a conversational tone in the text.',
      'Could you continue the story using short and simple sentences?',
    ],
  },
  {
    title: 'Facebook',
    prompts: [
      'Create a Facebook status emphasizing the benefits of [topic].',
      'Compose a Facebook post inviting followers to share their views on [topic].',
      'Generate a thought-provoking post that initiates a conversation about [topic].',
      'Share a humorous or relatable meme about [topic].',
      'Compose a Facebook post on [topic].',
      'Craft an inspiring story highlighting the life improvements brought by [product/service].',
      'Devise a Facebook poll or quiz that prompts critical thinking on [topic].',
      "Create a Facebook quiz to test your followers' knowledge of [topic].",
      'Formulate a post requesting constructive feedback on [company/project].',
      'Develop a Facebook post motivating followers to act on [issue].',
      'Propose an engaging question for my Facebook Group about [topic].',
      'Share a life-changing personal experience related to [topic].',
      'Transform the provided content into an attention-grabbing Facebook post.',
      'Design a Facebook status exploring the social impact of [product/service].',
      'Create a Facebook status informing the audience about the significance of [topic].',
      'Develop question ideas for a Facebook poll on [topic].',
      "Design a Facebook post showcasing [product/service]'s unique features.",
    ],
  },
  {
    title: 'LinkedIn',
    prompts: [
      'Create a LinkedIn post promoting a blog post on [topic].',
      'Generate a promotional LinkedIn post highlighting the benefits of using [product/service] to achieve [action].',
      'Create a LinkedIn quiz about [topic].',
      'Promote a webinar or virtual event about [topic] in a LinkedIn post.',
      'Promote a podcast episode or interview about [topic] in a LinkedIn post.',
      'Describe the role of technology in shaping and improving your field in a LinkedIn post.',
      'Create a LinkedIn poll about common challenges in [industry/profession].',
      'Write a LinkedIn post about optimizing your workspace for increased efficiency and productivity.',
      'Write a LinkedIn post discussing the benefits of pursuing additional certifications and education within your field.',
      'Discuss the value of networking and cultivating professional relationships in a LinkedIn post.',
      'Emphasize the benefits of continuous learning and professional development in a LinkedIn post.',
      'Highlight the importance of establishing a strong personal brand in a LinkedIn post.',
      'Share the significance of staying current with industry trends and news in a LinkedIn post.',
      'Compose a LinkedIn post about setting career goals and creating a plan to reach them.',
      'Discuss the power of mentors and seeking professional guidance in a LinkedIn post.',
      'Craft a LinkedIn post about building a diverse professional network.',
      'Share the importance of work-life balance and self-care in a LinkedIn post.',
    ],
  },
  {
    title: 'Pinterest',
    prompts: [
      'Write a Pinterest description for an image about [topic].',
      'Create a Pinterest description for a [product/service] that leads people to [website].',
      'Generate 5 pin title ideas for a post about [topic/product/service].',
      'Write a promotional Pinterest post for a [product/service] that gets people to [action].',
      'Write an inspirational Pinterest post about [topic].',
      'Create a Pinterest post talking about [topic] tips and tricks.',
      'Share a collection of inspiring quotes related to [topic].',
      'Write a Pinterest post featuring a list of fun and creative ways to use [product/service].',
      'Create a Pinterest post featuring a list of the top 10 trends related to [topic/product/service] and how to incorporate them into daily life.',
      'Write a Pinterest post featuring a list of surprising facts about [product/service].',
    ],
  },
  {
    title: 'GMB',
    prompts: [
      'Craft a compelling post promoting a limited-time offer or sale for [product/service] to increase customer engagement and drive sales.',
      'Disclose an exciting [discount/offer] in a public post.',
      'Write an eye-catching post highlighting positive customer reviews, showcasing the trust and satisfaction of our clientele.',
      'Write a post introducing a new product or service, emphasizing its unique features and benefits to pique customer interest.',
      'Announce [event/product launch/company news] in a captivating post.',
      'Promote [partnership/collaboration] in a share-worthy post.',
      'Create an informative post sharing upcoming events, workshops, or webinars hosted by our business to educate and engage with the local community.',
      'Create an engaging seasonal or holiday-themed post, celebrating special occasions and offering exclusive deals or discounts.',
    ],
  },
  {
    title: 'Shorten',
    prompts: [
      'Shorten the provided text while retaining its key information\n\n[Give text]',
      'Summarize the given text into a concise and easy-to-understand version.\n\n[Give text]',
      'Create a shorter version of the provided content, capturing its key points.\n\n[Give text]',
      'Condense the given text while maintaining its core message and meaning.\n\n[Give text]',
      'Transform the provided content into a brief and engaging summary.\n\n[Give text]',
      'Shorten the given text, highlighting its most important ideas and information.\n\n[Give text]',
    ],
  },
  {
    title: 'Paragraph',
    prompts: [
      'Elaborate on the given text in a well-structured paragraph.\n\n[Give text]',
      'Craft a captivating paragraph about the given topic while incorporating the latest trends and insights.\n\n[Topic/statement]',
      'Write an informative and engaging paragraph that clearly explains the main aspects of [topic/statement].',
      'Develop a persuasive paragraph that highlights the significance and benefits of [topic/statement].',
      'Create an imaginative paragraph that explores the potential future developments or implications of [topic/statement].',
      'Compose a thought-provoking paragraph that stimulates a meaningful discussion around [topic/statement].',
    ],
  },
  {
    title: 'Pros and Cons List',
    prompts: [
      'Act as an analyst to help write the list of pros and cons about the topic of [your topic].',
      'Act as an analyst to help write the list of pros and cons about the topic of [your topic].\n\nPlease [do / do not] generate hashtags in the caption.\n\nPlease [do / do not] generate emojis in the caption.\n\nUse voice tone [voice tone e.g. Professional, formal, hopeful etc.]\n\nWord count [count]',
      'Provide a balanced list of pros and cons for the given topic/product/service.\n\n[Topic/product/service]',
      'Can you share the positive and negative aspects of the given topic? Please give a list of the top pros and cons.\n\n[Topic]',
      'Help me decide by giving a clear list of the advantages and disadvantages of [topic].',
      'List the top 5 benefits and drawbacks of the given topic to better understand its impact.\n\n[Topic]',
      'Offer insights into the pros and cons of the given topic to aid in making an informed decision.\n\n[Topic]',
    ],
  },
]

export const AiChatQuickPrompts = {
  text: [
    'Continue Writing',
    'Summarize',
    'Shorten',
    'Rewrite',
    'Expand',
    'Improve',
    'Make More Formal',
    'Make More Friendly',
  ],
  image: [
    'Extract Text',
    'Summarize',
    'Generate Caption',
    'Describe',
  ],
}
