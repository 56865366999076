import { mapGetters } from 'vuex'
import {
  WORKSPACE_ROLES,
  WORKSPACE_MEMBER_TYPES,
} from '@common/constants/composer'

export const permissionMixin = {
  computed: {
    ...mapGetters(['getActiveWorkspace', 'getProfile', 'getWorkspaces']),

    /**
     * This method will return the complete object of the logged user.
     * @returns {*}
     */
    getLoggedUser() {
      const workspace = this.getActiveWorkspace
      const user = this.getProfile._id
      let member = null
      // finding member from states
      if (user && workspace._id && workspace.members)
        member = workspace.members.find((item) => {
          return item.user_id === user
        })
      return member
    },

    /**
     * This method will return the role of the logged user.
     * @returns {*|string}
     */
    getUserRole() {
      const user = this.getLoggedUser
      return user ? this.getMutatedUserRole(user.role) : ''
    },

    /**
     * This method will return the class base on the role of the logged user.
     * @returns {string}
     */
    getUserRoleClass() {
      const user = this.getLoggedUser
      return user ? this.getRoleClass(user.role) : ''
    },
    getTeamMembership() {
      const user = this.getLoggedUser
      if (user) {
        if (user.role === 'super_admin') return 'team'
        if (user.membership) return user.membership
      }
      return 'team'
    },
    isEnableGlobalCategoryOption() {
      console.log('isEnableGlobalCategoryOption', this.getLoggedUser)
      if (this.getLoggedUser?.role) {
        return (
          this.getLoggedUser.role === WORKSPACE_ROLES.SUPER_ADMIN ||
          (this.getLoggedUser.role === WORKSPACE_ROLES.ADMIN &&
            this.getLoggedUser.membership === WORKSPACE_MEMBER_TYPES.TEAM)
        )
      }
      return false
    },
  },
  methods: {
    /**
     * This role mutate the user role e.g super_admin to Super Admin
     * @param role
     * @returns {string}
     */
    getMutatedUserRole(role) {
      if (role === WORKSPACE_ROLES.SUPER_ADMIN) return 'Super Admin'
      if (role === WORKSPACE_ROLES.ADMIN) return 'Admin'
      if (role === WORKSPACE_ROLES.COLLABORATOR) return 'Collaborator'
      if (role === WORKSPACE_ROLES.APPROVER) return 'Approver'
      return ''
    },

    /**
     * This method will return the class base on the role
     * @param role
     * @returns {string}
     */
    getRoleClass(role) {
      if (role === WORKSPACE_ROLES.SUPER_ADMIN) return 'text-orange-500'
      if (role === WORKSPACE_ROLES.ADMIN) return 'text-blue-500'
      if (role === WORKSPACE_ROLES.COLLABORATOR) return 'text-purple-500'
      if (role === WORKSPACE_ROLES.APPROVER) return 'text-green-500'
      return ''
    },

    /**
     * Check whether member has the permission of specific action or not.
     * @param action
     * @param owner optional
     * @returns {boolean}
     */
    hasPermission(action, owner = false) {
      const user = this.getLoggedUser

      if (!user && action === 'can_save_workspace') return true
      if (user) {
        if (user.role === 'super_admin') return true
        if (user.role === 'approver') {
          switch (action) {
            case 'can_schedule_plan':
              return true
          }
          return false
        }
        if (user.role === 'admin') {
          switch (action) {
            case 'can_see_subscription':
              return user.permissions && user.permissions.hasBillingAccess
            case 'can_see_development':
              return false
          }
          return true
        }
        // if owner then allowed
        if (this.getProfile._id === owner) return true
        if (user.role === 'collaborator') {
          switch (action) {
            case 'can_save_blog':
              return !!(user.permissions && user.permissions.addBlog)
            case 'can_save_social':
              return !!(user.permissions && user.permissions.addSocial)
            case 'can_save_source':
              return !!(user.permissions && user.permissions.addSource)
            case 'can_save_topic':
              return !!(user.permissions && user.permissions.addTopic)
            case 'can_view_team':
              return !!(user.permissions && user.permissions.viewTeam)
            case 'can_schedule_plan':
            case 'can_schedule_automation_plan':
              return !(user.permissions && user.permissions.postsReview)
            case 'can_reschedule_plan':
              return !!(user.permissions && user.permissions.rescheduleQueue)
            case 'can_change_fb_group_publishing_method':
              return !(
                user.permissions &&
                // eslint-disable-next-line no-prototype-builtins
                user.permissions.hasOwnProperty('changeFBGroupPublishAs') &&
                user.permissions.changeFBGroupPublishAs === false
              )

            case 'can_access_workspace_setting':
              return true
            case 'can_access_top_header':
              return true
            case 'can_use_bulk_operations':
              return true
            case 'can_use_actions':
              return true
            case 'can_save_workspace':
              return this.getProfile.subscription_name !== 'No Package'
            case 'can_use_email_notification_status':
              return false
          }
        }
      }

      return false
    },

    hasRoutePermission(routeName) {
      const user = this.getLoggedUser
      if (user) {
        if (user.role === WORKSPACE_ROLES.APPROVER) {
          switch (routeName) {
            case 'planner_feed_v2':
              return true
            case 'planner_list_v2':
              return true
            case 'planner_calendar_v2':
              return true
            case 'planner_tiktok_grid_view':
              return true
            case 'planner_instagram_grid_view':
              return true
            case 'feed_view':
              return true
            case 'list_plans':
              return true
            case 'calender_plans':
              return true
            case 'notifications':
              return true
            case 'profile':
              return true
            case 'grid_tiktok':
              return true
            case 'grid_instagram':
              return true
            case 'setPassword':
              return true
            case 'workspaces':
              return true
            default:
              return false
          }
        }
      }
      return true
    },
    canViewUpgradeOffer() {
      console.log('method: canViewUpgradeOffer')
      const user = this.getLoggedUser
      return !!(user && user.role.includes(WORKSPACE_ROLES.SUPER_ADMIN))
    },
  },
}
