<template>
  <div
class="cursor-pointer flex-shrink-0"
       @click.stop="() => displayFile()"
  >
    <img
class="rounded pointer-events-none object-cover mr-3.5"
         :class="isCompactView ? 'w-20 h-14' : (onGridView ? 'w-[6rem] h-[5.5rem]' : 'w-32 h-24')"
         :src="getResizedImageURL(getPlanAttachment, 150, 0)"
         alt=""
         @error="onErrorHandle($event, getPlanAttachment)">
  </div>
</template>

<script>

// import {getPlanMedia, planHeadAttachment} from "@common/lib/planner";
import {EventBus} from "@common/lib/event-bus";

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isCompactView: {
      type: Boolean,
      default: false
    },
    onGridView: {
      type: Boolean,
      default: false
    },
    isTiktok: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    getPlanAttachment () {
      if(this.onGridView){

        // Handling the preview of the media sidebar post
        if(this.getGridViewAttachment?.multimedia?.length) {
          return typeof this.getGridViewAttachment.multimedia[0] === 'string' ? this.getGridViewAttachment.multimedia[0] : this.getGridViewAttachment.multimedia[0]?.thumbnail
        }

        return this.getGridViewAttachment?.video?.thumbnail
      }
      if(this.item?.image){
        return this.item.image
      }
      // const attachment = planHeadAttachment(this.item)
      // if (attachment.url) {
      //   return attachment.type === 'image'
      //       ? attachment.url
      //       : attachment.thumbnail
      // }
      return false
    },
    getGridViewAttachment () {
      if(!this.onGridView) return false
       return this.item?.common_box_status ? this.item?.common_sharing_details : this.isTiktok ? this.item?.tiktok_sharing_details : this.item?.instagram_sharing_details
    }
  },
  methods: {
    onErrorHandle(event, image) {
      event.target.src = image || require('@src/assets/img/no_data_images/no_media_found.svg');
    },
    displayFile () {
      console.debug('Method::displayFile')
      if(this.onGridView){

        if(this.getGridViewAttachment?.multimedia?.length) {
          EventBus.$emit('displayFile', {
            type: 'multimedia',
            media: this.getGridViewAttachment?.multimedia || [],
            index: 0,
          })
          this.$bvModal.show('display-file-modal')
          return
        }

        EventBus.$emit('displayFile', {
          type: 'video',
          media: this.getGridViewAttachment?.video?.converted_video || this.getGridViewAttachment?.video?.link,
          index: 0
        })
        this.$bvModal.show('display-file-modal')
        return
      }

      if(this.item?.video_link){
        EventBus.$emit('displayFile', {
            type: 'video',
            media: this.item.video_link,
            index: 0
        })
        this.$bvModal.show('display-file-modal')
      }


      // const attachment = getPlanMedia(this.item)
      // if (attachment) {
      //   EventBus.$emit('displayFile', {
      //     type: attachment.type,
      //     media: attachment.media,
      //     index: 0
      //   })
      //   this.$bvModal.show('display-file-modal')
      // }
    }
  }
};
</script>
