<script setup>
import OverlayPost from './OverlayPost'
import useInstagramGridView from '@/src/modules/planner_v2/composables/SocialMediaManager/Instagram/useInstagramGridView'

defineProps({
  item: {
    type: Object,
    required: true,
  },
  isDropping: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
    required: true,
  },
})
defineEmits(['dragover', 'dragstart', 'dragend'])

const {
  getThumbnail,
  getOverlayDetails,
  shouldDisplayIcon,
  isRemoteMedia,
  getStatusIcon,
  getFeedStatus,
  getFormattedDate,
  getFormattedTime,
} = useInstagramGridView()
</script>

<template>
  <div
    class="flex relative col-span-3 h-[8rem] w-full pr-[1px] pt-[1px] cursor-pointer md:h-[9rem] lg:h-[10rem] xl:h-[10.5rem] 2xl:h-[12rem] group"
    draggable="true"
    @dragstart="$emit('dragstart', $event, index)"
    @dragover="$emit('dragover', $event, index)"
    @dragend="$emit('dragend', $event)"
  >
    <OverlayPost
      :details="getOverlayDetails(item)"
      :item="item"
      item-type="reel"
    >
      <div
        v-if="!isRemoteMedia(item)"
        class="backdrop-blur-sm !z-10 inset-0 bg-gray-800/30 rounded flex flex-col items-center justify-center invisible absolute group-hover:!visible pointer-events-none"
      >
        <p
          class="lg:text-sm md:text-xs sm:text-xxs text-center text-white font-semibold text-shadow-lg"
        >
          {{ getFormattedDate(item?.execution_time?.date) }}
        </p>
        <p
          class="lg:text-sm md:text-xs sm:text-xxs text-center text-white font-semibold text-shadow-lg"
        >
          {{ getFormattedTime(item?.execution_time?.date) }}
        </p>
      </div>
      <div v-if="isDropping">
        <div
          class="absolute z-20 inset-0 text-center flex items-center justify-center text-gray-900 text-sm font-semibold"
        >
          Drop to swap posts
        </div>
      </div>
      <img
        v-else-if="isRemoteMedia(item)"
        v-tooltip.top="{
          content: `<p class='text-xs'>Created from <img width='12px' height='12px' src='https://static.cdninstagram.com/rsrc.php/v3/yI/r/VsNE-OHk_8a.png'  alt=''/></p>`,
          allowHTML: true,
          followCursor: true,
        }"
        class="w-full h-full object-cover"
        loading="lazy"
        alt="Post"
        :src="getResizedImageURL(getThumbnail(item), 170, 170)"
        @error="
          $event.target.src = getThumbnail(item) || require('@src/assets/img/no_data_images/no_media_found.svg')
        "
      />
      <img
        v-else
        class="w-full h-full object-cover"
        loading="lazy"
        alt="Post"
        :src="getResizedImageURL(getThumbnail(item), 170, 170)"
        @error="
          $event.target.src = getThumbnail(item) || require('@src/assets/img/no_data_images/no_media_found.svg')
        "
      />
    </OverlayPost>
    <div
      v-if="item?.isDraggable"
      v-tooltip="'Drag to swap posts'"
      draggable="false"
      class="invisible group-hover:!visible absolute top-1.5 right-1 !z-20 drop-shadow"
    >
      <img
        src="@assets/img/icons/planner/grid-view/drag-item.svg"
        alt="Drag Icon"
        class="w-[1.25rem] h-[1.25rem]"
      />
    </div>
    <div
      v-if="shouldDisplayIcon(item)"
      class="flex align-items-center justify-content-center absolute top-1 p-[0.2rem] bg-[#202324] bg-opacity-60 rounded border !border-[#D2D5DF] left-1 z-10"
    >
      <img
        v-tooltip="getFeedStatus(item)"
        :src="getStatusIcon(item)"
        alt="Status Icon"
        class="w-[1rem] h-[1rem]"
      />
    </div>
    <img
      src="@assets/img/common/video-play-outlined.svg"
      alt="Bottom Left Icon"
      class="w-[0.875rem] h-[0.875rem] absolute left-[0.4rem] bottom-[0.6rem] object-cover z-10"
    />
  </div>
</template>
