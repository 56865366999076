<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import AccountSelection from '@src/modules/publish/components/posting/social/AccountSelection'
import AutomationScheduleOptions from '@src/modules/automation/components/ScheduleOptions.vue'
import InfiniteLoading from 'vue-infinite-loading'
import integrations, { getPlatformName } from '@common/lib/integrations'
import { parseDescription } from '@common/lib/helper'
import {
  planHeadText,
  planHeadAttachment,
  itemSelectedPlatformsCount,
  itemSelectedPlatformVisibleStatus,
} from '@common/lib/planner'
import { pusherSocketPublish } from '@common/lib/pusher'
import useDateFormat from '@common/composables/useDateFormat'
import ContentCategorySelection from '@src/modules/publish/components/posting/social/ContentCategorySelection'
import AutoMixin from '@src/modules/automation/components/AutoMixin'
import UploadMediaModal from '@src/modules/publish/components/media-library/components/UploadMediaModal'
import AccountSelectionFirstComment from '@src/modules/publish/components/posting/social/AccountSelectionFirstComment'
import { instagramPostingOption } from '@src/modules/publish/store/states/mutation-types'
import { commonMethods } from '@common/store/common-methods'
import proxy from '@/src/modules/common/lib/http-common'
import { csvCheckProcessURL } from '@/src/modules/setting/config/api-utils'
import { socialIntegrationsConfigurations } from '@src/modules/integration/config/api-utils'
import CstButton from '@/src/components/UI/Button/CstButton.vue'
import useHelper from '@/src/modules/common/composables/useHelper'
import ImportantUpdateModal from './ImportantUpdateModal.vue'

export default {
  components: {
    AccountSelection,
    AccountSelectionFirstComment,
    AutomationScheduleOptions,
    InfiniteLoading,
    ContentCategorySelection,
    UploadMediaModal,
    CstButton,
    ImportantUpdateModal
  },
  mixins: [AutoMixin],
  setup() {
    const { getAccountDateTimeFormat } = useDateFormat()
    const { setPreferenceStatus } = useHelper()
    return {
      getAccountDateTimeFormat,
      setPreferenceStatus,
    }
  },
  data() {
    return {
      integrations,
      format: 'm/d/Y H:i',
      bulk_channel: '',
      processingCheckInterval: null,
      processingCheckAttempts: 0,
      maxProcessingCheckAttempts: 1000,
      lastProcessedCount: 0,
      showImportModal: null,
    }
  },

  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getCsvAutomationTabStatus',
      'getCsvAutomationSelection',
      'getCsvListing',
      'getAutomationScheduleOptions',
      'getApprovedLoading',
      'getCsvProcessingStatus',
      'getProfile',
    ]),
    processBar() {
      return {
        width:
          (this.getCsvAutomationSelection.processed /
            this.getCsvAutomationSelection.totalProcess) *
            100 +
          '%',
      }
    },
    hasFirstCommentAccounts() {
      return (
        this.getAccountSelection.facebook.length > 0 ||
        this.getAccountSelection.instagram.length > 0 ||
        this.getAccountSelection.linkedin.length > 0
      )
    },
    /**
     * Check if step four is passed
     * @returns {boolean}
     */
    isStepFourPassed() {
      return (
        !this.getCsvListing.fetchCSVLoader &&
        this.getCsvListing.totalPosts > 0 &&
        this.getCsvListing.totalPosts === this.getCsvListing.approvedPosts
      )
    },
    shouldShowModal() {
      return this.getProfile?.preferences?.bulk_uploader_view ?? true
    },
  },

  watch: {
    'getCsvAutomationSelection.completed'(value) {
      if (value && this.getCsvAutomationSelection.validPosts) {
        // set default getter for pagination
        this.SET_CSV_LISTING_LOADER(true)
        this.SET_CSV_PAGINATION_LOADER(false)
        this.fetchCSVPosts()
      }
    },
    'getCsvAutomationSelection.csvId': {
      handler(value) {
        if (value) {
          this.startPeriodicProcessingCheck()
        }
      },
      immediate: true,
    },
    'getCsvListing.items.length'(n, o) {
      if (n === 0 && o === 1) {
        if (this.$el) {
          this.$refs.cvs_file.value = null
        }
        this.SET_CSV_Fourth_TAB_STATUS(false)
        this.setAccountSelection(null)
        this.SET_CSV_DEFAULT_STATE()
        this.changeActiveCSVAutomationTab(1)
        this.$router.push({
          name: 'saveBulkCsvAutomation',
          params: { workspace: this.getWorkspaces.activeWorkspace.slug },
        })
      }
    },
  },

  created() {
    this.setEditQueueStatus(false)
    this.setAccountSelection(null)
    this.setFirstCommentAccount([])
    this.$store.commit(
      instagramPostingOption.SET_INSTAGRAM_POSTING_OPTION,
      this.getWorkspaces.activeWorkspace?.instagram_posting_method || 'api'
    )
    this.setAutomationScheduleOptions(null)
    this.getAutomationScheduleOptions.time_type = 'Custom'
    this.SET_CSV_DEFAULT_STATE()
    this.initializeSaveVideoSocialAutomationSection()

    if (this.$route.params.csvId) {
      this.SET_CSV_PROCESS_LOADER(true)
      this.SET_CSV_ID(this.$route.params.csvId)
      this.changeActiveCSVAutomationTab(4)
      // this.checkCSVProcessing()
    }

    // Initialize modal visibility
    this.showImportModal = this.getProfile?.preferences?.bulk_uploader_view ?? true
  },

  mounted() {
    // this.bulk_channel = pusherSocketPublish.subscribe(
    //   `automation_${this.getActiveWorkspace._id}`
    // )
    // this.bulk_channel.bind('bulk_csv_processing', (data) => {
    //   if (data.csv_id && data.csv_id === this.getCsvAutomationSelection.csvId) {
    //     this.SET_CSV_PROCESSED_POSTS(data.processed)
    //     this.SET_CSV_TOTAL_PROCESSING(data.total)
    //     if (data.complete) {
    //       this.SET_CSV_VALID_POSTS(data.any_valid_post)
    //       this.SET_CSV_COMPLETED_POSTS(data.complete)
    //     }
    //   }
    // })
    // Set facebook default posted as
    this.getFacebookAccounts.items.forEach((account) => {
      if (commonMethods.isFacebookGroupWithPostedAs(account)) {
        account.posted_as.forEach((postedAs) => {
          if (postedAs.default === true) {
            this.$store.commit('setFacebookPostedAs', {
              posted_id: postedAs.id,
              account_id: account.facebook_id,
            })
          }
        })
      }
    })
  },

  beforeUnmount() {
    this.stopPeriodicProcessingCheck()
  },

  methods: {
    ...mapActions([
      'processCsv',
      'fetchCSVPosts',
      'cvsPlanOperations',
      'bulkCSVOperation',
      'setAccountSelection',
      'setAutomationScheduleOptions',
      'checkCSVProcessing',
      'setEditQueueStatus',
      'setFirstCommentAccount',
      'setBulkUploaderViewStatus',
    ]),
    ...mapMutations([
      'SET_CSV_DATE_FORMAT',
      'SET_CSV_DEFAULT_STATE',
      'SET_CSV_LISTING_LOADER',
      'SET_CSV_PAGINATION_LOADER',
      'SET_CSV_COMPLETED_POSTS',
      'SET_CSV_ID',
      'SET_CSV_PROCESS_LOADER',
      'SET_CSV_BULK_ACTION_MESSAGE_STATUS',
      'SET_CSV_VALID_POSTS',
      'SET_CSV_TOTAL_PROCESSING',
      'SET_CSV_PROCESSED_POSTS',
      'SET_CSV_Fourth_TAB_STATUS',
    ]),
    platformText(platform) {
      return getPlatformName(platform)
    },
    headingText(item) {
      return planHeadText(item, 'blog')
    },
    headAttachment(item) {
      return planHeadAttachment(item)
    },
    selectedPlatformsCount(item) {
      return itemSelectedPlatformsCount(item)
    },
    platformVisibleStatus(item, account) {
      return itemSelectedPlatformVisibleStatus(item, account)
    },
    processDescription(description, limit) {
      return parseDescription(description, limit)
    },
    getTumblrAccountTooltip(account) {
      if (account.name) {
        return account.name.replace('profile_', '')
      } else if (account.platform_name) {
        return account.platform_name.replace('profile_', '')
      }
      return ''
    },
    userBrowser() {
      // eslint-disable-next-line no-undef
      const result = bowser.getParser(window.navigator.userAgent)
      if (result && result.parsedResult && result.parsedResult.os.name) {
        return result.parsedResult.os.name
      }
      return false
    },

    async startPeriodicProcessingCheck() {
      this.stopPeriodicProcessingCheck() // Clear any existing interval
      this.processingCheckAttempts = 0
      this.lastProcessedCount = 0

      const checkProcessing = async () => {
        this.processingCheckAttempts++

        try {
          const payload = {
            csv_id: this.getCsvAutomationSelection.csvId,
            workspace_id: this.getWorkspaces.activeWorkspace._id,
          }
          const resp = await proxy.post(csvCheckProcessURL, payload)

          if (resp.data.complete) {
            this.handleProcessingComplete()
            this.checkCSVProcessing()
            return true // Stop checking
          } else {
            this.updateProcessingProgress(resp.data.processed, resp.data.total)

            this.lastProcessedCount = resp.data.processed

            // Stop if progress is stagnant for too long or max attempts reached
            if (
              this.processingCheckAttempts >= this.maxProcessingCheckAttempts
            ) {
              console.error('Max attempts reached. Stopping processing check.')
              return true
            }
          }
        } catch (error) {
          console.error('Error checking CSV processing:', error)
          if (this.processingCheckAttempts >= this.maxProcessingCheckAttempts) {
            console.error('Max attempts reached. Stopping processing check.')
            return true // Stop checking
          }
        }

        return false // Continue checking
      }

      const runCheck = async () => {
        const shouldStop = await checkProcessing()
        if (shouldStop) {
          this.stopPeriodicProcessingCheck()
        } else {
          this.processingCheckInterval = setTimeout(runCheck, 5000)
        }
      }

      runCheck()
    },

    updateProcessingProgress(processed, total) {
      this.SET_CSV_PROCESSED_POSTS(processed)
      this.SET_CSV_TOTAL_PROCESSING(total)
    },

    handleProcessingComplete() {
      this.stopPeriodicProcessingCheck()
      this.SET_CSV_COMPLETED_POSTS(true)
      this.SET_CSV_PROCESS_LOADER(false)
      // Add any additional logic needed when processing is complete
    },

    stopPeriodicProcessingCheck() {
      if (this.processingCheckInterval) {
        clearTimeout(this.processingCheckInterval)
        this.processingCheckInterval = null
      }
    },

    calculateWarnings(item) {
      const warnings = []
      const accountSelection = item.account_selection

      if (accountSelection) {
        Object.keys(accountSelection).forEach((platform) => {
          const selectionValue = accountSelection[platform]
          // Check if the selectionValue is not empty
          if (selectionValue.length) {
            const totalImages = this.getTotalImageCount(item)
            const maxImagesInfo = this.getMaxImagesInfo(platform)

            if (totalImages > maxImagesInfo.maxImages) {
              warnings.push({
                text: maxImagesInfo.warningText,
                type: 'warning',
              })
            }
          }
        })
      }
      return warnings
    },
    getTotalImageCount(item) {
      const details = item.common_sharing_details
      return details?.image?.length || 0
    },

    getMaxImagesInfo(platform) {
      const maxImages =
        socialIntegrationsConfigurations[platform]?.warnings?.image
          ?.max_images || Infinity
      const warningText =
        socialIntegrationsConfigurations[platform]?.warnings?.image
          ?.max_images_warning || 'Image limit exceeded.'

      return {
        maxImages,
        warningText,
      }
    },
  },
}
</script>

<template>
  <div class="automation_main_component create_automation_main_component">
    <UploadMediaModal type="evergreen"></UploadMediaModal>

    <ImportantUpdateModal :show-import-modal="showImportModal" />

    <div class="component_inner">
      <!--             <router-link class="close_icon float-right"-->
      <!--                          :to="{'name': 'automation', params: { workspace: getWorkspaces.activeWorkspace.slug}}">-->
      <!--                    &times;-->
      <!--              </router-link>-->

      <button
        class="close_icon float-right"
        @click="
          exitConfirmation(
            'automation',
            'Bulk Uploader',
            getWorkspaces.activeWorkspace?.slug,
            isStepFourPassed
          )
        "
      >
        &times;
      </button>

      <div
        class="bottom_notification_fixed"
        :class="{ open: getCsvListing.showBulkActionMessage }"
      >
        <div class="d-flex align-items-center toastr_inner">
          <div class="icon_sec">
            <img
              class="info_icon"
              src="../../../../assets/img/notification_icons/info_icon.svg"
            />
          </div>
          <div class="content_sec">
            <p>{{ getCsvListing.bulkActionMessage }}</p>
          </div>
          <div
            class="close_icon"
            @click.prevent="SET_CSV_BULK_ACTION_MESSAGE_STATUS(false)"
          >
            <img
              class="success_icon"
              src="../../../../assets/img/notification_icons/cross_icon.svg"
            />
          </div>
        </div>
      </div>

      <template v-if="getCsvAutomationSelection.processLoader">
        <div class="pt-3 pb-3">
          <beat-loader :color="'#436aff'"></beat-loader
        ></div>
      </template>
      <template v-else>
        <div class="top_content">
          <h2>New Import</h2>
          <img
            style="width: 45px"
            src="../../../../assets/img/automation/bulk_upload.svg"
            alt=""
          />
          <h3>Bulk Uploader</h3>
          <p
            >Schedule multiple posts (Text, Images, Videos, Links) to your social platforms via CSV data import.</p
          >
        </div>

        <!--active - past - d-none -->
        <div class="steps_count">
          <div class="count_inner">
            <a
              class="d-flex align-items-center justify-content-center"
              :class="{
                active: getCsvAutomationTabStatus.first === true,
                past:
                  getCsvAutomationTabStatus.first === false &&
                  getCsvAutomationTabStatus.fourth === false,
              }"
              @click="changeActiveCSVAutomationTab(1)"
            >
              <span>1</span>
            </a>
            <a
              class="d-flex align-items-center justify-content-center"
              :class="{
                active: getCsvAutomationTabStatus.second === true,
                past:
                  getCsvAutomationTabStatus.first === false &&
                  getCsvAutomationTabStatus.second === false &&
                  getCsvAutomationTabStatus.fourth === false,
              }"
              @click="processCsvAutomationValidation(1)"
            >
              <span>2</span>
            </a>
            <a
              class="d-flex align-items-center justify-content-center"
              :class="{
                active: getCsvAutomationTabStatus.third === true,
                past:
                  getCsvAutomationTabStatus.first === false &&
                  getCsvAutomationTabStatus.second === false &&
                  getCsvAutomationTabStatus.third === false &&
                  getCsvAutomationTabStatus.fourth === false,
              }"
              @click="processCsvAutomationValidation(2)"
            >
              <span>3</span>
            </a>
            <a
              class="d-flex align-items-center justify-content-center"
              :class="{ active: getCsvAutomationTabStatus.fourth === true }"
            >
              <span
                v-if="
                  !getCsvListing.fetchCSVLoader &&
                  getCsvListing.totalPosts > 0 &&
                  getCsvListing.totalPosts === getCsvListing.approvedPosts
                "
              >
                <i class="d-block pt-1 cs-checked"></i>
              </span>
              <span v-else>4</span>
            </a>
          </div>
        </div>

        <div
          class="page_1 connect_social_accounts"
          :class="{ 'd-none': getCsvAutomationTabStatus.first === false }"
        >
          <div class="automation_page width_medium">
            <div class="page_inner basic_form">
              <div class="step_heading d-flex justify-between align-items-center">
                <h3>Step 1 - Upload CSV File</h3>

                <!--<div class="input_field">-->
                <!--<label for="">Campaign Name</label>-->
                <!--<input type="text" placeholder="Enter campaign name..." v-model="getCsvAutomationSelection.name">-->
                <!--</div>-->

                <div class="">
                  <a
                  href="#"
                  class="beacon"
                  data-beacon-article-modal="677b82779a830000cc29b3a3"
                  >Learn more</a
                >
              </div>

              </div>

              <div class="input_field desc_text">
                <p
                  >Import a CSV file with the date, time, text and media of your new posts.</p
                >
                <p class="mt-2"
                  >Here’s how your CSV file should look like.
                  <a
                    href="https://storage.googleapis.com/contentstudio-media-library-nearline/contentstudio/bulk_uploader_csv_template-v2.csv"
                    >Download CSV file template</a
                  >.</p
                >
                <p v-if="userBrowser() === 'macOS'" class="mt-2"
                  >Learn more:
                  <a
                    href="https://docs.contentstudio.io/article/720-how-to-create-csv-file-on-mac"
                    target="_blank" rel="noopener"
                    >https://docs.contentstudio.io/article/720-how-to-create-csv-file-on-mac</a
                  ></p
                >
              </div>

              <div class="cvs_template ml-auto mt-4">
                  <img
                    style="width: 100%"
                    class=""
                    src="../../../../assets/img/automation/csv_template.png"
                    alt=""
                  />
                </div>

              <div class="input_field">
                <label for="">Upload your CSV file</label>
                <div class="cvs_upload_btn">
                  <div class="cvs_upload_inner">
                    <label for="cvs_file" class="cvs_upload_label">
                      <span class="upload_icon">
                        <span class="cs-upload-arrow"></span>
                      </span>
                      <span class="text">Upload</span>
                    </label>
                    <input
                      id="cvs_file"
                      ref="cvs_file"
                      accept=".csv, text/csv"
                      style="display: none"
                      class="hide"
                      type="file"
                      @change="uploadCsvFile($event)"
                    />
                    <span
                      v-if="getCsvAutomationSelection.fileName"
                      class="input_file_name"
                      >{{ getCsvAutomationSelection.fileName }}</span
                    >
                    <span v-else class="input_file_name">No file selected</span>
                  </div>
                </div>
              </div>

              <!--End Select blog platforms-->

              <div class="field_group text-center">
                <button
                  class="btn btn-studio-theme-transparent-grey mr-1"
                  disabled="disabled"
                >
                  <i class="icon_left cs-angle-left"> </i>
                  <span>Prev</span>
                </button>
                <button
                  class="btn btn-studio-theme-space"
                  @click.prevent="processCsvAutomationValidation(1)"
                >
                  <span>Next</span>
                  <i class="icon_right arrow_right_active cs-angle-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="page_2 post_optimization"
          :class="{ 'd-none': getCsvAutomationTabStatus.second === false }"
        >
          <div class="automation_page width_small">
            <div class="page_inner basic_form">
              <div class="step_heading">
                <h3>Step 2 - Select Channels</h3>
              </div>

              <div class="input_field">
                <ContentCategorySelection
                  position="rss-listing"
                  :module="'bulk_automation'"
                ></ContentCategorySelection>
              </div>

              <AccountSelection :show-label="true"></AccountSelection>

              <div
                class="input_field"
                :class="{ hidden: !hasFirstCommentAccounts }"
              >
                <div class="w-48 flex items-center">
                  <span class="mr-2"> Publish first comment to </span>
                  <v-menu
                    class=""
                    :popper-triggers="['hover']"
                    placement="bottom-end"
                    popper-class="first-comment__info-popover"
                  >
                    <!-- This will be the popover target (for the events and position) -->
                    <a href="#"
                      ><img
                        src="@assets/img/composer/help-icon.svg"
                        alt=""
                        class="w-5 h-5"
                    /></a>
                    <!-- This will be the content of the popover -->
                    <template v-slot:popper>
                      <div> First comment is available for:</div>
                      <ul>
                        <li><i class="fab fa-facebook-f"></i> Pages</li>
                        <li
                          ><i class="fab fa-instagram"></i> API Posting
                          Method</li
                        >
                        <li>
                          <i class="fab fa-linkedin-in"></i> Profiles and Pages
                        </li>
                      </ul>
                    </template>
                  </v-menu>
                </div>
                <div class="mt-[-15px]">
                  <AccountSelectionFirstComment :show-label="false" />
                </div>
              </div>

              <div class="field_group text-center">
                <button
                  class="btn btn-studio-theme-transparent-grey mr-1"
                  @click.prevent="changeActiveCSVAutomationTab(1)"
                >
                  <i class="icon_left cs-angle-left"> </i>
                  <span>Prev</span>
                </button>
                <button
                  class="btn btn-studio-theme-space"
                  @click.prevent="processCsvAutomationValidation(2)"
                >
                  <span>Next</span>
                  <i class="icon_right arrow_right_active cs-angle-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="page_3 schedule_campaign"
          :class="{ 'd-none': getCsvAutomationTabStatus.third === false }"
        >
          <div class="automation_page width_small">
            <div class="page_inner basic_form">
              <div class="step_heading">
                <h3>Step 3 - Scheduling Options</h3>
              </div>

              <AutomationScheduleOptions
                :tooltip-value="'Get the time slots from file'"
                type="csv"
              ></AutomationScheduleOptions>

              <template
                v-if="getAutomationScheduleOptions.time_type === 'Custom'"
              >
                <div class="input_field">
                  <label for=""
                    >Which date format have you used in the file?</label
                  >
                  <select
                    id=""
                    v-model="format"
                    name=""
                    class="with_bg w-auto d-block"
                    @change="SET_CSV_DATE_FORMAT(format)"
                  >
                    <option value="m/d/Y H:i">mm/dd/yyyy hh:mm</option>
                    <option value="d/m/Y H:i">dd/mm/yyyy hh:mm</option>
                  </select>
                </div>
                <div
                  v-if="getCsvAutomationSelection.dateFormat === 'd/m/Y H:i'"
                  class="time_format input_field d-flex align-items-start"
                >
                  <div class="left w-50">
                    <p class="title">Select your date format</p>
                    <ul>
                      <li>23/05/2015 7:05</li>
                      <li>24/05/2015 17:05</li>
                      <li>25/05/2015 7:05</li>
                      <li>26/05/2015 17:05</li>
                    </ul>
                  </div>
                  <div class="right w-50">
                    <p class="title">How we interpret this date</p>
                    <ul>
                      <li>May 23, 2015 7:05 AM</li>
                      <li>May 24, 2015 5:05 PM</li>
                      <li>May 25, 2015 7:05 AM</li>
                      <li>May 26, 2015 5:05 PM</li>
                    </ul>
                  </div>
                </div>
                <div
                  v-else
                  class="time_format input_field d-flex align-items-start"
                >
                  <div class="left w-50">
                    <p class="title">Select your date format</p>
                    <ul>
                      <li>05/23/2015 7:05</li>
                      <li>05/24/2015 17:05</li>
                      <li>05/25/2015 7:05</li>
                      <li>05/26/2015 17:05</li>
                    </ul>
                  </div>
                  <div class="right w-50">
                    <p class="title">How we interpret this date</p>
                    <ul>
                      <li>May 23, 2015 7:05 AM</li>
                      <li>May 24, 2015 5:05 PM</li>
                      <li>May 25, 2015 7:05 AM</li>
                      <li>May 26, 2015 5:05 PM</li>
                    </ul>
                  </div>
                </div>
              </template>
              <div class="field_group text-center">
                <button
                  class="btn btn-studio-theme-transparent-grey mr-1"
                  @click.prevent="changeActiveCSVAutomationTab(2)"
                >
                  <i class="icon_left cs-angle-left"> </i>
                  <span>Prev</span>
                </button>
                <button
                  class="btn btn-studio-theme-space"
                  :class="{ btn_disable: getCsvAutomationSelection.saveLoader }"
                  @click.prevent="processCsv"
                >
                  <span>Next</span>
                  <i class="icon_right arrow_right_active cs-angle-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="page_4 schedule_campaign"
          :class="{ 'd-none': getCsvAutomationTabStatus.fourth === false }"
        >
          <div
            v-if="isStepFourPassed"
            class="automation_page automation_listing width_small"
          >
            <div class="page_inner basic_form" style="padding: 25px">
              <div class="bulk_empty_message">
                <div class="bulk_empty_message_inner">
                  <i class="fa fa-check"></i>
                  <p>You're all done!</p>
                  <p style="font-size: 1rem; opacity: 0.7"
                    >Now, You can find your posts in the Planner.</p
                  >
                </div>
              </div>
            </div>
          </div>

          <div v-else class="automation_page automation_listing">
            <div class="page_inner basic_form" style="padding: 25px">
              <div class="step_heading">
                <h3>Step 4 - Finalizing your posts</h3>
              </div>

              <div
                v-if="
                  !getCsvListing.fetchCSVLoader && getCsvListing.totalPosts > 0
                "
                class="warning_box text-center more_padding warn_box"
              >
                <p class="green-text">
                  <strong>
                    {{
                      getCsvListing.totalPosts -
                      (getCsvListing.approvedPosts + getCsvListing.errorPosts)
                    }}
                  </strong>
                  post(s) are ready to be approved.
                </p>
                <p class="red-text">
                  <strong> {{ getCsvListing.errorPosts }} </strong> post(s) have
                  an error that needs to be fixed.
                </p>
              </div>

              <br />

              <div class="automation_listing1_csv">
                <template v-if="getCsvAutomationSelection.completed">
                  <template v-if="!getCsvAutomationSelection.validPosts">
                    <p class="top_description">No valid post found</p>
                  </template>
                  <template v-else>
                    <div
                      class="table_filter d-flex align-items-center bulk_list"
                    >
                      <div class="f_left mr-auto text-right">
                        <div
                          v-if="getCsvListing.selectedItems.length < 2"
                          v-tooltip.top="'Select 2 or more posts'"
                          class="
                            disabled
                            bulk_action_csv_dropdown
                            with_background
                            dropdown
                            option_dropdown
                            default_style_dropdown
                          "
                        >
                          <div
                            class="dropdown_header d-flex align-items-center"
                            data-toggle="dropdown"
                          >
                            <span class="align-middle text">Bulk Action</span>
                            <i class="align-middle cs-angle-down icon_last"></i>
                          </div>
                        </div>
                        <div
                          v-else
                          class="
                            bulk_action_csv_dropdown
                            with_background
                            dropdown
                            option_dropdown
                            default_style_dropdown
                          "
                        >
                          <div
                            class="dropdown_header d-flex align-items-center"
                            data-toggle="dropdown"
                          >
                            <span class="align-middle text">Bulk Action</span>
                            <i class="align-middle cs-angle-down icon_last"></i>
                          </div>

                          <div class="dropdown-menu dropdown-menu-left">
                            <ul class="inner">
                              <li
                                class="list_item_li"
                                @click.prevent="bulkCSVOperation('approve')"
                                >Approve Selected</li
                              >
                              <li
                                class="list_item_li"
                                @click.prevent="
                                  confirmAction('removeCsvPlans', 'delete')
                                "
                              >
                                <!--bulkCSVOperation('delete')-->
                                Delete Selected
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div class="f_right">
                        <p
                          v-if="
                            !getCsvListing.fetchCSVLoader &&
                            getCsvListing.totalPosts > 0
                          "
                          class="top_description"
                        >
                          <span
                            ><strong>{{ getCsvListing.approvedPosts }}</strong>
                            out of
                            <strong>{{ getCsvListing.totalPosts }}</strong>
                            post(s) have been scheduled successfully.
                          </span>
                          <!--<span><strong>{{getCsvListing.approvedPosts}}</strong> out of <strong>{{getCsvListing.totalPosts}}</strong> message(s) have been processed and scheduled. </span>-->
                          <!--<span><strong>{{getCsvListing.errorPosts}}</strong> message(s) needs to be fixed before they are scheduled.</span>-->
                        </p>
                      </div>
                    </div>
                    <div class="social_table">
                      <div class="table_wrapper">
                        <table class="table">
                          <thead>
                            <tr>
                              <th class="text-center">
                                <div
                                  v-if="
                                    getCsvListing.totalPosts -
                                      (getCsvListing.approvedPosts +
                                        getCsvListing.errorPosts) >
                                    1
                                  "
                                  class="checkbox_input_image"
                                >
                                  <input
                                    id="visiableCheckAllStatus"
                                    v-model="getCsvListing.checkAll"
                                    type="checkbox"
                                    @change="changeSelectedCSV('ALL')"
                                  />
                                  <label for="visiableCheckAllStatus"></label>
                                </div>
                              </th>
                              <th class="text-center" style="width: 170px"
                                >Media</th
                              >
                              <th class="text-center" style="width: 300px"
                                >Description</th
                              >
                              <th class="text-center" style="width: 330px"
                                >Profile</th
                              >
                              <th style="width: 200px" class="text-center"
                                >Date</th
                              >
                              <th style="width: 140px" class="text-center"
                                >Status</th
                              >
                              <th style="text-align: center; width: 200px"
                                >Actions</th
                              >
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="getCsvListing.fetchCSVLoader">
                              <td colspan="7" class="text-center">
                                <clip-loader
                                  color="#4165ed"
                                  :size="'16px'"
                                ></clip-loader>
                              </td>
                            </tr>
                            <template v-else>
                              <tr
                                v-for="(item, index) in getCsvListing.items"
                                :key="index"
                                :class="{
                                  error_row:
                                    item.error === 'error_date' ||
                                    item.error === 'error_message' ||
                                    item.error === 'error_date_empty' ||
                                    item.error === 'twitter_length_error' ||
                                    item.error === 'error_video' ||
                                    (item.execution_time &&
                                      timePassingCheckCSV(
                                        item.execution_time.date
                                      )),
                                }"
                              >
                                <td class="text-center">
                                  <div class="checkbox_input_image">
                                    <input
                                      :id="index"
                                      v-model="getCsvListing.selectedItems"
                                      type="checkbox"
                                      :disabled="
                                        item.error === 'error_date' ||
                                        item.error === 'error_message' ||
                                        item.error === 'error_date_empty' ||
                                        item.error === 'twitter_length_error' ||
                                        item.error === 'error_video' ||
                                        (item.execution_time &&
                                          timePassingCheckCSV(
                                            item.execution_time.date
                                          ))
                                      "
                                      :value="item._id"
                                      @change.prevent="
                                        changeSelectedCSV('single')
                                      "
                                    />
                                    <label :for="index"></label>
                                  </div>
                                </td>
                                <td>
                                  <template
                                    v-if="
                                      headAttachment(item) &&
                                      headAttachment(item).url
                                    "
                                  >
                                    <div v-if="headAttachment(item).type === 'image'" class="flex justify-center relative">
                                      <img
                                        class="csv_img"
                                        :src="headAttachment(item).url"
                                        alt=""
                                      />
                                      <span
                                        v-if=" (item?.common_sharing_details?.image?.length - 1 ) > 0"
                                         class="absolute top-[-10px] right-[-3px] bg-primary text-white text-xs
                                          rounded-full w-7 h-7 flex items-center justify-center"
                                      >
                                      + {{
                                        item?.common_sharing_details?.image
                                          ?.length - 1
                                      }}

                                      </span>
                                    </div>
                                    <div class="flex justify-center relative"  v-else-if="headAttachment(item).type === 'video'">
                                      <video
                                        class="csv_img"
                                        :src="headAttachment(item).url"
                                        alt=""
                                      />
                                    </div>

                                    <template v-else>
                                      <img
                                        class="csv_img"
                                        src="https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png"
                                        alt=""
                                      />
                                    </template>

                                  </template>

                                </td>
                                <td class="text-left">
                                  <p
                                    class="desc"
                                    v-html="
                                      processDescription(headingText(item), 320)
                                    "
                                  ></p>
                                </td>
                                <td class="text-center picture_block_list">
                                  <div
                                    class="
                                      social_icons
                                      d-flex
                                      align-items-center
                                      flex-wrap
                                      justify-content-center
                                    "
                                  >
                                    <template v-if="item.account_selection">
                                      <template
                                        v-for="(account, key) in item
                                          .account_selection.facebook"
                                      >
                                        <div
                                          v-if="
                                            platformVisibleStatus(
                                              item,
                                              account.facebook_id
                                            )
                                          "
                                          :key="key"
                                          v-tooltip.top="account.name"
                                          class="
                                            profile_picture
                                            d-flex
                                            align-items-start
                                            fb
                                          "
                                        >
                                          <div
                                            class="
                                              picture_block
                                              picture_block_background
                                            "
                                          >
                                            <div class="icon">
                                              <i class="cs-facebook"></i>
                                            </div>
                                            <div
                                              v-if="facebookImage(account)"
                                              class="img"
                                              :style="{
                                                background:
                                                  'url(' +
                                                  facebookImage(account) +
                                                  ')',
                                              }"
                                            ></div>
                                            <div
                                              v-else
                                              class="img"
                                              style="
                                                background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                                              "
                                            ></div>
                                          </div>
                                        </div>
                                      </template>

                                      <template
                                        v-for="(account, key) in item
                                          .account_selection.instagram"
                                      >
                                        <div
                                          v-if="
                                            platformVisibleStatus(
                                              item,
                                              account.instagram_id
                                            )
                                          "
                                          :key="key"
                                          v-tooltip.top="account.name"
                                          class="
                                            profile_picture
                                            d-flex
                                            align-items-start
                                            ins
                                          "
                                        >
                                          <div
                                            class="
                                              picture_block
                                              picture_block_background
                                            "
                                          >
                                            <div class="icon">
                                              <i class="cs-instagram"></i>
                                            </div>
                                            <div
                                              class="img"
                                              :style="{
                                                background:
                                                  'url(' +
                                                  instagramImage(account) +
                                                  ')',
                                              }"
                                            ></div>
                                          </div>
                                        </div>
                                      </template>

                                      <template
                                        v-for="(account, key) in item
                                          .account_selection.threads"
                                      >
                                        <div
                                          v-if="
                                            platformVisibleStatus(
                                              item,
                                              account.platform_identifier
                                            )
                                          "
                                          :key="key"
                                          v-tooltip.top="account.platform_name"
                                          class="
                                            profile_picture
                                            d-flex
                                            align-items-start
                                            ths
                                          "
                                        >
                                          <div
                                            class="
                                              picture_block
                                              picture_block_background
                                            "
                                          >
                                            <div class="icon">
                                              <i class="cs-threads"></i>
                                            </div>
                                            <div
                                              class="img"
                                              :style="{
                                                backgroundImage:
                                                  threadsImage(account),
                                              }"
                                            ></div>
                                          </div>
                                        </div>
                                      </template>

                                      <template
                                          v-for="(account, key) in item
                                          .account_selection.bluesky"
                                      >
                                        <div
                                            v-if="
                                            platformVisibleStatus(
                                              item,
                                              account.platform_identifier,
                                            )
                                          "
                                            :key="key"
                                            v-tooltip.top="account.platform_name"
                                            class="profile_picture d-flex align-items-start ths"
                                        >
                                          <div
                                              class="picture_block picture_block_background"
                                          >
                                            <div class="icon">
                                              <img src="@src/assets/img/integration/bluesky-rounded.svg" alt="bluesky_logo" />
                                            </div>
                                            <div
                                                class="img"
                                                :style="{
                                                backgroundImage: threadsImage(account)
                                              }"
                                            ></div>
                                          </div>
                                        </div>
                                      </template>

                                      <template
                                        v-for="(account, key) in item
                                          .account_selection.twitter"
                                      >
                                        <div
                                          v-if="
                                            platformVisibleStatus(
                                              item,
                                              account.twitter_id
                                            )
                                          "
                                          :key="key"
                                          v-tooltip.top="account.name"
                                          class="
                                            profile_picture
                                            d-flex
                                            align-items-start
                                            tw
                                          "
                                        >
                                          <div
                                            class="
                                              picture_block
                                              picture_block_background
                                            "
                                          >
                                            <div class="icon">
                                              <i class="cs-twitter"></i>
                                            </div>
                                            <div
                                              v-if="twitterImage(account)"
                                              class="img"
                                              :style="{
                                                background:
                                                  'url(' +
                                                  twitterImage(account) +
                                                  ')',
                                              }"
                                            ></div>
                                            <div
                                              v-else
                                              class="img"
                                              style="
                                                background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                                              "
                                            ></div>
                                          </div>
                                        </div>
                                      </template>

                                      <template
                                        v-for="(account, key) in item
                                          .account_selection.linkedin"
                                      >
                                        <div
                                          v-if="
                                            platformVisibleStatus(
                                              item,
                                              account.linkedin_id
                                            )
                                          "
                                          :key="key"
                                          v-tooltip.top="account.name"
                                          class="
                                            profile_picture
                                            d-flex
                                            align-items-start
                                            lin
                                          "
                                        >
                                          <div
                                            class="
                                              picture_block
                                              picture_block_background
                                            "
                                          >
                                            <div class="icon">
                                              <i class="cs-linkedin"></i>
                                            </div>
                                            <div
                                              v-if="linkedinImage(account)"
                                              class="img"
                                              :style="{
                                                background:
                                                  'url(' +
                                                  linkedinImage(account) +
                                                  ')',
                                              }"
                                            ></div>
                                            <div
                                              v-else
                                              class="img"
                                              style="
                                                background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                                              "
                                            ></div>
                                          </div>
                                        </div>
                                      </template>

                                      <template
                                        v-for="(account, key) in item
                                          .account_selection.pinterest"
                                      >
                                        <div
                                          v-if="
                                            platformVisibleStatus(
                                              item,
                                              account.board_id
                                            )
                                          "
                                          :key="key"
                                          v-tooltip.top="account.name"
                                          class="
                                            profile_picture
                                            d-flex
                                            align-items-start
                                            pin
                                          "
                                        >
                                          <div
                                            class="
                                              picture_block
                                              picture_block_background
                                            "
                                          >
                                            <div class="icon">
                                              <i class="cs-pinterest"></i>
                                            </div>

                                            <div
                                              v-if="pinterestImage(account)"
                                              class="img"
                                              :style="{
                                                background:
                                                  'url(' +
                                                  pinterestImage(account) +
                                                  ')',
                                              }"
                                            ></div>
                                            <div
                                              v-else
                                              class="img"
                                              style="
                                                background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                                              "
                                            ></div>
                                          </div>
                                        </div>
                                      </template>

                                      <template
                                        v-for="(account, key) in item
                                          .account_selection.tumblr"
                                      >
                                        <div
                                          v-if="
                                            platformVisibleStatus(
                                              item,
                                              account.name
                                            )
                                          "
                                          :key="key"
                                          v-tooltip.top="
                                            getTumblrAccountTooltip(account)
                                          "
                                          class="
                                            profile_picture
                                            d-flex
                                            align-items-start
                                            tum
                                          "
                                        >
                                          <div
                                            class="
                                              picture_block
                                              picture_block_background
                                            "
                                          >
                                            <div class="icon">
                                              <i class="cs-tumblr"></i>
                                            </div>
                                            <div
                                              v-if="tumblrImage(account)"
                                              class="img"
                                              :style="{
                                                background:
                                                  'url(' +
                                                  tumblrImage(account) +
                                                  ')',
                                              }"
                                            ></div>
                                            <div
                                              v-else
                                              class="img"
                                              style="
                                                background: url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg');
                                              "
                                            ></div>
                                          </div>
                                        </div>
                                      </template>

                                      <template
                                        v-for="(account, key) in item
                                          .account_selection.gmb"
                                      >
                                        <div
                                          v-if="
                                            platformVisibleStatus(
                                              item,
                                              account.name
                                            )
                                          "
                                          :key="key"
                                          v-tooltip.top="platformText(account)"
                                          class="
                                            profile_picture
                                            d-flex
                                            align-items-start
                                            google_b
                                          "
                                        >
                                          <div
                                            class="
                                              picture_block
                                              picture_block_background
                                            "
                                          >
                                            <div class="icon">
                                              <i class="fab fa-google"></i>
                                            </div>
                                            <div
                                              class="img"
                                              :style="{
                                                background:
                                                  'url(' +
                                                  integrations.platformImage(
                                                    account
                                                  ) +
                                                  ')',
                                              }"
                                            ></div>
                                          </div>
                                        </div>
                                      </template>
                                    </template>

                                    <div
                                      v-if="selectedPlatformsCount(item) > 3"
                                      class="
                                        d-inline-block
                                        align-middle
                                        ml-3
                                        circle_boxes_inline
                                      "
                                    >
                                      <div
                                        class="
                                          circle_box
                                          d-flex
                                          align-items-center
                                          justify-content-center
                                        "
                                      >
                                        <span class="text"
                                          >+{{
                                            selectedPlatformsCount(item) - 3
                                          }}</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </td>

                                <td class="text-center">
                                  <template v-if="item.execution_time">
                                    <template
                                      v-if="
                                        item.content_category_id &&
                                        item.dateSettingType &&
                                        item.dateSettingType ===
                                          'content_category'
                                      "
                                    >
                                      Next Available Slot</template
                                    >
                                    <template
                                      v-else-if="
                                        timePassingCheckCSV(
                                          item.execution_time.date
                                        )
                                      "
                                    >
                                      Scheduled date passed</template
                                    >
                                    <template v-else>
                                      {{
                                        getWorkspaceTimeZoneTime(
                                          item['execution_time']['date'],
                                          getAccountDateTimeFormat
                                        )
                                      }}
                                    </template>
                                  </template>
                                  <template v-else>
                                    <P>Incorrect/Empty date</P>
                                  </template>
                                </td>
                                <td class="text-center csv_error_block">
                                  <template
                                    v-if="
                                      item.error &&
                                      (item.error === 'error_date' ||
                                        item.error === 'error_message' ||
                                        item.error === 'error_date_empty' ||
                                        item.error === 'twitter_length_error' ||
                                        item.error === 'error_video')
                                    "
                                  >
                                    <i
                                      class="
                                        error_found
                                        fa fa-exclamation
                                        custom_tooltip
                                      "
                                      aria-hidden="true"
                                    >
                                      <div class="tool_tip_box top_center">
                                        <div class="tool_tip_inner font-normal">
                                          <p v-if="item.error === 'error_date'"
                                            >Your message contains an invalid date format. A message with an invalid date will not be processed.
                                            Please edit the post or correct the date format in the CSV file.</p
                                          >
                                          <p
                                            v-else-if="
                                              item.error === 'error_date_empty'
                                            "
                                            >Your message does not have any date
                                            for the schedule, a message without
                                            a date will not be processed.</p
                                          >
                                          <p
                                            v-else-if="
                                              item.error === 'error_message'
                                            "
                                            >Your message do not have any text
                                            or image or video, message without any text
                                            or image or video is not allowed.</p
                                          >
                                          <p
                                            v-else-if="
                                              item.error ===
                                              'twitter_length_error'
                                            "
                                            >Your message for the X (Twitter)
                                            exceeds character limit, please make
                                            sure your message is less than 280
                                            characters.</p
                                          >
                                          <p
                                            v-else-if="
                                              item.error ===
                                              'error_video'
                                            "
                                            >{{ item.error_message }}.</p
                                          >
                                        </div>
                                      </div>
                                    </i>
                                    <span class="error_found_color ml-2"
                                      >Error</span
                                    >
                                  </template>
                                  <template
                                    v-else-if="
                                      item.execution_time &&
                                      timePassingCheckCSV(
                                        item.execution_time.date
                                      ) &&
                                      !(
                                        item.content_category_id &&
                                        item.dateSettingType &&
                                        item.dateSettingType ===
                                          'content_category'
                                      )
                                    "
                                  >
                                    <i
                                      class="
                                        error_found
                                        fa fa-exclamation
                                        custom_tooltip
                                      "
                                      aria-hidden="true"
                                    >
                                      <div class="tool_tip_box top_center font-normal	">
                                        <div class="tool_tip_inner">
                                          <p
                                            >Scheduled date for this message has
                                            already passed. Please set a date in
                                            future.</p
                                          >
                                        </div>
                                      </div>
                                    </i>
                                    <span class="error_found_color ml-2"
                                      >Error</span
                                    >
                                  </template>
                                  <template v-else>
                                    <i
                                      class="
                                        fa fa-exclamation-triangle
                                        warning_found
                                        custom_tooltip
                                      "
                                      aria-hidden="true"
                                      v-if="calculateWarnings(item).length > 0"
                                    >
                                      <div class="tool_tip_box top_center font-normal">
                                        <ul class="tool_tip_inner text-left">
                                          <li
                                          class="text-[#000] py-2"
                                            v-for="(
                                              warning, index
                                            ) in calculateWarnings(item)"
                                            :key="index"
                                          >
                                            {{ warning.text }}
                                        </li>
                                        </ul>
                                      </div>
                                    </i>
                                    <span
                                      class="warning_found_color ml-2"
                                      v-if="calculateWarnings(item).length > 0"
                                      >Warning</span
                                    >
                                    <template v-else>
                                      <i
                                        class="
                                          fa fa-check
                                          success_found
                                          custom_tooltip
                                        "
                                        aria-hidden="true"
                                      >
                                        <div class="tool_tip_box top_center font-normal	">
                                          <div class="tool_tip_inner">
                                            <p
                                              >This post looks fine and has no
                                              warnings.</p
                                            >
                                          </div>
                                        </div>
                                      </i>
                                      <span class="success_found_color ml-2"
                                        >Ready</span
                                      >
                                    </template>
                                  </template>
                                </td>
                                <td class="text-center">
                                  <!--with_loader-->
                                  <div class="action_icons">
                                    <!--<i style="font-size: 20px;"  v-if="checkValidForApprove(item)" @click.prevent="cvsPlanOperations({'id':item._id,'index':index,'type':'approve'})"-->
                                    <!--v-tooltip.top="'Approve & Schedule'" class="icon_table edit_icon approve_icon cs-checked" aria-hidden="true"></i>-->

                                    <!--<i @click.prevent="editCSVPost(item,index)" v-tooltip.top="'Edit'"-->
                                    <!--class="icon_table edit_icon icon-edit-cs" aria-hidden="true"></i>-->

                                    <!--<i  @click.prevent="confirmAction('removeCsvPlan', {'id':item._id,'index':index,'type':'delete'})"-->
                                    <!--v-tooltip.top="'Remove'" class="icon_table edit_icon far fa-trash" aria-hidden="true"></i>-->

                                    <button
                                      v-if="checkValidForApprove(item)"
                                      v-tooltip.top="'Approve & Schedule'"
                                      class="btn btn_square"
                                      :disabled="getApprovedLoading"
                                      @click.prevent="
                                        cvsPlanOperations({
                                          id: item._id,
                                          index: index,
                                          type: 'approve',
                                        })
                                      "
                                    >
                                      <i
                                        class="
                                          edit_icon
                                          approve_icon
                                          cs-checked
                                        "
                                      ></i>
                                    </button>
                                    <!--icon_table-->

                                    <button
                                      v-tooltip.top="'Edit'"
                                      class="btn btn_square"
                                      @click.prevent="editCSVPost(item, index)"
                                    >
                                      <i class="edit_icon icon-edit-cs"></i>
                                    </button>

                                    <button
                                      v-tooltip.top="'Remove'"
                                      class="btn btn_square"
                                      @click.prevent="
                                        confirmAction('removeCsvPlan', {
                                          id: item._id,
                                          index: index,
                                          type: 'delete',
                                        })
                                      "
                                    >
                                      <i class="icon-delete-cs"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                              <tr v-if="getCsvListing.items.length === 0">
                                <td
                                  style="border: 0"
                                  colspan="7"
                                  class="text-center p-3"
                                  >No posts found</td
                                >
                              </tr>

                              <!--<tr  v-else-if="!getCsvListing.fetchCSVPaginationScroll">-->
                              <!--<td style="    border: 0;"  colspan="7" class="text-center" >-->
                              <!--<no-results-found :message="'No more data found.'"></no-results-found>-->
                              <!--</td>-->
                              <!--</tr>-->
                              <tr
                                v-else-if="
                                  getCsvListing.fetchCSVPaginationScroll
                                "
                              >
                                <td colspan="7" class="text-center">
                                  <InfiniteLoading
                                    ref="infiniteLoading"
                                    @infinite="onInfiniteCSVList"
                                  >
                                    <span slot="spinner" class="pt-3 d-block">
                                      <beat-loader
                                        :color="'#436aff'"
                                      ></beat-loader>
                                    </span>
                                  </InfiniteLoading>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </template>
                </template>
                <!--v-else-->
                <template v-else>
                  <p
                    v-if="getCsvAutomationSelection.processed === 0"
                    style="font-size: 1.125rem"
                    class="mb-2"
                    >Transforming file (it may take few seconds depending on the
                    number of posts)</p
                  >
                  <p v-else style="font-size: 1.125rem" class="mb-2"
                    >Processing {{ getCsvAutomationSelection.processed }} /
                    {{ getCsvAutomationSelection.totalProcess }}</p
                  >

                  <div class="process_bar_automation">
                    <div class="limit_add" :style="processBar"></div>
                    <div class="limit_exceed"></div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="less">
.action_icons {
  button {
    background: rgba(58, 69, 87, 0.08);
    margin: 0px 1px;
  }
  i {
    color: rgba(58, 69, 87, 0.5);
  }
}
.warn_box {
  background: rgba(255, 252, 245, 0.5);
  width: 50%;
  margin: auto;

  .red-text {
    color: #db5353;
  }
  .green-text {
    color: #3ac488;
  }
}
.input_field .list-decimal li{
  list-style-type: decimal;
}
</style>
