<template>
  <div class="setting_linked_devices_component">
    <div class="component_inner clear">
      <div class="bg-white rounded-xl mb-7 overflow-hidden">
        <div class="flex px-4 py-4 items-center justify-between">
          <div class="flex flex-col items-start gap-y-1">
            <h4 class="text-lg">Activity</h4>
            <p class="text-gray-700 text-sm"
              >Your account is currently logged in on these devices.
              Remove/Logout any unauthorized devices.</p
            >
          </div>
          <div class="flex items-center">
            <CstButton
              variant="danger"
              @click="confirmLogoutAllDevices"
            >
              Logout of all devices
            </CstButton>
          </div>
        </div>
        <div class="w-full rounded-xl px-4 pb-2">
          <table class="w-full p-2">
            <thead class="focus:outline-none h-16 bg-gray-50">
              <tr>
                <th class="pl-3 text-left">Device Name</th>
                <th class="text-left">Last Active</th>
                <th class="text-left">Location</th>
                <th class="text-right pr-5">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="device in sortedDevices"
                :key="device.token"
                style="border-top: 1px solid #f1f1f1"
                class="focus:outline-none h-16"
              >
                <td class="pl-3">
                  <div class="flex items-center">
                    <img
                      :src="getDeviceIcon(device.device.platform)"
                      class="w-10 h-10 bg-gray-400 rounded-full mr-3 p-2"
                      alt="device icon"
                    />
                    <div>
                      <p class="font-medium text-sm">
                        {{ device?.device?.name }}
                      </p>
                      <p
                        v-if="device.is_current"
                        class="text-xs text-green-500 flex items-center mt-0.5"
                      >
                        <span
                          class="w-1.5 h-1.5 rounded-full bg-green-500 mr-1"
                        ></span>
                        Current device
                      </p>
                    </div>
                  </div>
                </td>

                <td>
                  <div class="flex items-center">
                    <template v-if="device.is_current">
                      <span
                        class="w-1.5 h-1.5 rounded-full bg-green-500 mr-1"
                      ></span>
                      <span class="text-sm">Active now</span>
                    </template>
                    <template v-else>
                      <span class="text-sm">{{ formatDate(device.last_active)}}</span>
                    </template>
                  </div>
                </td>

                <td>
                  <span v-tooltip="getFormattedLocation(device?.location)" class="text-sm">
                    {{ getFormattedLocation(device?.location) }}
                  </span>
                </td>

                <td class="text-right pr-4">
                  <CstButton
                    v-if="!device.is_current"
                    variant="danger"
                    @click="confirmLogoutDevice(device)"
                  >
                    Logout
                  </CstButton>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <CstConfirmationPopup
      modal-name="logout-device"
      primary-button-text="Logout"
      @confirmed="handleDeviceLogout"
    >
      <template v-slot:head>
        <h4 class="text-xl !flex items-center gap-x-2"><i class="fas fa-power-off"></i> Logout Device</h4>
      </template>
      <template v-slot:body>
        <p class="pt-4">This will log you out from this device. You can log back in anytime.</p>
      </template>
      <template v-slot:footer="{ onCancel, onConfirm }">
        <CstButton
          text="Cancel"
          variant="outlined"
          @click="onCancel"
        />
        <CstButton
          text="Logout"
          variant="danger"
          @click="onConfirm"
        />
      </template>
    </CstConfirmationPopup>
    <CstConfirmationPopup
      modal-name="logout-all-devices"
      primary-button-text="Agree"
      @confirmed="logoutAllDevices"
    >
      <template v-slot:head>
        <h4 class="text-xl !flex items-center gap-x-2"><i class="fas fa-power-off"></i> Logout From All Devices</h4>
      </template>
      <template v-slot:body>
        <p class="pt-4">This will log you out from all other devices, except this one. You can log back in anytime.</p>
      </template>
      <template v-slot:footer="{ onCancel, onConfirm }">
        <CstButton
          text="Cancel"
          variant="outlined"
          @click="onCancel"
        />
        <CstButton
          text="Logout"
          variant="danger"
          @click="onConfirm"
        />
      </template>
    </CstConfirmationPopup>
  </div>
</template>

<script setup>
import { ref, inject, computed, watch } from 'vue'
import { useDevices } from '@common/composables/useDevices'
import CstSimpleCheckbox from '@ui/CheckBox/CstSimpleCheckbox.vue'
import CstButton from '@ui/Button/CstButton.vue'
import DesktopIcon from '@assets/img/settings/windows.svg'
import SmartphoneIcon from '@assets/img/settings/mobile.svg'
import LaptopIcon from '@assets/img/settings/macbook.svg'
import CstConfirmationPopup from '@ui/Popup/CstConfirmationPopup.vue'
import useDateFormat from '@common/composables/useDateFormat'
import useTime from '@/src/modules/common/composables/useTime'

const root = inject('root')
const { $bvModal } = root
const selectedDeviceId = ref(null)
const selectedDevice = ref(null)
const { momentWrapper, getAccountDateTimeFormat } = useDateFormat()
const { getWorkspaceTimeZoneTime } = useTime()

const {
  devices,
  logoutLoading,
  isLoggingOutAll,
  fetchDevices,
  logoutDevice,
  logoutAllDevices,
} = useDevices()

const props = defineProps({
  isTabActive: {
    type: Boolean,
    default: false
  }
})

const getDeviceIcon = (platform) => {
  const platformLower = platform.toLowerCase()
  if (platformLower.includes('windows')) {
    return DesktopIcon
  } else if (platformLower.includes('ios') || platformLower.includes('android')) {
    return SmartphoneIcon
  } else if (platformLower.includes('mac')) {
    return LaptopIcon
  }
  return DesktopIcon
}

const getFormattedLocation = (location) => {
  if (!location) return 'Unknown'
  const parts = [location.city, location.region, location.country].filter(Boolean)
  return parts.join(', ')
}

const confirmLogoutDevice = (device) => {
  selectedDevice.value = device
  $bvModal.show('logout-device-confirmation-modal')
}

const handleDeviceLogout = () => {
  if (selectedDevice.value) {
    logoutDevice(selectedDevice.value.token, selectedDevice.value.is_current)
    selectedDevice.value = null
  }
}

const confirmLogoutAllDevices = () => {
  $bvModal.show('logout-all-devices-confirmation-modal')
}

const formatDate = (date) => {
  return getWorkspaceTimeZoneTime(date, getAccountDateTimeFormat.value)
}

const sortedDevices = computed(() => {
  return [...devices.value].sort((a, b) => {
    if (a.is_current === b.is_current) return 0;
    return a.is_current ? -1 : 1;
  });
});

watch(() => props.isTabActive, (isActive) => {
  if (isActive) {
    fetchDevices()
  }
}, { immediate: true })
</script>
